import Cookies from 'js-cookie';
import { APP_MODE } from '@hems/util/src/constant';
import { TENANT } from '@hems/util/src/constant/msal';
import { isRoleCodeType } from '@hems/util/src/helper/tsguardHelper';
import { Helper } from '@hems/util/src/index';
const RoleMap = {
    10: 'user',
    20: 'installer',
    60: 'dev',
    70: 'service',
    90: 'admin',
};
// FIXME: 추후 삭제
export const getRoleNm = (roleCd) => {
    if (!isRoleCodeType(roleCd)) {
        return;
    }
    return RoleMap[roleCd];
};
export const getRoleName = (roleCode) => RoleMap[roleCode];
export const parseJwt = (jwt) => {
    if (!jwt) {
        return null;
    }
    try {
        const base64Url = jwt.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map((c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    catch (error) {
        return null;
    }
};
const TOKEN_KEY = {
    ACCESS_TOKEN: 'access_token',
    ID_TOKEN: 'id_token',
};
const cookieOptions = {
    path: '/',
    sameSite: 'strict',
};
export const saveAuthToken = (authToken) => {
    Cookies.set(TOKEN_KEY.ACCESS_TOKEN, encodeURIComponent(authToken.accessToken || ''), cookieOptions);
    Cookies.set(TOKEN_KEY.ID_TOKEN, encodeURIComponent(authToken.idToken || ''), cookieOptions);
};
export const saveAccessTokenForIframe = (token) => {
    const iframeCookieOptions = {
        path: '/',
        sameSite: 'none',
        secure: true,
    };
    return new Promise((resolve, reject) => {
        try {
            Cookies.set(TOKEN_KEY.ACCESS_TOKEN, encodeURIComponent(token), iframeCookieOptions);
            resolve();
        }
        catch (error) {
            reject(error);
        }
    });
};
export const getAuthTokenFromCookie = () => {
    const isLocal = Helper.getAppEnv() === APP_MODE.LOCAL;
    const accessToken = isLocal ? process.env.VUE_APP_ACCESS_TOKEN : Cookies.get(TOKEN_KEY.ACCESS_TOKEN);
    const idToken = isLocal ? process.env.VUE_APP_ID_TOKEN : Cookies.get(TOKEN_KEY.ID_TOKEN);
    return { accessToken: accessToken || null, idToken: idToken || null };
};
export const removeAuthToken = () => {
    Cookies.remove(TOKEN_KEY.ACCESS_TOKEN, cookieOptions);
    Cookies.remove(TOKEN_KEY.ID_TOKEN, cookieOptions);
    // FIXME: 임시 기능. 기존 access token을 쿠키에 set할 때 window.location.hostname을 domain으로 설정했던 것들을 제거하기 위함.
    Cookies.remove(TOKEN_KEY.ACCESS_TOKEN, {
        domain: window.location.hostname,
        path: '/',
        sameSite: 'strict',
    });
};
export const isExpired = (jwtObject) => {
    const expiredDate = new Date(Number(jwtObject.exp) * 1000);
    const now = new Date();
    return now > expiredDate;
};
export const getRoleCdByRoleName = (roleNm) => {
    const roleInfo = Object.entries(RoleMap).find(([, roleValue]) => {
        return roleNm === roleValue;
    });
    return roleInfo?.[0];
};
export const getClientId = (tenant) => tenant === TENANT.PARTNERS
    ? process.env.VUE_APP_MSAL_PARTNERS_CLIENT_ID || ''
    : process.env.VUE_APP_MSAL_HOMEOWNER_CLIENT_ID || '';
export const getTenantId = (tenant) => tenant === TENANT.PARTNERS
    ? process.env.VUE_APP_MSAL_PARTNERS_TENANT_ID || ''
    : process.env.VUE_APP_MSAL_HOMEOWNER_TENANT_ID || '';
export const getTenantUrl = (tenant) => tenant === TENANT.PARTNERS
    ? process.env.VUE_APP_MSAL_PARTNERS_TENANT_URL || ''
    : process.env.VUE_APP_MSAL_HOMEOWNER_TENANT_URL || '';
export const getAuthority = (tenant) => tenant === TENANT.PARTNERS
    ? `https://${process.env.VUE_APP_MSAL_PARTNERS_TENANT_ID}.ciamlogin.com/${process.env.VUE_APP_MSAL_PARTNERS_TENANT_ID}`
    : `https://${process.env.VUE_APP_MSAL_HOMEOWNER_TENANT_ID}.ciamlogin.com/${process.env.VUE_APP_MSAL_HOMEOWNER_TENANT_ID}`;
/** MSAL 라이브러리 사용을 위한 Config 설정 */
export const getMSALConfig = (tenant) => ({
    auth: {
        clientId: getClientId(tenant),
        authority: getAuthority(tenant),
        redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI || '',
        knownAuthorities: tenant === TENANT.PARTNERS ? [] : [process.env.VUE_APP_MSAL_HOMEOWNER_TENANT_URL || ''],
    },
    cache: {
        cacheLocation: 'sessionStorage',
    },
});
export const getLoginRequestParams = (tenant) => ({
    scopes: [`${getClientId(tenant)}/.default`, 'openid', 'profile'],
});
export const getTenantByTenantId = (tenantId) => tenantId === process.env.VUE_APP_MSAL_HOMEOWNER_TENANT_ID ? TENANT.HOMEOWNER : TENANT.PARTNERS;
export const getHomeownerConfig = () => getMSALConfig(TENANT.HOMEOWNER);
export const getPartnersConfig = () => getMSALConfig(TENANT.PARTNERS);
export const getMSALAccountInfo = (authToken) => {
    const { accessToken } = authToken ?? getAuthTokenFromCookie();
    if (!accessToken) {
        return null;
    }
    const accessTokenJwt = parseJwt(accessToken);
    if (!accessTokenJwt) {
        return null;
    }
    const tenantId = getTenantByTenantId(accessTokenJwt.tid);
    const accountInfoKeySuffix = getTenantId(tenantId);
    const accountInfoKey = Object.keys(sessionStorage).find((key) => key.endsWith(accountInfoKeySuffix));
    if (!accountInfoKey) {
        return null;
    }
    const accountInfo = sessionStorage.getItem(accountInfoKey);
    return accountInfo ? JSON.parse(accountInfo) : null;
};
