import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Suspense as _Suspense, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainTemplate = _resolveComponent("MainTemplate")!
  const _component_HealthCheckContainer = _resolveComponent("HealthCheckContainer")!
  const _component_ModalsContainer = _resolveComponent("ModalsContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (!_ctx.isTermsPage)
          ? (_openBlock(), _createBlock(_component_HealthCheckContainer, { key: 0 }, {
              default: _withCtx(() => [
                (_ctx.isLoad)
                  ? (_openBlock(), _createBlock(_component_MainTemplate, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_view)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_router_view)
            ]))
      ]),
      _: 1
    })),
    _createVNode(_component_ModalsContainer)
  ], 64))
}