/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */

import type { AppEnv } from 'hems';

import type { ValueType } from 'hems/common/utils';
import type { DeviceStatus } from 'hems/device';

// export const passwordPolicy = /(((?=.*[a-z])|(?=.*[A-Z]))(?=.*\d)(?=.*[`~!@#$%^&*|'";:/?\\]).{8,20})/;
export const passwordPolicy = /(((?=.*[a-z])|(?=.*[A-Z]))(?=.*\d)(?=.*[`~!@#$%^&*|'";:/?\\]).{8,20})/;
export const idPolicy = /^[a-zA-Z0-9!@#$*()\-_+,.~<>[\]{}=|]*$/;
export const alpha2CountryList = [
  { country: 'AU', lang: 'en' },
  { country: 'AT', lang: 'de' },
  { country: 'BR', lang: 'pt' },
  { country: 'CA', lang: 'en' },
  { country: 'CL', lang: 'en' },
  { country: 'CN', lang: 'zh' },
  { country: 'FR', lang: 'fr' },
  { country: 'DE', lang: 'de' },
  { country: 'GR', lang: 'el' },
  { country: 'HU', lang: 'hu' },
  { country: 'IE', lang: 'en' },
  { country: 'IT', lang: 'it' },
  { country: 'JP', lang: 'ja' },
  { country: 'KR', lang: 'ko' },
  { country: 'MY', lang: 'ms' },
  { country: 'NL', lang: 'nl' },
  { country: 'PH', lang: 'en' },
  { country: 'PL', lang: 'pl' },
  { country: 'PT', lang: 'pt' },
  { country: 'ES', lang: 'eu' },
  { country: 'CH', lang: 'fr' },
  { country: 'TR', lang: 'tr' },
  { country: 'GB', lang: 'en' },
  { country: 'US', lang: 'en' },
  { country: 'VN', lang: 'vi' },
  { country: 'NZ', lang: 'en' },
  { country: 'BE', lang: 'fr' },
];

export const euAlpha2CountryList = [
  { country: 'DE', lang: 'de' },
  { country: 'AT', lang: 'de' },
  { country: 'BE', lang: 'fr' },
  { country: 'CH', lang: 'fr' },
  { country: 'FR', lang: 'fr' },
  { country: 'GB', lang: 'en' },
  { country: 'IE', lang: 'en' },
  { country: 'PT', lang: 'pt' },
];

export const auAlpha2CountryList = [
  { country: 'AU', lang: 'en' },
  { country: 'NZ', lang: 'en' },
];

export const usAlpha2CountryList = [
  { country: 'US', lang: 'en' },
  { country: 'CA', lang: 'en' },
];

export const ENERGY_POLICY = {
  STAND_BY: '0',
  SELF_CONSUMPTION: '1',
  ZERO_EXPORT: '2',
  TIME_BASED: '3',
  EXTERNAL_GENERATION: '4',
  EOS: '5',
  GATEWAY: '6',
  ENGINEER: '7',
  SMART_SAVING: '8',
  STORM_COVER: '9',
  VPP: '10',
} as const;

export type EnergyPolicy = ValueType<typeof ENERGY_POLICY>;

export const ENV_LOCALE = {
  EU: 'EU',
  AU: 'AU',
  NZ: 'NZ',
  US: 'US',
} as const;

export type EnvLocaleValue = ValueType<typeof ENV_LOCALE>;

/** @TODO 추후 State(주) 단위로 변경될 예정 */
export const SITE_COUNTRY_CODE = {
  US: 'US',
  KR: 'KR',
  ETC: 'etc',
} as const;

export type SiteCountryCodeValue = ValueType<typeof SITE_COUNTRY_CODE>;

export const NUMBER_UNIT = {
  MILLION: 1000000,
  TEN_THOUSAND: 10000,
  THOUSAND: 1000,
  HUNDRED: 100,
} as const;

export type NumberUnitValue = ValueType<typeof NUMBER_UNIT>;

const OPER_STATUS_CD = {
  _40: '40',
  _60: '60',
};

export const DEVICE_CONNECTION_STATUS: { [key: string]: DeviceStatus } = {
  [OPER_STATUS_CD._40]: 'incomplete',
  [OPER_STATUS_CD._60]: 'offline',
  ONLINE: 'online',
  ONLINE_DISCONNECTED: 'online-disconnected',
};

export const AC_SYSTEM_FIELD_TYPE_CODE = {
  SITE: 'site',
  ACES: 'aces',
  ACCB: 'accb',
  ACES_INDIVIDUAL: 'aces_individual',
} as const;

export type ACSystemFieldTypeCodeValue = ValueType<typeof AC_SYSTEM_FIELD_TYPE_CODE>;

export type AcSystemFieldTypeValue = ValueType<typeof AC_SYSTEM_FIELD_TYPE_CODE>;

export const AC_DEVICE_MODEL_TYPE = {
  AC_COMBINER: 'accb',
  AC_COUPLED: 'aces',
} as const;

export type ACDeviceModelTypeValue = ValueType<typeof AC_DEVICE_MODEL_TYPE>;

export const APP_MODE: Record<string, AppEnv> = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
} as const;

export type AppModeValue = ValueType<typeof APP_MODE>;

export const SAPN_TYPE = {
  NO: 'NO',
  FAIL: 'FAIL',
  SAPN: 'SAPN',
} as const;

export const FORM_TYPE = {
  NEW: 'new',
  EDIT: 'edit',
  READ: 'read',
} as const;

export type FormTypeValue = ValueType<typeof FORM_TYPE>;

export const GROUP_MODE = {
  NEW: 'new',
  NEW_COPY: 'newCopy',
  READ: 'read',
} as const;

export const GROUP_READ_TYPE = {
  INFO: 'info',
  TARGET: 'target',
  CONFIG: 'config',
} as const;

export const SORT_ORDER = {
  DESCENDING: 'desc',
  ASCENDING: 'asc',
} as const;

export type SortOrderValue = ValueType<typeof SORT_ORDER>;

export const CONFIG_MSG_TYPE = {
  AS: 'AS',
  BS: 'BS',
  ES: 'ES',
  TOU: 'TOU',
} as const;

export const CSS_COLUMN = {
  MAX_COLUMN_1: 1,
  MAX_COLUMN_2: 2,
  MAX_COLUMN_3: 3,
  MAX_COLUMN_4: 4,
  MAX_COLUMN_5: 5,
  MAX_COLUMN_6: 6,
};

export const VALIDATION_MESSAGE = {
  DUPLICATE: 'duplicate',
  INVALID: 'invalid',
  VALID: 'valid',
} as const;

export type ValidationMessageValue = ValueType<typeof VALIDATION_MESSAGE>;

export const CSS_VISIBILITY = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
} as const;

export const SELECT_ALL = 'ALL';

export const INPUT_VALID_STATUS = {
  DEFAULT: '',
  VALID: 'valid',
  INVALID: 'invalid',
} as const;

export type InputValidStatus = ValueType<typeof INPUT_VALID_STATUS>;

export const QOMMAND_WEB_ORIGIN_URL = 'qommand.qcells.com' as const;

export const API_METHOD_TYPE = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
} as const;

export const CONTENT_TYPE_HEADER = {
  KEY: 'Content-Type',
  APPLICATION_JSON: 'application/json',
} as const;

export const CS_CONTACT_NUMBER = '+1 (888) 249 - 7750' as const;

export const INDENT_DEPTH = {
  FIRST_LEVEL_INDENT: 1,
  SECOND_LEVEL_INDENT: 2,
  THIRD_LEVEL_INDENT: 3,
} as const;

export type IndentDepthValue = ValueType<typeof INDENT_DEPTH>;

export const USER_AGENT_TYPE = {
  ANDROID: 'android',
  IPHONE: 'iphone',
  IPAD: 'ipad',
  IOS: 'ios',
  AOS: 'aos',
  PC: 'pc',
} as const;

export const APP_TYPE = {
  ADMIN: 'ADMIN',
  INSTALLER: 'INSTALLER',
  PORTAL: 'PORTAL',
  USER: 'USER',
} as const;

export type AppTypeValue = ValueType<typeof APP_TYPE>;

export const IMAGE_FILE_EXTENSION = {
  PNG: 'png',
  SVG: 'svg',
  GIF: 'gif',
} as const;

export type ImageFileExtensionValue = ValueType<typeof IMAGE_FILE_EXTENSION>;

export const SITE_DETAIL_ROUTER_NAME = {
  SITE_INFO: 'DeviceDashboard',
  SITE_ENERGY: 'SiteEnergy',
  ENERGY_STATISTICS: 'DeviceGraph',
  ARRAY_MAPPING: 'SmartModuleWeb',
  SYSTEM_SETTINGS: 'Installation',
  ERROR_HISTORY: 'DeviceEvent',
} as const;

export type SiteDetailRouterNameValue = ValueType<typeof SITE_DETAIL_ROUTER_NAME>;

export const PROVIDER_URL = {
  /** @TODO 추후 제거 예정 */
  TEST: 'w3schools.com',
  FLEET: 'powerappsportals.com',
  FLEET_MVP2: 'vpp-fleet-v2.powerappsportals.com',
  /** @TODO 추후 제거 예정 */
  FLEET_MVP2_TEST: 'site-10eaq.powerappsportals.com',
} as const;
