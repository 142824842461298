import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-042088b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting-info" }
const _hoisted_2 = {
  key: 0,
  class: "setting-info-title"
}
const _hoisted_3 = {
  key: 1,
  class: "setting-info-sub-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.subTitle)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.subTitle), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}