
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import type { InputErrorInfo } from 'hems';

export default defineComponent({
  name: 'InputError',
  props: {
    errorInfo: {
      type: Object as PropType<InputErrorInfo>,
      required: true,
    },
  },
});
