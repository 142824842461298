import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
export default defineComponent({
    name: 'ScrollTop',
    setup() {
        const isVisible = ref(false);
        const onClick = () => {
            window.scrollTo({ top: 0 });
        };
        const handlerScroll = () => {
            if (window.pageYOffset > window.innerHeight / 2) {
                isVisible.value = true;
            }
            else {
                isVisible.value = false;
            }
        };
        onMounted(() => {
            window.addEventListener('scroll', handlerScroll);
        });
        onBeforeUnmount(() => {
            window.removeEventListener('scroll', handlerScroll);
        });
        return {
            isVisible,
            onClick,
        };
    },
});
