
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { ModalsContainer } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import isNil from 'lodash-es/isNil';

import { MainTemplate } from '@hems/component';
import { HealthCheckContainer } from '@hems/container';
import { useLanguage } from '@hems/util';
import { REQUIRED_TERMS_CATEGORY } from '@hems/util/src/constant';

export default defineComponent({
  name: 'App',
  components: {
    MainTemplate,
    HealthCheckContainer,
    ModalsContainer,
  },
  setup() {
    const route = useRoute();
    const { locale: i18nLocale } = useI18n();
    const { languageCode } = useLanguage();

    const isLoad = ref(false);

    const isTermsPage = computed(
      () =>
        !isNil(route.name) &&
        (route.name === REQUIRED_TERMS_CATEGORY.TERMS_OF_SERVICE ||
          route.name === REQUIRED_TERMS_CATEGORY.TERMS_OF_PRIVACY)
    );

    // 초기화
    i18nLocale.value = languageCode.value;

    onBeforeMount(async () => {
      if (!isTermsPage.value) {
        isLoad.value = true;
      }
    });

    return {
      languageCode,
      isTermsPage,
      isLoad,
    };
  },
});
