import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicRadio = _resolveComponent("BasicRadio")!
  const _component_ErrorMessageLabel = _resolveComponent("ErrorMessageLabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["radio-group", { [`direction-${_ctx.direction}`]: true, [_ctx.align]: true }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: idx,
          style: _normalizeStyle({ textAlign: 'left', flexBasis: `calc(100% / ${_ctx.maxColumnNum})` })
        }, [
          _createVNode(_component_BasicRadio, _mergeProps({
            modelValue: _ctx.inputValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            class: "basic-radio",
            name: _ctx.inputName,
            "value-type": _ctx.valueType,
            value: option.value
          }, _ctx.$attrs, { readonly: _ctx.readonly }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.text), 1)
            ]),
            _: 2
          }, 1040, ["modelValue", "name", "value-type", "value", "readonly"])
        ], 4))
      }), 128))
    ], 2),
    _createVNode(_component_ErrorMessageLabel, { "error-message": _ctx.errorMessage }, null, 8, ["error-message"])
  ], 64))
}