
import 'tui-grid/dist/tui-grid.css';
import '@vueform/slider/themes/default.css';
import '@hems/component/resources/vendor/css_new/common.css';
import '@hems/component/resources/vendor/css_new/common_style.css';
import '@hems/component/resources/vendor/css_new/main.css';
import '@hems/component/resources/vendor/css_new/contents.css';
import '@hems/component/resources/vendor/css_new2021/common_new2021.css';
import '@hems/component/resources/styles/main.scss';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import 'vue-final-modal/dist/style.css';

import type { PropType } from 'vue';
import { defineAsyncComponent, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { useIdle } from '@vueuse/core';

import { MainTemplate, Header, Navigation, LanguageSelector, LoginUserInfo } from '@hems/component';
import { CommonService } from '@hems/service';
import { useAccessToken, useLanguage, useAppProvider } from '@hems/util';
import { useMSAL } from '@hems/util/src/composable/msal';
import { useNavigationMenu } from '@hems/util/src/composable/navigationMenu';
import type { LanguageValue } from '@hems/util/src/constant';
import { ONE_HOUR_VALUE_IN_MILLISECONDS } from '@hems/util/src/constant';

import type { NavItem } from 'hems';

import type { CommonRootState } from 'hems/common/store';
export default defineComponent({
  name: 'MainTemplateContainer',
  components: {
    MainTemplate,
    Header,
    Navigation,
    LanguageSelector,
    LoginUserInfo,
    IdleTimePopup: defineAsyncComponent(() => import('@hems/component/src/popup/IdleTimePopup.vue')),
  },
  props: {
    navigationConfig: {
      type: Array as PropType<NavItem[]>,
      default: () => [],
    },
  },
  setup() {
    const commonService = new CommonService(window.axiosInstance.axios);
    const { accessToken, isAuthenticated, isTokenExpired } = useAccessToken();
    const { t, locale: i18nLocale } = useI18n();
    const store = useStore<CommonRootState>();

    const { idle } = useIdle(ONE_HOUR_VALUE_IN_MILLISECONDS); // 60 min
    const { languageCode, setLanguage, setIntlLocale } = useLanguage();
    const { navigationConfigByRole } = useNavigationMenu();

    const isInit = ref(false);
    const selectedLanguage = ref<LanguageValue>(languageCode.value);
    const isOpenIdleTimePopup = ref(false);

    const { logoutRedirect } = useMSAL();

    const onLogout = () => {
      isOpenIdleTimePopup.value = false;

      logoutRedirect();
    };

    const onContinue = () => {
      isOpenIdleTimePopup.value = false;
    };

    const changeLanguage = (selectedLanguage: LanguageValue) => {
      i18nLocale.value = selectedLanguage;
      setLanguage(selectedLanguage);
      setIntlLocale();
    };

    const { isFleetApp } = useAppProvider();

    watch(idle, (idleValue) => {
      if (idleValue) {
        isOpenIdleTimePopup.value = true;
      }
    });

    const init = async () => {
      if (!accessToken.value || !isAuthenticated.value) {
        alert(t('message.login'));
        location.href = '/';

        return;
      }

      if (isAuthenticated.value && isTokenExpired.value) {
        alert(t('message.invalid_token'));
        location.href = '/';

        return;
      }

      store.dispatch('appCtx/setGoogleApiKey', await commonService.getGoogleAPIKey());

      // 초기화
      i18nLocale.value = languageCode.value;
      isInit.value = true;
    };

    init();

    return {
      isInit,
      languageCode,
      navigationConfigByRole,
      selectedLanguage,
      isOpenIdleTimePopup,
      onLogout,
      onContinue,
      changeLanguage,
      isFleetApp,
    };
  },
  errorCaptured(error, instance, info) {
    console.error('MainTemplate ErrorCaptured', error, instance, info);
    alert('An unknown error has occurred.');
  },
});
