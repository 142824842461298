import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c26b6f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "basic-button-container right"
}
const _hoisted_2 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_ToastGrid = _resolveComponent("ToastGrid")!
  const _component_ListNavigator = _resolveComponent("ListNavigator")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    default: _withCtx(() => [
      (_ctx.isAdminGroup)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_BasicButton, {
              "color-type": _ctx.BASIC_BUTTON_COLOR_TYPE.SECONDARY,
              "before-image": _ctx.downloadButtonIconInfo,
              onClick: _ctx.onDownload
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.download_excel')), 1)
              ]),
              _: 1
            }, 8, ["color-type", "before-image", "onClick"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showGrid)
          ? (_openBlock(), _createBlock(_component_ToastGrid, {
              key: 0,
              data: _ctx.eventList,
              columns: _ctx.toastGrid.gridOptions.columns,
              options: _ctx.toastGrid.gridOptions.options,
              "sort-state": _ctx.toastGrid.gridOptions.sortState,
              events: _ctx.events
            }, null, 8, ["data", "columns", "options", "sort-state", "events"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ListNavigator, {
        "list-total-count": _ctx.totalErrorHistoryCount,
        "page-condition": { pageNumber: _ctx.condition.pageNumber, pageSize: _ctx.condition.listSize },
        onChangePage: _ctx.onChangePage,
        onChangeListSize: _ctx.onChangeListSize
      }, null, 8, ["list-total-count", "page-condition", "onChangePage", "onChangeListSize"])
    ]),
    _: 1
  }))
}