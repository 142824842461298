
import type { PropType } from 'vue';
import { defineComponent, ref, reactive, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { BasicButton } from '@hems/component';
import { useFailModal } from '@hems/util';

export default defineComponent({
  name: 'ImageUploader',
  components: { BasicButton },
  props: {
    name: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: File as PropType<File | null>,
      default: null,
    },
    accept: {
      type: Array as PropType<string[]>,
      default: () => ['image/gif', 'image/jpeg', 'image/png'],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const imageInput = ref<HTMLInputElement | null>(null);

    const state = reactive<{
      imageUrl: string | null;
    }>({
      imageUrl: props.modelValue ? URL.createObjectURL(props.modelValue) : null,
    });

    const updateImageUrl = (image: File | null) => {
      state.imageUrl = image ? URL.createObjectURL(image) : null;
    };

    const clear = () => {
      state.imageUrl = null;
      if (imageInput.value) {
        imageInput.value.value = '';
        imageInput.value.files = null;
      }
      emit('update:modelValue', null);
    };

    const failModal = useFailModal({ content: t('message.not_allowed_file') });

    const onChangeImages = () => {
      if (!imageInput.value || !imageInput.value.files) return;

      const [file] = imageInput.value.files;

      if (!props.accept.includes(file.type)) {
        clear();
        failModal.open();

        return;
      }

      updateImageUrl(file);
      emit('update:modelValue', file);
    };

    const triggerImageUpload = () => {
      imageInput.value?.click();
    };

    watch(() => props.modelValue, updateImageUrl);

    onMounted(() => updateImageUrl(props.modelValue));

    return {
      state,
      imageInput,
      triggerImageUpload,
      onChangeImages,
      clear,
    };
  },
});
