import { defineComponent, computed, ref, onBeforeUnmount, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import isNil from 'lodash-es/isNil';
import { useField } from 'vee-validate';
import { Image2 } from '@hems/component';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { INPUT_VALID_CLASS, SELECTED_DISPLAY_TYPE, SELECTOR_VALUE_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'Selector',
    components: {
        ErrorMessageLabel,
        Image2,
    },
    props: {
        modelValue: {
            type: [String, Number, Boolean, null],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        valueType: {
            type: String,
            default: SELECTOR_VALUE_TYPE.STRING,
        },
        name: {
            type: String,
            required: true,
        },
        beforeChange: {
            type: Function,
            default: null,
        },
        afterChange: {
            type: Function,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedDisplayType: {
            type: String,
            default: SELECTED_DISPLAY_TYPE.TEXT,
        },
        isDisplaySelectedInList: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        validClass: {
            type: String,
            default: INPUT_VALID_CLASS.DEFAULT,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const selectorRef = ref(null);
        const isOpen = ref(false);
        const transformValue = (value) => {
            if (isNil(value))
                return null;
            if (props.valueType === SELECTOR_VALUE_TYPE.BOOLEAN)
                return Boolean(value);
            if (props.valueType === SELECTOR_VALUE_TYPE.NUMBER)
                return Number(value);
            return value;
        };
        const { value: inputValue, errorMessage } = useField(props.name, undefined, {
            initialValue: transformValue(props.modelValue),
            type: props.valueType,
        });
        const placeholderText = computed(() => {
            return props.placeholder ? props.placeholder : t('common.select');
        });
        const selectorOptions = computed(() => props.options.map((item) => {
            const value = transformValue(item.value);
            return { ...item, value };
        }));
        const selected = computed(() => selectorOptions.value.find((item) => item.value === inputValue.value));
        const selectedText = computed(() => {
            if (selected.value && props.selectedDisplayType === SELECTED_DISPLAY_TYPE.VALUE) {
                return selected.value.value;
            }
            if (selected.value && props.selectedDisplayType === SELECTED_DISPLAY_TYPE.TEXT) {
                return selected.value.text;
            }
            return '';
        });
        const hasDescription = computed(() => selectorOptions.value.some((option) => option.description));
        const onClickSelect = () => {
            if (!props.disabled && !props.readonly) {
                isOpen.value = !isOpen.value;
            }
        };
        const onClickOption = (value) => {
            if (props.beforeChange) {
                if (props.beforeChange(value.value, inputValue.value)) {
                    setValue(value);
                }
            }
            else {
                setValue(value);
            }
        };
        const setValue = (value) => {
            inputValue.value = value.value;
            emit('update:modelValue', value.value, value.text);
            if (props.afterChange) {
                props.afterChange(value.value, value.text);
            }
        };
        const onClickOtherLayer = (e) => {
            if (isOpen.value &&
                e.target instanceof HTMLElement &&
                selectorRef.value &&
                !selectorRef.value.contains(e.target)) {
                isOpen.value = false;
            }
        };
        watch(() => props.modelValue, () => {
            if (inputValue.value !== props.modelValue)
                inputValue.value = props.modelValue ?? '';
        });
        onMounted(() => {
            document.addEventListener('click', onClickOtherLayer);
        });
        onBeforeUnmount(() => {
            document.removeEventListener('click', onClickOtherLayer);
        });
        return {
            selected,
            selectedText,
            inputValue,
            selectorRef,
            errorMessage,
            INPUT_VALID_CLASS,
            selectorOptions,
            isOpen,
            hasDescription,
            onClickSelect,
            onClickOption,
            placeholderText,
        };
    },
});
