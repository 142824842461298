import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ButtonNew, List, Checkbox } from '@hems/component';
import EnergyManagementNavigation from '@hems/container/src/energyManagement/EnergyManagementNavigation.vue';
import { useDangerConfirmationModal, useEnergyManagement, useScheduleDate } from '@hems/util';
import { BUTTON_NEW_TYPE, LIST_ITEM_MODE, LIST_ITEM_SIZE } from '@hems/util/src/constant';
import { createScheduleEventByOperation } from '@hems/util/src/helper/energyManagement/energyManagementHelper';
export default defineComponent({
    name: 'ScheduleDeleteList',
    components: {
        EnergyManagementNavigation,
        ButtonNew,
        List,
        Checkbox,
    },
    props: {
        scheduleList: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['onDeleteSchedule', 'moveToPrevPage'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const { scheduleInfoList, updateWeeklySchedulerEventList, setSelectedScheduleInfo } = useEnergyManagement();
        const { getOnStatus, getScheduleDateRangeText } = useScheduleDate();
        const isCheckedAll = ref(false);
        const onMoveToPrevPage = () => {
            emit('moveToPrevPage');
        };
        const convertScheduleToListItem = (list) => list.map((schedule) => ({
            id: schedule.name,
            onStatus: getOnStatus(schedule.startDate, schedule.endDate),
            activeStatus: false,
            mode: LIST_ITEM_MODE.CHECK,
            size: LIST_ITEM_SIZE.SMALL,
            mainContents: schedule.name,
            description: getScheduleDateRangeText(schedule.startDate, schedule.endDate),
            checkStatus: false,
            badgeStatus: null,
            iconImageInfo: null,
        }));
        const scheduleItemList = ref(convertScheduleToListItem(props.scheduleList));
        const isDeleteButtonDisabled = computed(() => scheduleItemList.value.every((item) => !item.checkStatus));
        const onCheckAllSchedule = (isChecked) => {
            isCheckedAll.value = isChecked;
            scheduleItemList.value = scheduleItemList.value.map((item) => ({
                ...item,
                checkStatus: isChecked,
            }));
        };
        const onCheckSchedule = (scheduleName, isChecked) => {
            scheduleItemList.value = scheduleItemList.value.map((item) => ({
                ...item,
                checkStatus: item.id === scheduleName ? isChecked : item.checkStatus,
            }));
            isCheckedAll.value = scheduleItemList.value.every((item) => item.checkStatus);
        };
        const deleteSchedule = () => {
            emit('onDeleteSchedule', scheduleItemList.value.filter((item) => item.checkStatus).map((item) => item.id));
        };
        const confirmDeleteModal = useDangerConfirmationModal({
            title: t('control.delete_schedule_modal_title'),
            content: t('control.delete_schedule_message'),
            confirmButtonText: t('common.delete'),
            confirmCallback: () => {
                confirmDeleteModal.close();
                deleteSchedule();
            },
        });
        const openConfirmDeleteModal = () => {
            confirmDeleteModal.open();
        };
        const onDetailSchedule = (scheduleName) => {
            scheduleItemList.value = scheduleItemList.value.map((schedule) => ({
                ...schedule,
                activeStatus: schedule.id === scheduleName,
            }));
            const currentSchedule = scheduleInfoList.value.find((schedule) => schedule.name === scheduleName) ?? null;
            if (currentSchedule) {
                updateWeeklySchedulerEventList(createScheduleEventByOperation(currentSchedule.operations));
                setSelectedScheduleInfo(currentSchedule);
            }
        };
        return {
            onMoveToPrevPage,
            openConfirmDeleteModal,
            onCheckAllSchedule,
            onCheckSchedule,
            onDetailSchedule,
            scheduleItemList,
            BUTTON_NEW_TYPE,
            isCheckedAll,
            isDeleteButtonDisabled,
        };
    },
});
