/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { createStore, createLogger } from 'vuex';

import { Helper } from '@hems/util';
import appCtx from '@hems/util/src/stores/modules/appCtx';
import loading from '@hems/util/src/stores/modules/loading';
import toast from '@hems/util/src/stores/modules/toast';
import user from '@hems/util/src/stores/modules/user';

export default createStore({
  plugins: Helper.getAppEnv() === 'local' ? [createLogger()] : [],
  modules: {
    appCtx,
    user,
    toast,
    loading,
  },
});
