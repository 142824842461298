/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import TermsPage from '@hems/container/src/terms/TermsPage.vue';
import store from '@hems/portal/src/store';
import { Helper } from '@hems/util';
import { REQUIRED_TERMS_CATEGORY } from '@hems/util/src/constant';
import { LOADING_ACTION } from '@hems/util/src/stores/modules/loading';

import NewHome from '@/views/NewHome.vue';
import PageWrapper from '@/views/PageWrapper.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: NewHome,
  },
  {
    path: '/.well-known',
    name: 'PageWrapper',
    component: PageWrapper,
    children: [
      {
        path: 'security.txt',
        name: 'SecurityText',
        component: () => import(/* webpackChunkName: "cyberSecurity" */ '@/views/cyberSecurity/SecurityText.vue'),
      },
    ],
  },
  {
    path: '/.well-known',
    name: 'PageWrapper',
    component: PageWrapper,
    children: [
      {
        path: 'security.txt',
        name: 'SecurityText',
        component: () => import(/* webpackChunkName: "cyberSecurity" */ '@/views/cyberSecurity/SecurityText.vue'),
      },
    ],
  },
  {
    path: '/terms',
    name: REQUIRED_TERMS_CATEGORY.TERMS_OF_SERVICE,
    component: TermsPage,
  },
  {
    path: '/privacy',
    name: REQUIRED_TERMS_CATEGORY.TERMS_OF_PRIVACY,
    component: TermsPage,
  },
  {
    path: '/redirect/Unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "error" */ '@hems/component/src/redirectPages/UnauthorizedPage.vue'),
  },
  {
    path: '/redirect/PageNotFound',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "error" */ '@hems/component/src/redirectPages/NotFoundPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/redirect/PageNotFound',
  },
];

const router = createRouter({
  history: createWebHistory(Helper.getAppEnv() === 'local' ? process.env.BASE_URL : undefined),
  routes,
});

router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message) || error.name === 'ChunkLoadError') {
    window.location.reload();
  }
});

router.afterEach((_to, from) => {
  const isFromMSAL = from.hash.includes('#code');

  if (isFromMSAL) {
    return;
  }

  store.dispatch(LOADING_ACTION.RESET_LOADING);
});

export default router;
