/** 기본 컬러 */
export const DEFAULT_COLOR = {
    PRIMARY_BLUE: '#148cd7',
    PRIMARY_BLUE_LIGHT: '#00adef',
    BLUE_LIGHTER: '#a9cee6',
    BLUE_DARK: '#142845',
    SECONDARY_MINT: '#15dbb7',
    GRAY: '#9c9c9c',
    GRAY_LIGHT: '#e5e5e5',
    GRAY_LIGHTER: '#f5f5f5',
    GRAY_DARK: '#6d6d6d',
    GRAY_DARKER: '#4b4b4b',
    BLACK: '#292929',
    SUCCESS: '#00adef',
    WARNING: '#ffde68',
    ERROR: '#f06280',
    OFFLINE: '8b8b8b',
    INCOMPLETE: '#ff8c38',
};
/** Q.HOME 2.0 컬러 */
export const HOME_2_COLOR = {
    PRIMARY_MINT: '#00c6c1',
    PRIMARY_BLUE: '#0095d6',
    ERROR: '#fd2f40',
    MINT: '#00c6c1',
    SKY_BLUE: '#0095d6',
    GREEN: '#60d483',
    LIGHT_GREEN: '#cae223',
    ORANGE: '#f16b54',
    YELLOW: '#ffda00',
    BLUE: '#5d60ff',
    PURPLE: '#9c73ff',
    TANGERINE: '#ff981d',
    MAGENTA: '#d14ed8',
    GRAY: '#8b8b8b',
    LIGHT_GRAY: '#d8d8d8',
    BLACK: '#0a0a0a',
    BLACK_BACKGROUND: '#191919',
    WHITE: '#ffffff',
    WHITE_BACKGROUND: '#fafafa',
};
/** Q.PRO 2.0 컬러 */
export const PRO_2_COLOR = {
    PRIMARY_MINT: '#00c6c1',
    PRIMARY_BLUE: '#0095d6',
    MINT: '#00c6c1',
    SKY_BLUE: '#0095d6',
    TANGERINE: '#fd881c',
};
/** Highchart Series 컬러 */
export const HIGHCHART_SERIES_COLOR = {
    SKY_BLUE: '#7cb5ec',
    DARK_GRAY: '#434348',
    LIGHT_GREEN: '#90ed7d',
    ORANGE: '#f7a35c',
    PURPLE: '#8085e9',
    MAGENTA: '#f15c80',
    YELLOW: '#e4d354',
    GREEN: '#2b908f',
    RED: '#f45b5b',
    MINT: '#91e8e1',
    BEIGE: '#ddd3af',
};
export const HIGHCHART_DIVIDING_LINE_COLOR = '#8c8c8c';
/** Mapper 컬러 */
export const AC_COMBINER_BG_COLOR = {
    IMAGE_BG_COLOR: '#8b8b8b',
    IMAGE_LIGHT_BG_COLOR: '#d9d9d9',
    POWER_BG_COLOR: '#505050',
    POWER_LIGHT_BG_COLOR: '#c7c7c7',
    SEQ_BG_COLOR: 'black',
    SEQ_LIGHT_BG_COLOR: '#bbbbbb',
    COUNT_BOX_BG_COLOR: '#f0f0f0',
    COUNT_BOX_LIGHT_BG_COLOR: '#f7f7f7',
};
export const ARRAY_EDIT_MODULE_COLOR = {
    SHOW_BORDER: '#00c6c1',
    SHOW_FILL: '#505050',
    HIDE_BORDER: '#f0f0f0',
    HIDE_FILL: '#f6f6f6',
};
export const LIST_MATCH_MODULE_COLOR = {
    SELECT_TEXT: '#00c6c1',
    SELECT_BORDER: '#00c6c1',
    SELECT_FILL: '#eef8f7',
};
export const COMMON_MODULE_COLOR = {
    TEXT_WHITE: '#fafafa',
    DEFAULT_BORDER: '#fafafa',
    DEFAULT_FILL: '#505050',
    SELECT_BORDER: '#00c6c1',
    SELECT_FILL: '#8edfdc',
    RECT_BORDER: '#f0f0f0',
};
export const TEXTBOX_COLOR = {
    SELECT_CURSOR: '#00c6c1',
    TEXT_GRAY: '#8b8b8b',
    TEXT_BLACK: '#0a0a0a',
};
/* Design Language System - color
  DLS v1.1.0 - https://www.figma.com/design/ci2lWIZH3S7SpJH39ob6ko/%5BFINAL%5D-Design-Language-System_v1.1.0?node-id=335-811
*/
/** PRIMARY */
export const PRIMARY_COLOR = {
    P1: '#001c77',
    P2: '#00c6c1',
};
/** SUB COLOR */
export const SUB_COLOR = {
    RED: '#fd2f40',
    YELLOW: '#ffdd3d',
    GREEN: '#69d016',
    DARK_BLUE: '#43689c',
    PURPLE: '#8a5bea',
    TANGERINE: '#fd8b1c',
    LIGHT_GREEN: '#cae223',
    MAGENTA: '#d14ed8',
    BLUE: '#5d60ff',
    SKY_BLUE: '#0095d6',
    ORANGE: '#f16b54',
    BLUE_GRAY: '#93acdb',
    GRAY: '#a9a9a9',
};
/** Background */
export const BACKGROUND_COLOR = {
    LIGHT_MODE_BG1: '#ffffff',
    LIGHT_MODE_BG2: '#fafafa',
    DARK_MODE_BG0: '#353535',
    DARK_MODE_BG1: '#191919',
    DARK_MODE_BG2: '#0a0a0a',
    MAPPER_COMMENT_BOX: 'rgba(142, 223, 220, 0.1)',
};
/** Basic Gray Color */
export const BASIC_GRAY_COLOR = {
    GRAY_50: '#fafafa',
    GRAY_100: '#f0f0f0',
    GRAY_200: '#dcdcdc',
    GRAY_300: '#c2c2c2',
    GRAY_400: '#a8a8a8',
    GRAY_500: '#8b8b8b',
    GRAY_600: '#6e6e6e',
    GRAY_700: '#505050',
    GRAY_800: '#353535',
    GRAY_900: '#191919',
    GRAY_950: '#0a0a0a',
};
export const P1_GRAY_COLOR = {
    GRAY_50: '#fafafb',
    GRAY_100: '#f0f0f2',
    GRAY_500: '#dbdcdf',
    GRAY_750: '#878b94',
    GRAY_800: '#4c5059',
    GRAY_900: '#31353e',
    GRAY_950: '#171821',
};
export const P2_GRAY_COLOR = {
    GRAY_50: '#f9fafa',
    GRAY_100: '#eef1f1',
    GRAY_500: '#d8dede',
    GRAY_750: '#838e8f',
    GRAY_800: '#4b5251',
    GRAY_900: '#323636',
    GRAY_950: '#181a1a',
};
export const WHITE = '#ffffff';
export const BLACK = '#000000';
/** FONT COLOR */
export const FONT_COLOR = {
    DEFAULT_LIGHT_MODE: BASIC_GRAY_COLOR.GRAY_950,
    DEFAULT_DARK_MODE: BASIC_GRAY_COLOR.GRAY_50,
    PRIMARY: PRIMARY_COLOR.P2,
    GRAY: BASIC_GRAY_COLOR.GRAY_500,
};
/** COLOR OPACITY HEX */
export const COLOR_OPACITY = {
    /** 10% */
    10: '1A',
    /** 20% */
    20: '33',
    /** 30% */
    30: '4D',
    /** 40% */
    40: '66',
    /** 50% */
    50: '80',
    /** 60% */
    60: '99',
};
export const STYLE_NONE = 'none';
export const STYLE_INHERIT = 'inherit';
export const USER_OVERVIEW_REPORT_COLOR = {
    PRODUCED: '#002e54',
    CONSUMED: '#cfcfcf',
    EXPORTED: '#91c9d3',
    IMPORTED: '#c3e76f',
    DISCHARGED: '#0068bf',
    CHARGED: '#8c8c8c',
};
export const USER_REPORT_TEXT_COLOR = '#434348';
export const USER_REPORT_NO_DATA_TEXT_COLOR = '#202030';
export const USER_REPORT_COLOR = {
    PRODUCED: '#fbdf5a',
    CONSUMED: '#b4b8bb',
    CORE_LOAD: '#97b5cc',
    EXPORTED: '#fd8714',
    IMPORTED: '#f05538',
    CHARGED: '#89cd1d',
    DISCHARGED: '#2b53a4',
    SOC: '#9b5ba4',
};
/** Pro, Admin WEB Primary Color */
export const PRO_PRIMARY_COLOR = {
    DEFAULT: '#2764de',
    DARK: '#2046b6',
    ACTIVE: '#152d75',
};
