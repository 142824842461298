import type { ColumnOptions } from 'tui-grid';

import { CustomRenderer } from '@hems/component';

import type { i18nTranslation } from 'hems';

export const getColumns = (t: i18nTranslation, isInstallerApp: boolean): ColumnOptions[] => [
  {
    header: t('common.dt'),
    name: 'create_dt_str',
    width: 190,
    sortable: true,
    resizable: true,
    className: 'overflow-visible',
    renderer: {
      type: CustomRenderer.UTCTooltipRenderer,
      options: {
        tooltipColumnName: 'create_dt_utc_str',
        position: ['bottom', 'right'],
      },
    },
  },
  {
    header: t('common.country'),
    name: 'cntry_cd',
    width: 90,
    sortable: true,
    resizable: true,
    hidden: isInstallerApp,
  },
  {
    header: t('common.site_id'),
    name: 'site_id',
    width: 110,
    sortable: true,
    resizable: true,
  },
  {
    header: t('common.act_category'),
    name: 'act_type_str',
    width: 150,
    sortable: true,
    resizable: true,
  },
  {
    header: t('device.serial_no'),
    name: 'device_id',
    width: 170,
    sortable: true,
    resizable: true,
  },
  {
    header: t('common.description'),
    name: 'ref_desc',
    sortable: true,
    resizable: true,
  },
];
