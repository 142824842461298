import { defineComponent, watch, ref } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import { BasicButton } from '@hems/component';
import Image2 from '@hems/component/src/image/Image2.vue';
import { BasicCheckbox, BasicInput } from '@hems/component/src/inputs';
import { BASIC_BUTTON_COLOR_TYPE, BASIC_BUTTON_SIZE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'MonitoringListColumnFilter',
    components: {
        Image2,
        OnClickOutside,
        BasicCheckbox,
        BasicInput,
        BasicButton,
    },
    props: {
        columnList: {
            type: Array,
            required: true,
        },
    },
    emits: ['hide-columns'],
    setup(props, { emit }) {
        const showColumnFilter = ref(false);
        const searchedColumnList = ref(props.columnList);
        const searchWord = ref('');
        const onSearchFilter = () => {
            searchedColumnList.value = props.columnList.filter((item) => item.header?.toLowerCase().includes(searchWord.value.toLowerCase()));
        };
        const showColumnNameList = ref(props.columnList.map((item) => item.name));
        const onClickColumnOption = () => {
            showColumnFilter.value = !showColumnFilter.value;
        };
        const onClickOutside = () => {
            showColumnFilter.value = false;
        };
        const deselectAll = () => {
            showColumnNameList.value = [];
            return;
        };
        const selectAll = () => {
            showColumnNameList.value = props.columnList.map((item) => item.name);
        };
        watch(() => showColumnNameList.value, () => {
            const hiddenColumnList = props.columnList
                .filter((item) => !showColumnNameList.value.includes(item.name))
                .map((item) => item.name);
            emit('hide-columns', hiddenColumnList);
        });
        return {
            onClickColumnOption,
            showColumnFilter,
            onClickOutside,
            onSearchFilter,
            deselectAll,
            selectAll,
            searchedColumnList,
            showColumnNameList,
            searchWord,
            BASIC_BUTTON_SIZE,
            BASIC_BUTTON_COLOR_TYPE,
        };
    },
});
