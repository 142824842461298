import { defineComponent, ref, onBeforeUnmount, onMounted, computed } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import isNil from 'lodash-es/isNil';
import { withLoading } from '@hems/component';
import { CommonService } from '@hems/service';
import DeviceService from '@hems/service/src/api/device/DeviceService';
import { apiWrapper } from '@hems/service/src/util/helper';
import { useAccessToken, useACDeviceType, useAppMode, useSiteId, useSiteMasterDeviceInfo, useStomp } from '@hems/util';
import { FIFTY_SECONDS_VALUE_IN_MILLISECONDS, TEN_SECONDS_VALUE_IN_MILLISECONDS } from '@hems/util/src/constant';
import { NETWORK_TYPE_CODE } from '@hems/util/src/constant/network';
import { isJSON } from '@hems/util/src/helper/helper';
import { DEFAULT_SITE_ENERGY_DATA, formatToZeroByRange } from '@hems/util/src/helper/siteEnergyHelper';
// FIXME: 절대 경로로 변경 시 node_modules 내의 경로로 찾는 문제
import EnergyFlowTimeoutPopup from './EnergyFlowTimeoutPopup.vue';
import SiteEnergyFlowContainer from './flow/SiteEnergyFlowContainer.vue';
import SiteEnergyValueContainer from './value/SiteEnergyValueContainer.vue';
const SITE_ENERGY_URL = `${process.env.VUE_APP_API_URL?.replace('https://', 'wss://')}${apiWrapper.managerApiV1('/web-socket')}`;
export default defineComponent({
    name: 'SiteEnergyContainer',
    components: {
        SiteEnergyValueContainer,
        SiteEnergyFlowContainer,
        EnergyFlowTimeoutPopup,
    },
    setup() {
        const { siteId } = useSiteId();
        const commonService = new CommonService(window.axiosInstance.axios);
        const deviceService = new DeviceService(window.axiosInstance.axios);
        const { masterDeviceId, isConsumptionCT } = useSiteMasterDeviceInfo();
        const { isThirdPartyCase } = useACDeviceType();
        const { stompConnect, stompDisconnect, stompSubscribe, stompPublish, isStompConnected } = useStomp();
        const { isLocal } = useAppMode();
        const isInit = ref(false);
        const disconnect = ref(false);
        const isSiteEnergyTimeoutPopup = ref(false);
        const isCellular = ref(false);
        const stompSubscription = ref(null);
        const transformSiteEnergyData = (data) => {
            return {
                eventTime: data.eventTime,
                energyControl: data.energyControl,
                pvFault: data.pv.fault,
                batteryFault: data.battery.fault,
                gridFault: data.grid.fault,
                batteryUserSoc: data.battery.userSoc,
                batteryRealSoc: data.battery.realSoc,
                consumptionPower: formatToZeroByRange(data.load.power + data.coreLoad.power),
                loadPower: formatToZeroByRange(data.load.power),
                coreLoadPower: formatToZeroByRange(data.coreLoad.power),
                batteryPower: formatToZeroByRange(data.battery.power),
                gridPower: data.grid.status ? formatToZeroByRange(data.grid.power) : 0,
                pvPower: formatToZeroByRange(isThirdPartyCase ? data.externalPv.power : data.pv.power),
                networkType: data.networkType,
                gridStatus: data.grid.status,
                generatorPower: formatToZeroByRange(data.generator.power),
                externalPvPower: formatToZeroByRange(data.externalPv.power),
            };
        };
        const siteEnergyData = ref(DEFAULT_SITE_ENERGY_DATA);
        const { accessToken } = useAccessToken();
        const subscribeConfig = computed(() => ({
            subscribeTopic: `/site/${siteId.value}/real-time/response`,
            subscribeCallback: (message) => {
                if (!isJSON(message.body))
                    return;
                const siteEnergyResponse = JSON.parse(message.body);
                siteEnergyData.value = transformSiteEnergyData(siteEnergyResponse);
                if (siteEnergyData.value.networkType === NETWORK_TYPE_CODE.LTE) {
                    isCellular.value = true;
                }
            },
        }));
        const checkConnection = async () => {
            try {
                const isConnected = await commonService.isConnection(masterDeviceId.value ?? '');
                disconnect.value = !isConnected;
            }
            catch (e) {
                console.error(e);
                return false;
            }
        };
        const loadSiteEnergyData = async () => {
            if (isNil(siteId.value))
                return;
            try {
                await checkConnection();
                if (disconnect.value || !accessToken.value)
                    return;
                await stompConnect({
                    brokerURL: SITE_ENERGY_URL,
                    connectHeaders: {
                        Authorization: `Bearer ${accessToken.value}`,
                    },
                });
                stompPublish({ destination: `/site/${siteId.value}/real-time/request` });
                stompSubscription.value = stompSubscribe(subscribeConfig.value.subscribeTopic, subscribeConfig.value.subscribeCallback);
            }
            catch (e) {
                console.error(e);
            }
        };
        const loadSiteNetworkType = async () => {
            if (isNil(siteId.value))
                return;
            try {
                const { networkType } = await deviceService.getSiteNetworkType(siteId.value);
                isCellular.value = networkType === NETWORK_TYPE_CODE.LTE;
            }
            catch (e) {
                console.error(e);
            }
        };
        const { pause: pauseSiteEnergyInterval, resume: resumeSiteEnergyInterval } = useIntervalFn(() => {
            if (isSiteEnergyTimeoutPopup.value)
                return;
            if (stompSubscription.value) {
                stompDisconnect();
            }
            loadSiteEnergyData();
        }, FIFTY_SECONDS_VALUE_IN_MILLISECONDS);
        const { pause: pauseSiteEnergyTimeout, resume: resumeSiteEnergyTimeout } = useIntervalFn(() => {
            if (disconnect.value || !isCellular.value)
                return;
            isSiteEnergyTimeoutPopup.value = true;
            if (stompSubscription.value) {
                stompDisconnect();
            }
            pauseSiteEnergyInterval();
            pauseSiteEnergyTimeout();
        }, FIFTY_SECONDS_VALUE_IN_MILLISECONDS + TEN_SECONDS_VALUE_IN_MILLISECONDS);
        const resumeEnergyFlow = () => {
            isSiteEnergyTimeoutPopup.value = false;
            loadSiteEnergyData();
            resumeSiteEnergyInterval();
            resumeSiteEnergyTimeout();
        };
        onMounted(withLoading(async () => {
            await loadSiteNetworkType();
            loadSiteEnergyData();
            isInit.value = true;
        }));
        onBeforeUnmount(() => {
            stompDisconnect();
        });
        return {
            isInit,
            siteEnergyData,
            disconnect,
            isSiteEnergyTimeoutPopup,
            isConsumptionCT,
            isStompConnected,
            resumeEnergyFlow,
            checkConnection,
        };
    },
});
