
import type { PropType } from 'vue';
import { computed, defineComponent, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import {
  Selector,
  useMessageBox,
  LabelInput,
  HiddenSearchbox,
  BasicCheckbox,
  Image,
  Panel,
  BasicButton,
} from '@hems/component';
import { useLanguage, useLocale } from '@hems/util';
import { DATE_UNIT, DAY_RANGE_31 } from '@hems/util/src/constant';
import { ERROR_RESOLVED_FILTER } from '@hems/util/src/constant/eventHistory';
import { UnknownErrorException } from '@hems/util/src/exception/exception';
import {
  getLocalDateFormat,
  now,
  getDateDifference,
  defaultPeriod,
  sixMonthsPeriod,
  thirtyOneDaysPeriod,
} from '@hems/util/src/helper/dateHelper';
import { isNull } from '@hems/util/src/helper/helper';

import type { Period } from 'hems';

import type { MakeFieldNonNullable } from 'hems/common/utils';
import type { ErrorHistorySearchBoxOptions } from 'hems/event';

export default defineComponent({
  name: 'EventSearchBox',
  components: {
    Selector,
    LabelInput,
    HiddenSearchbox,
    BasicCheckbox,
    Image,
    Panel,
    BasicButton,
  },
  props: {
    condition: {
      type: Object as PropType<ErrorHistorySearchBoxOptions>,
      required: true,
    },
  },
  emits: ['search'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const messageBox = useMessageBox();
    const store = useStore();
    const { locale } = useLocale();
    const maxDate = now();
    const errorResolvedFilterOptions = [
      { text: t('common.current'), value: ERROR_RESOLVED_FILTER.CURRENT },
      { text: t('common.resolved'), value: ERROR_RESOLVED_FILTER.RESOLVED },
    ];

    /** 전체 에러 코드 리스트 */
    const errorCodeList = computed<string[]>(() => store.state.event.codeList);

    const searchCondition = ref<MakeFieldNonNullable<ErrorHistorySearchBoxOptions, 'date' | 'search'>>({
      ...props.condition,
      date: props.condition.date ?? defaultPeriod,
      search: props.condition.search ?? '',
    });
    const filteredErrorCodeList = ref<string[]>([]);
    const searchedErrorCodeList = ref<string[]>([]);
    const searchCode = ref<string>('');

    const { languageCode } = useLanguage();
    const getEventAlarmCodes = async () => {
      // Filtered Alarm 리스트 불러오기 -> wait 따로
      try {
        if (errorCodeList.value.length === 0) {
          await store.dispatch('event/setEventAlarmCodes', languageCode.value);
        }
        filteredErrorCodeList.value = errorCodeList.value;
        searchedErrorCodeList.value = errorCodeList.value;
      } catch (e) {
        throw new UnknownErrorException();
      }
    };

    const filterValue = () => {
      // 체크안된값과 전체리스트 비교 후 체크 안된값 필터링 => checkList와 codeList비교
      const excludedErrorCodes = errorCodeList.value.filter(
        (errorCode) => !filteredErrorCodeList.value.includes(errorCode)
      );
      searchCondition.value.excludeErrorCodes = excludedErrorCodes.join(',');
    };

    const onSearchFilter = () => {
      // Filterd Alarm 검색 로직
      // 프론트에서 검색
      searchedErrorCodeList.value = errorCodeList.value;
      searchCondition.value.search = searchCondition.value.search?.trim();

      if (!isNull(searchCode.value)) {
        // listData filter 후, 검색한 데이터(결과값)를 listData에 할당
        searchedErrorCodeList.value = errorCodeList.value.filter((errorCode) => {
          return errorCode.toLowerCase().includes(searchCode.value?.toLowerCase());
        });
      }
    };

    const validateRangeDate = (value: Period) => {
      const dateDifference = getDateDifference(value, DATE_UNIT.DAYS);

      if (dateDifference > DAY_RANGE_31) {
        messageBox.alert(t('message.period_not_exceed')).open();

        return false;
      }

      return true;
    };

    const onSearch = () => {
      searchCondition.value.search = searchCondition.value.search?.trim();
      searchCondition.value.date =
        searchCondition.value.errorResolved === ERROR_RESOLVED_FILTER.CURRENT ? sixMonthsPeriod : thirtyOneDaysPeriod;

      emit('search', { ...searchCondition.value });
    };

    const placeholder = computed(
      () => `${t('message.enter_search_word')} (${t('device.serial_no')}, ${t('device.err_code')})`
    );

    onMounted(() => {
      getEventAlarmCodes();
    });

    return {
      locale,
      maxDate,
      searchCode,
      searchCondition,
      filteredErrorCodeList,
      searchedErrorCodeList,
      placeholder,
      errorResolvedFilterOptions,
      validateRangeDate,
      onSearch,
      onSearchFilter,
      filterValue,
      getEventAlarmCodes,
      getLocalDateFormat,
    };
  },
});
