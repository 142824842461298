/** Copyright © 2025 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
export const useAppProvider = () => {
    const store = useStore();
    const isFleetApp = computed(() => store.state.appCtx.isFleetApp);
    return { isFleetApp };
};
