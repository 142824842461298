import { defineComponent } from 'vue';
import { PopupWrapper, BasicButton } from '@hems/component';
export default defineComponent({
    name: 'EnergyFlowTimeoutPopup',
    components: {
        PopupWrapper,
        BasicButton,
    },
    emits: ['resume'],
});
