import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { useRouter } from 'vue-router';
import TitlePanel from '@hems/component/src/panels/TitlePanel.vue';
import { DeviceServiceInstaller } from '@hems/service';
import { DateHelper, useLoading } from '@hems/util';
import { ONE_MINUTE_VALUE_IN_MILLISECONDS } from '@hems/util/src/constant';
export default defineComponent({
    name: 'ProductList',
    components: { TitlePanel },
    setup() {
        const router = useRouter();
        const { startLoading, stopLoading } = useLoading();
        const deviceService = new DeviceServiceInstaller(window.axiosInstance.axios);
        const deviceList = ref([]);
        const loadRecentInstalledDeviceList = async (useLoading = false) => {
            try {
                useLoading && startLoading();
                const response = await deviceService.getRecentInstalledDeviceList(1);
                deviceList.value = response.map((item) => ({
                    ...item,
                    instl_dt: DateHelper.getLocalDate(item.instl_dt, { isTime: true, needUtcConvert: true }),
                }));
            }
            catch (error) {
                console.error(error);
            }
            finally {
                useLoading && stopLoading();
            }
        };
        const onClickMoreProduct = () => {
            router.push({
                name: 'MonitoringList',
            });
        };
        loadRecentInstalledDeviceList(true);
        const interval = setInterval(() => {
            loadRecentInstalledDeviceList();
        }, ONE_MINUTE_VALUE_IN_MILLISECONDS);
        onBeforeUnmount(() => {
            clearInterval(interval);
        });
        return { deviceList, onClickMoreProduct };
    },
});
