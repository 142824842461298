import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useSiteInfo } from '@hems/util';
import { OPERATION_STATUS_CODE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'SubTab',
    props: {
        multiTabName: {
            type: String,
            default: '',
        },
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const { masterDeviceProfile } = useSiteInfo();
        const siteId = computed(() => route.query.siteId);
        const deviceId = computed(() => route.query.deviceId);
        const statusId = computed(() => masterDeviceProfile?.site_status_cd ?? '');
        const genType = computed(() => store.state.device.gen_type);
        const tabname = computed(() => route.path.replace('/remote/monitoring/details/setting/', '').split('/')[0]);
        const roleNm = computed(() => store.state.user.roleNm);
        const routerQuery = { siteId: siteId.value, deviceId: deviceId.value };
        return {
            siteId,
            deviceId,
            statusId,
            genType,
            tabname,
            roleNm,
            routerQuery,
            OPERATION_STATUS_CODE,
        };
    },
});
