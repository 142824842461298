import { useStore } from 'vuex';
import { TOAST_MESSAGE_STATUS_TYPE } from '@hems/util/src/constant';
import { TOAST_MUTATION } from '@hems/util/src/stores/modules/toast';
// eslint-disable-next-line import/prefer-default-export
export const useToast = (messageOpenTime = 2000) => {
    const store = useStore();
    const openToastMessage = (message, isError = false, isTOU = true) => {
        store.commit(TOAST_MUTATION.SET_MESSAGE_INFO, {
            message,
            type: isError ? TOAST_MESSAGE_STATUS_TYPE.ERROR : TOAST_MESSAGE_STATUS_TYPE.SUCCESS,
            isTOU,
        });
        store.commit(TOAST_MUTATION.SET_IS_SHOW, true);
        setTimeout(() => {
            store.commit(TOAST_MUTATION.SET_IS_SHOW, false);
        }, messageOpenTime);
    };
    return {
        openToastMessage,
    };
};
