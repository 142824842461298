import type { ValueType } from 'hems/common/utils';
/** 권한별 UI 기능 리스트 테이블 https://growingenergylabs.atlassian.net/wiki/x/wQE14wE */

export const ROLE_NAME = {
  USER: 'user',
  INSTALLER: 'installer',
  DEV: 'dev',
  SERVICE: 'service',
  ADMIN: 'admin',
} as const;

export type RoleNameValue = ValueType<typeof ROLE_NAME>;

export const ROLE_CODE = {
  USER: '10',
  INSTALLER: '20',
  DEV: '60',
  SERVICE: '70',
  ADMIN: '90',
} as const;

export type RoleCodeValue = ValueType<typeof ROLE_CODE>;

// 참고 문서: Role Matrix - https://growingenergylabs.atlassian.net/wiki/x/AgDJSAI
export const FLEET_ROLE = {
  /** DEV */
  DEVOPS: 'DevOps',
  /** DEV */
  QCELLS_SUPER_ADMIN: 'Qcells Super Admin',
  /** DEV */
  QCELLS_ADMIN: 'Qcells Admin',
  /** Admin */
  QCELLS_MANAGER: 'Qcells Manager',
  /** Service */
  QCELLS_VIEWER: 'Qcells Viewer',
  /** Installer */
  VPP_PARTNER_ADMIN: 'VPP Partner Admin',
  /** Installer */
  VPP_MANAGER: 'VPP Manager',
  /** Installer */
  VPP_VIEWER: 'VPP Viewer',
  /** Installer */
  FLEET_PARTNER_ADMIN: 'Fleet Partner Admin',
  /** Installer */
  FLEET_MANAGER: 'Fleet Manager',
  /** Installer */
  FLEET_VIEWER: 'Fleet Viewer',
} as const;

export type FleetRoleValue = ValueType<typeof FLEET_ROLE>;
