<template>
  <div v-if="isSupported">
    <BasicButton :color-type="BASIC_BUTTON_COLOR_TYPE.SECONDARY" @click="copy(copyData)">
      <span>{{ copied ? $t('message.copied') + '!' : $t('message.copy') }}</span>
    </BasicButton>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { useClipboard } from '@vueuse/core';

import BasicButton from '@hems/component/src/buttons/BasicButton.vue';
import { BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
export default defineComponent({
  name: 'ClipboardButton',
  components: {
    BasicButton,
  },
  props: {
    copyData: {
      type: String,
      required: true,
    },
  },
  setup() {
    const copiedDuring = 5000; // 5초 동안 Copied! 버튼 유지

    const { copy, copied, isSupported } = useClipboard({ copiedDuring });

    return {
      copy,
      copied,
      isSupported,
      BASIC_BUTTON_COLOR_TYPE,
    };
  },
});
</script>

<style scoped></style>
