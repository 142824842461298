import { defineComponent } from 'vue';
import { IconButton } from '@hems/component';
export default defineComponent({
    name: 'StatusPanel',
    components: {
        IconButton,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        hasModify: {
            type: Boolean,
            default: false,
        },
        hasNoAutoGroupInfo: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['clickModify', 'clickDelete', 'clickCreate'],
});
