import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39f1b2f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip_wrap" }
const _hoisted_2 = {
  ref: "tooltipArrowRef",
  class: "tooltip_arrow"
}
const _hoisted_3 = {
  key: 0,
  class: "tooltip_text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.hasTooltipIcon)
      ? (_openBlock(), _createBlock(_component_Image2, {
          key: 0,
          class: "tooltip_icon",
          src: "fleet/ic_info",
          extension: "svg"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("div", {
      ref: "tooltipTextWrapRef",
      class: _normalizeClass(["tooltip_text_wrap", _ctx.tooltipPosition])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Image2, {
          src: "fleet/ic_tooltip_arrow",
          extension: "svg"
        })
      ], 512),
      (_ctx.tooltipText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.tooltipText), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}