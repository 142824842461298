export const ENERGY_PRODUCT_TYPE = {
    BATTERY: 'battery',
    PV: 'pv',
    LOAD: 'load',
    HOME_ENERGY: 'homeEnergy',
    GRID: 'grid',
};
export const NETWORK_TYPE = {
    ETHERNET: 0,
    WIFI: 1,
    LTE: 2,
    UNKNOWN: -1,
};
export const ENERGY_FLOW_IMAGE = {
    HOME_IMAGE_PATH: 'energyflow2/ic_re_lt_ef_layer_set_1_img',
    PIPE_IMAGE_PATH: 'energyflow2/ic_re_lt_ef_layer_set_2_img',
    OFF_GRID_PIPE_IMAGE_PATH: 'energyflow2/ic_re_lt_ef_layer_set_2_offgrid_img',
    PRODUCT_IMAGE_PATH: 'energyflow2/ic_re_lt_ef_layer_set_3_img',
    AC_HOME_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer1_no-inverter',
    AC_PIPE_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer2_no-inverter',
    AC_PIPE_NO_BATTERY_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer2_no-inverter-no-battery',
    AC_PIPE_NO_PV_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer2_no-inverter-no-pv',
    AC_OFF_GRID_PIPE_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer2_offgrid_no-inverter 1',
    AC_OFF_GRID_PIPE_NO_BATTERY_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer2_offgrid_no-inverter-no-battery 1',
    AC_OFF_GRID_PIPE_NO_PV_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer2_offgrid_no-inverter-no-pv 1',
    AC_OFF_GRID_PIPE_GENERATOR_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer2_offgrid_generator_lt 1',
    AC_PRODUCT_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer3_no-inverter_lt 1',
    AC_PRODUCT_NO_BATTERY_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer3_no-inverter-no-battery',
    AC_PRODUCT_NO_PV_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer3_no-inverter-no-pv_lt 1',
    AC_PRODUCT_NO_CONSUMPTION_CT_IMAGE_PATH: 'energyflow2/acsystem/img_ef_layer3_no-inverter-no-battery-no-grid_lt 1',
};
export const ENERGY_VALUE_IMAGE = {
    INFO_IMAGE_PATH: 'icons/ic_re_info',
    PV_MODULE_IMAGE_PATH: 'energyflow2/ic_re_pvmodule',
    LOAD_IMAGE_PATH: 'energyflow2/ic_re_myhome_dr',
    BATTERY_IMAGE_PATH: 'energyflow2/ic_re_pi_battery_left_view',
    HOME_APPLIANCE_IMAGE_PATH: 'energyflow2/ic_re_home_energy_wh',
    AC_MODULE_IMAGE_PATH: 'energyflow2/acsystem/ic_acmodule_right 1',
    AC_BATTERY_IMAGE_PATH: 'energyflow2/acsystem/ic_us_battery_left 1',
    BATTERY_CHARGING_STATUS_IMAGE_PATH: 'energyflow2/ic_re_lt_charging_solid',
    BATTERY_DISCHARGING_STATUS_IMAGE_PATH: 'energyflow2/ic_re_lt_discharging_solid',
    BATTERY_FULL_CHARGED_STATUS_IMAGE_PATH: 'energyflow2/ic_re_full_charged_solid',
    PRODUCING_STATUS_IMAGE_PATH: 'energyflow2/ic_re_lt_charging_solid',
    CONSUMING_STATUS_IMAGE_PATH: 'energyflow2/ic_re_consuming_p2',
    STANDBY_STATUS_IMAGE_PATH: 'energyflow2/ic_re_standby_default',
    DISCONNECTED_STATUS_IMAGE_PATH: 'energyflow2/ic_re_disconnected_product_default',
};
export const ENERGY_VALUE_IMAGE_PRO = {
    BATTERY_CHARGING_STATUS_IMAGE_PATH: 'fleet/ic_re_lt_charging_solid',
    BATTERY_DISCHARGING_STATUS_IMAGE_PATH: 'fleet/ic_re_lt_discharging_solid',
    PRODUCING_STATUS_IMAGE_PATH: 'fleet/ic_re_lt_charging_solid',
    CONSUMING_STATUS_IMAGE_PATH: 'fleet/ic_re_consuming_p2',
    BATTERY_FULL_CHARGED_STATUS_IMAGE_PATH: 'fleet/ic_re_full_charged_solid',
};
export const FLOW_ANIMATION = {
    AC_PV_TO_BATTERY: 'energyflow2/no-inverter_PVtoB',
    AC_PV_TO_GRID: 'energyflow2/no-inverter_PVtoG',
    AC_PV_TO_LOAD: 'energyflow2/no-inverter_PVtoL',
    AC_BATTERY_TO_GRID: 'energyflow2/no-inverter_BtoG',
    AC_BATTERY_TO_LOAD: 'energyflow2/no-inverter_BtoL',
    AC_GRID_TO_BATTERY: 'energyflow2/no-inverter_GtoB',
    AC_GRID_TO_LOAD: 'energyflow2/no-inverter_GtoL',
    AC_GENERATOR_TO_LOAD: 'energyflow2/no-inverter_Generator-to-Load',
};
export const NETWORK_TYPE_IMAGE = {
    ETHERNET_IMAGE_PATH: 'energyflow2/ic_network_offline',
    WIFI_IMAGE_PATH: 'energyflow2/ic_wifi_on',
    LTE_IMAGE_PATH: 'energyflow2/ic_cellular_on',
};
export const NETWORK_TYPE_IMAGE_PRO = {
    ETHERNET_IMAGE_PATH: 'fleet/ic_network_offline',
    WIFI_IMAGE_PATH: 'fleet/ic_wifi_on',
    LTE_IMAGE_PATH: 'fleet/ic_cellular_on',
};
export const SITE_ENERGY_MQTT_EMS_VERSION = '0702';
