/** Copyright © 2025 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useAlertModal, usePermission } from '@hems/util';
import { AUTH_STATUS } from '@hems/util/src/constant';
import { saveAuthToken } from '@hems/util/src/helper/authHelper';
import { USER_ACTION } from '@hems/util/src/stores/modules/user';
export const useAuthentication = () => {
    const store = useStore();
    const { t } = useI18n();
    const { processPermissionAndSetUserRole } = usePermission();
    const userName = computed(() => store.state.user.userId);
    const isLogin = computed(() => store.getters['user/isAuthenticated']);
    const tokenExpiredAlertModal = useAlertModal({ content: t('message.token_expired') });
    const setTokenInfo = async (authToken) => {
        if (!authToken.accessToken)
            return;
        saveAuthToken(authToken);
        const isRoleSetSuccessfully = await processPermissionAndSetUserRole(authToken);
        if (isRoleSetSuccessfully) {
            const authStatus = await store.dispatch(USER_ACTION.SET_AUTH_TOKEN, authToken);
            if (authStatus === AUTH_STATUS.SUCCESS)
                return;
        }
        // 토큰 만료 여부 체크
        if (store.getters['user/isTokenExpired']) {
            tokenExpiredAlertModal.open();
            store.commit('user/clear');
            return;
        }
    };
    return {
        isLogin,
        setTokenInfo,
        userName,
    };
};
