import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicInput = _resolveComponent("BasicInput")!
  const _component_SettingInfoItem = _resolveComponent("SettingInfoItem")!
  const _component_TruncateInput = _resolveComponent("TruncateInput")!
  const _component_Selector = _resolveComponent("Selector")!
  const _component_LabelInput = _resolveComponent("LabelInput")!
  const _component_SettingInfoBlock = _resolveComponent("SettingInfoBlock")!

  return (_openBlock(), _createBlock(_component_SettingInfoBlock, null, {
    default: _withCtx(() => [
      _createVNode(_component_SettingInfoItem, {
        title: _ctx.$t('common.site_id')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicInput, {
            modelValue: _ctx.accountInfo.siteId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accountInfo.siteId) = $event)),
            name: "siteId",
            type: _ctx.INPUT_TYPE.NUMBER,
            readonly: ""
          }, null, 8, ["modelValue", "type"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_SettingInfoItem, {
        title: _ctx.$t('account.id')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TruncateInput, {
            modelValue: _ctx.accountInfo.id,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accountInfo.id) = $event)),
            class: "ok",
            name: "id",
            readonly: true,
            "tooltip-text": _ctx.accountInfo.id || ''
          }, null, 8, ["modelValue", "tooltip-text"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_SettingInfoItem, {
        title: _ctx.$t('account.first_name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicInput, {
            modelValue: _ctx.accountInfo.firstName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.accountInfo.firstName) = $event)),
            name: "firstName",
            readonly: !_ctx.state.editable
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_SettingInfoItem, {
        title: _ctx.$t('account.last_name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicInput, {
            modelValue: _ctx.accountInfo.lastName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.accountInfo.lastName) = $event)),
            name: "lastName",
            readonly: !_ctx.state.editable
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_SettingInfoItem, {
        title: _ctx.$t('account.name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicInput, {
            modelValue: _ctx.accountInfo.displayName,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.accountInfo.displayName) = $event)),
            name: "displayName",
            readonly: !_ctx.state.editable
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_SettingInfoItem, {
        title: _ctx.$t('account.email')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicInput, {
            modelValue: _ctx.accountInfo.email,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.accountInfo.email) = $event)),
            name: "email",
            readonly: !_ctx.state.editable,
            "validate-mode": "blur"
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_SettingInfoItem, {
        title: _ctx.$t('account.telephone'),
        "is-multi-values": ""
      }, {
        default: _withCtx(() => [
          (!_ctx.state.editable)
            ? (_openBlock(), _createBlock(_component_BasicInput, {
                key: 0,
                modelValue: _ctx.telephoneNumberText,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.telephoneNumberText) = $event)),
                name: "telephoneNumberText",
                readonly: ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.state.editable)
            ? (_openBlock(), _createBlock(_component_Selector, {
                key: 1,
                modelValue: _ctx.accountInfo.region,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.accountInfo.region) = $event)),
                label: _ctx.$t('common.region'),
                name: "local_mpn_no",
                readonly: !_ctx.state.editable,
                options: _ctx.dialSelectOptions,
                "selected-display-type": "value",
                "is-display-selected-in-list": false
              }, null, 8, ["modelValue", "label", "readonly", "options"]))
            : _createCommentVNode("", true),
          (_ctx.state.editable)
            ? (_openBlock(), _createBlock(_component_LabelInput, {
                key: 2,
                modelValue: _ctx.accountInfo.telephone,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.accountInfo.telephone) = $event)),
                label: _ctx.$t('account.telephone'),
                name: "telephone",
                readonly: !_ctx.state.editable
              }, null, 8, ["modelValue", "label", "readonly"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }))
}