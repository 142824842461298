import type { ValueType } from 'hems/common/utils';

export const FIRMWARE_UPDATE_RESPONSE_CODE = {
  SUCCESS: 'success',
  IN_PROGRESS: 'in_progress',
  FAIL: 'fail',
} as const;

export type FirmwareUpdateResponseCode = ValueType<typeof FIRMWARE_UPDATE_RESPONSE_CODE>;

export const FIRMWARE_UPDATE_API_RESPONSE_CODE = {
  SUCCESS: 'SUCCESS',
  DUPLICATE: 'DUPLICATE',
} as const;

export type FirmwareUpdateApiResponseCode = ValueType<typeof FIRMWARE_UPDATE_API_RESPONSE_CODE>;

export type FirmwareUpdateTemplateRecordValue = { class: string; name: string };

export const FIRMWARE_RESULT_GRID_HEIGHT_SIZE = {
  SMALL: 170,
  MEDIUM: 360,
} as const;

export const SITE_CONFIGURATION_TYPE = {
  SOLAR: 'SOLAR',
  BACKUP: 'BACKUP',
  GRID: 'GRID',
} as const;

export type SiteConfigurationType = ValueType<typeof SITE_CONFIGURATION_TYPE>;

export const FIRMWARE_UPDATE_SEARCH_TYPE = {
  GROUP: 'group',
  DEVICE: 'device',
} as const;

export type FirmwareUpdateSearchType = ValueType<typeof FIRMWARE_UPDATE_SEARCH_TYPE>;

export const FIRMWARE_UPDATE_FILE = {
  EMS: 'EMS',
  BMS: 'BMS',
  PCS: 'PCS',
  HUB: 'HUB',
  GEM: 'GEM',
  USB: 'USB',
} as const;

export type FirmwareUpdateFileType = ValueType<typeof FIRMWARE_UPDATE_FILE>;
export type LowercaseFirmwareUpdateFileType = Lowercase<FirmwareUpdateFileType>;

export const EMS_FIRMWARE_UPDATE_FILE = {
  FULL: 'FULL',
  APP: 'APP',
} as const;

export type EMSFirmwareUpdateFileType = ValueType<typeof EMS_FIRMWARE_UPDATE_FILE>;
export type LowercaseFirmwareUpdateEMSFileType = Lowercase<EMSFirmwareUpdateFileType>;

export type UploadFileType = `${FirmwareUpdateFileType}${EMSFirmwareUpdateFileType}`;

/** [날짜 5자리] : 년년월일일, 월은 1월에서 12월까지 1~9ABC로 표현 */
/** YYMDD ==> 9월: 9, 10월: A, 11월: B */
const dateRule = '([0-9][0-9][1-9A-C][0-3][0-9])';
/** GEM_HOST 버전명 Hex 8자리 */
const gemVersionRule = '([A-Fa-f0-9]{8})';
/** 버전명은 모든 문자/숫자가 올 수 있다. */
const allCharacterRule = '(.+)';

export const FIRMWARE_UPDATE_FILE_FORMAT_REGEX = {
  EMS: `^A_EMS_${allCharacterRule}\\.zip$`,
  GEM: `^ACCB_GEM_MI_${dateRule}_${gemVersionRule}(?:_.*)?\\.zip$`,
  PCS: `^ACES_PCS_${dateRule}_${allCharacterRule}\\.zip$`,
  /** BMS의 경우 ACES_BMS / BMS_ 로 파일명이 시작할 수 있다. */
  /** BMS의 경우 우리가 만드는 것이 아니기 때문에 date, version 명에 어떤 것이 들어올 지 모른다 */
  BMS: `^(ACES_)?BMS_${allCharacterRule}_${allCharacterRule}\\.zip$`,
  HUB: `^ACES_HUB_${dateRule}_${allCharacterRule}\\.zip$`,
  /** USB파일명 규칙: USB_MMDDYYYY_XX.bz2 */
  USB: `^USB_\\d{8}_.{2}\\.bz2$`,
};

export const FIRMWARE_UPDATE_TYPE = {
  AUTO: 'AUTO',
  MANUAL: 'MANUAL',
} as const;

export type FirmwareUpdateType = ValueType<typeof FIRMWARE_UPDATE_TYPE>;

export const FIRMWARE_UPDATE_PRESIGNED_URL_TYPE = {
  DOWNLOAD: 'DOWNLOAD',
  UPLOAD: 'UPLOAD',
} as const;

export type FirmwareUpdatePresignedUrlType = ValueType<typeof FIRMWARE_UPDATE_PRESIGNED_URL_TYPE>;

/** 펌웨어 업데이트 Sorting 가능한 컬럼들 */
export const FIRMWARE_UPDATE_SORT_COLUMN = {
  REQUEST_DATE_ASC: '+requestDate',
  REQUEST_DATE_DESC: '-requestDate',
  GROUP_NAME_ASC: '+updateGroupId',
  GROUP_NAME_DESC: '-updateGroupId',
  EMS_VERSION_ASC: '+emsVersion',
  EMS_VERSION_DESC: '-emsVersion',
  PCS_VERSION_ASC: '+pcsVersion',
  PCS_VERSION_DESC: '-pcsVersion',
  BMS_VERSION_ASC: '+bmsVersion',
  BMS_VERSION_DESC: '-bmsVersion',
  HUB_VERSION_ASC: '+hubVersion',
  HUB_VERSION_DESC: '-hubVersion',
  GEM_VERSION_ASC: '+gemVersion',
  GEM_VERSION_DESC: '-gemVersion',
  RESPONSE_DATE_ASC: '+responseDate',
  RESPONSE_DATE_DESC: '-responseDate',
  DEVICE_ID_ASC: '+deviceId',
  DEVICE_ID_DESC: '-deviceId',
  SITE_ID_ASC: '+siteId',
  SITE_ID_DESC: '-siteId',
};

export const FIRMWARE_AUTO_UPDATE_STATUS = {
  ACTIVE: 'ACTIVE',
  HISTORY: 'HISTORY',
} as const;

export type FirmwareAutoUpdateStatus = ValueType<typeof FIRMWARE_AUTO_UPDATE_STATUS>;
