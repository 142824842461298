/**  Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type * as Highcharts from 'highcharts';
import isNil from 'lodash-es/isNil';

import { BASIC_GRAY_COLOR, GRAPH_SERIES_TYPE, HOME_2_COLOR, PRO_PRIMARY_COLOR, UNIT } from '@hems/util/src/constant';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';

import type { TodayEnergyChartData } from 'hems/report';

const xLabels = ['6AM', '9AM', '12PM', '3PM', '6PM', '9PM'];

const DEFAULT_CHART_HEIGHT = 130;

type TodayEnergyChartArguments = {
  data: TodayEnergyChartData[];
  isUserApp?: boolean;
  name?: string;
  height?: number;
};

export const getTodayEnergyChartOptions = ({
  data,
  isUserApp = true,
  name = '',
  height = DEFAULT_CHART_HEIGHT,
}: TodayEnergyChartArguments): Highcharts.Options => {
  return {
    chart: {
      type: GRAPH_SERIES_TYPE.LINE,
      height,
      zoomType: 'x',
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      visible: false,
    },
    xAxis: {
      categories: xLabels,
      labels: {
        style: {
          color: BASIC_GRAY_COLOR.GRAY_500,
          fontSize: '10px',
          fontWeight: '300',
        },
      },
    },
    tooltip: {
      formatter: (tooltip: Highcharts.Tooltip) => {
        return getTooltipFormatter(tooltip);
      },
    },
    series: [
      {
        type: GRAPH_SERIES_TYPE.LINE,
        name,
        color: isUserApp ? HOME_2_COLOR.PRIMARY_MINT : PRO_PRIMARY_COLOR.DEFAULT,
        marker: {
          radius: 2,
        },
        data: data.map((item) => item.y),
        lineWidth: 1,
      },
    ],
  };
};

export const checkDateRange = (date: string) => {
  const time = date.substring(8, 10);
  const regexp3hours = /(06|09|12|15|18|21)$/g;

  return regexp3hours.test(time);
};

const getTooltipFormatter = (tooltip: Highcharts.Tooltip) => {
  if (isNil(tooltip.chart.hoverPoint)) return '';

  const xValue = tooltip.chart.hoverPoint.category;

  const { formattedNumber, unit } = formatUnitNumber(tooltip.chart.hoverPoint.y ?? 0, UNIT.WATT_HOUR);

  const yValue = `<span style="color: ${tooltip.chart.hoverPoint.color}">\u25CF</span> ${formattedNumber} ${unit}`;

  return [xValue, yValue].join('<br />');
};
