/** Copyright © 2025 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { FLEET_ROLE } from '@hems/util/src/constant/role';
export const authTypeAndRoleData = {
    context: {},
    data: {
        email: 'gayoung.koh@qcells.com',
        accountType: '60',
        role: FLEET_ROLE.QCELLS_SUPER_ADMIN,
        firstName: 'gayoung',
        lastName: 'koh',
        accountId: 'gayoung.koh@qcells.com',
    },
};
