import { defineComponent } from 'vue';
import isNil from 'lodash-es/isNil';
import ErrorCodeChipsGroup from '@hems/component/src/chips/ErrorCodeChipsGroup.vue';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
import { isDisplayedSiteErrorInfoType } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'SiteInfoBlock',
    components: {
        ErrorCodeChipsGroup,
    },
    props: {
        siteInfoDataList: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
    },
    setup() {
        const getIndentClassName = ({ isIndent, indentDepth = 1 }) => {
            if (isIndent) {
                return `indent${indentDepth}`;
            }
        };
        const getFormattedUnitNumber = ({ value, unit: tableUnit }) => {
            if (isNil(value) || typeof value !== 'number' || Array.isArray(value) || !tableUnit) {
                return { origin: value, unit: tableUnit, formattedNumber: value };
            }
            return formatUnitNumber(value, tableUnit);
        };
        const getUnitText = (tableData) => {
            if (!tableData.unit && !tableData.formattedUnit) {
                return '';
            }
            if (Array.isArray(tableData.value) || isNil(tableData.value)) {
                return `[${tableData.formattedUnit || tableData.unit}]`;
            }
            const { unit } = getFormattedUnitNumber(tableData);
            return ` [${unit}]`;
        };
        return {
            getIndentClassName,
            isDisplayedSiteErrorInfoType,
            getFormattedUnitNumber,
            getUnitText,
        };
    },
});
