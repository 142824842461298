/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/

import type { LocationQueryValue } from 'vue-router';

import isNil from 'lodash-es/isNil';
import type { CellValue, Row, RowKey } from 'tui-grid';
import type Grid from 'tui-grid';

import type {
  ApplianceTypeValue,
  AppModeValue,
  EnergyPolicy,
  LanguageValue,
  SettingTargetValue,
  SiteStatusCodeType,
  SortOrderValue,
  UnitValueType,
  WattHourUnitValue,
} from '@hems/util/src/constant';
import { APPLIANCE_TYPE, LANGUAGE, SETTING_TARGET, UNIT, WATT_HOUR_UNIT } from '@hems/util/src/constant';
import { FORM_TYPE, GROUP_MODE, ENERGY_POLICY, APP_MODE, ENV_LOCALE } from '@hems/util/src/constant/constant';
import type { ACDeviceTypeValue } from '@hems/util/src/constant/device';
import { AC_DEVICE_TYPE, GEN_TYPE } from '@hems/util/src/constant/device';
import type {
  FirmwareUpdateType,
  LowercaseFirmwareUpdateFileType,
  SiteConfigurationType,
} from '@hems/util/src/constant/firmwareUpdate';
import { FIRMWARE_UPDATE_TYPE, SITE_CONFIGURATION_TYPE } from '@hems/util/src/constant/firmwareUpdate';
import type { GraphGroupCodeValue } from '@hems/util/src/constant/graph';
import { GRAPH_GROUP_CODE } from '@hems/util/src/constant/graph';
import { ROLE_CODE, ROLE_NAME } from '@hems/util/src/constant/role';
import type { RoleCodeValue, RoleNameValue } from '@hems/util/src/constant/role';
import { getDataTypeofValue, isEmptyString } from '@hems/util/src/helper/helper';

import type { Auth, FormMode, Period, YesOrNo } from 'hems';

import type { EnvLocale, GridEventType, GroupSelectOption, ListSize, SelectorOption } from 'hems/common/types';
import type { GenType, MonitoringListDeviceInfo, MonitoringListSiteInfo, YesOrNoType } from 'hems/device';
import type { DeviceProfileACSystem } from 'hems/device/dashboard/smartmodule/acsys';
import type { PvStringPowerIndexType } from 'hems/device/settings';
import type { Device, DeviceAC, DeviceProfileAllGenType } from 'hems/device/siteinfo';
import type { ESSNumberType } from 'hems/device/statistics/common';
import type { ErrorCodeDisplayDetails, DisplayedSiteErrorInfo } from 'hems/event';
import type { FirmwareEMSFileNameKey, FirmwareFileNameKey, FirmwareFileVersionKey } from 'hems/firmwareUpdate';
import type { GroupMode } from 'hems/group/types';

type RouteValueType = LocationQueryValue | LocationQueryValue[] | string | string[] | number;

export function isStringType(value: RouteValueType): value is string {
  return typeof value === 'string';
}

export function isYesOrNoType(value: RouteValueType): value is YesOrNoType {
  return value === 'Y' || value === 'N';
}

export function isFormModeType(value: RouteValueType): value is FormMode {
  return value === FORM_TYPE.READ || value === FORM_TYPE.EDIT || value === FORM_TYPE.NEW;
}

export function isGroupModeType(value: RouteValueType): value is GroupMode {
  return value === GROUP_MODE.READ || value === GROUP_MODE.NEW_COPY || value === GROUP_MODE.NEW;
}
export function isGenType(value: RouteValueType): value is GenType {
  return (
    value === GEN_TYPE.GEN2 ||
    value === GEN_TYPE.GEN3 ||
    value === GEN_TYPE.FOX_ESS ||
    value === GEN_TYPE.FOX_ESS_H3 ||
    value === GEN_TYPE.AC_SYS
  );
}

export const getRouteInfo = <T extends RouteValueType>(
  value: RouteValueType,
  isTypeGuardValue: (value: RouteValueType) => value is T
): T | undefined => {
  if (isTypeGuardValue(value)) return value;

  return;
};

export const isPeriod = (date: Date | Period): date is Period => {
  return 'start' in date && 'end' in date;
};

export const isNumberType = (value: RouteValueType): value is number => {
  return typeof value === 'number';
};

export const getStringValue = (value?: string | null) => {
  if (value === undefined || value === null || isEmptyString(value.trim())) {
    return null;
  }

  return value;
};

export const getYesOrNoValue = (value: string): YesOrNo => {
  return isYesOrNoType(value) ? value : 'Y';
};

export const isDeviceACType = (device: Device): device is DeviceAC => {
  return device.genType === GEN_TYPE.AC_SYS;
};

export const isDeviceACListType = (deviceList: Device[]): deviceList is DeviceAC[] => {
  return deviceList.every((device) => isDeviceACType(device));
};

export const isACSystemType = (deviceInfo: DeviceProfileAllGenType): deviceInfo is DeviceProfileACSystem => {
  return deviceInfo.gen_type === GEN_TYPE.AC_SYS;
};

export const getNumberValue = (value?: number | null) => {
  if (value === undefined || value === null) {
    return null;
  }

  return value;
};

export const isACDeviceType = (value: string): value is ACDeviceTypeValue => {
  const ACDeviceTypeValueList: string[] = Object.values(AC_DEVICE_TYPE);

  return ACDeviceTypeValueList.includes(value);
};

export const getACDeviceTypeValue = (value?: string | null): ACDeviceTypeValue | null => {
  if (value === undefined || value === null) {
    return null;
  }

  if (isACDeviceType(value)) {
    return value;
  }

  return null;
};

export const isGraphGroupCodeType = (value: string): value is GraphGroupCodeValue => {
  const graphGroupCodeList: string[] = Object.values(GRAPH_GROUP_CODE);

  return graphGroupCodeList.includes(value);
};

export const getGraphGroupCodeTypeValue = (value: string): GraphGroupCodeValue | null => {
  if (isGraphGroupCodeType(value)) {
    return value;
  }

  return null;
};

export const isGraphGroupCodeListType = (value: string[]): value is GraphGroupCodeValue[] => {
  const graphGroupCodeList: string[] = Object.values(GRAPH_GROUP_CODE);

  return value.every((item) => graphGroupCodeList.includes(item));
};

export const getGraphGroupCodeListTypeValue = (value: string[]): GraphGroupCodeValue[] | null => {
  if (isGraphGroupCodeListType(value)) {
    return value;
  }

  return null;
};

export const isListSize = (value: number): value is ListSize => [10, 20, 50].includes(value);

export const getListSize = (value: number): ListSize | undefined => {
  if (isListSize(value)) return value;
};

export const isSortColumnValue = <T extends string>(
  value: string,
  validSortColumns: T[]
): value is `${T} ${SortOrderValue}` => {
  const validSortOrders: SortOrderValue[] = ['asc', 'desc'];

  const match = value.match(/^(\w+)\s+(asc|desc)$/);
  if (match) {
    const column = match[1] as T;
    const order = match[2] as SortOrderValue;

    if (validSortColumns.includes(column) && validSortOrders.includes(order)) {
      return true;
    }
  }

  return false;
};

export const getSortColumn = <T extends string>(
  value: string,
  validSortColumns: T[]
): `${T} ${SortOrderValue}` | undefined => {
  if (isSortColumnValue(value, validSortColumns)) {
    return value;
  }

  return;
};

export const isPvStringPowerValue = (value: number): value is PvStringPowerIndexType => value >= 1 && value <= 5;

export const isRowKeyType = (rowKey: unknown): rowKey is RowKey =>
  typeof rowKey === 'number' || typeof rowKey === 'string';

export const isErrorCodeDisplayDetails = (errorDetails: CellValue | null): errorDetails is ErrorCodeDisplayDetails =>
  errorDetails !== null && typeof errorDetails === 'object' && 'description' in errorDetails;

export const isDisplayedSiteErrorInfoType = (value: unknown): value is DisplayedSiteErrorInfo[] =>
  Array.isArray(value) &&
  value.every((item) => isObjectType(item) && item && 'errorCode' in item && 'triggerDate' in item);

export const isEnergyPolicyType = (operationMode: string): operationMode is EnergyPolicy => {
  const energyPoliciesValues: string[] = Object.values(ENERGY_POLICY);

  return energyPoliciesValues.includes(operationMode);
};

export const isGroupSelectOptionListType = (optionList: unknown): optionList is GroupSelectOption[] =>
  Array.isArray(optionList) && optionList.every((option) => isObjectType(option) && option && 'items' in option);

export const isSelectOptionListType = (optionList: unknown): optionList is SelectorOption[] =>
  Array.isArray(optionList) &&
  optionList.every((option) => isObjectType(option) && 'text' in option && 'value' in option);

export const isObjectType = (value: unknown): boolean => {
  return getDataTypeofValue(value) === 'Object';
};

export const isSiteStatusCodeType = (value: string): value is SiteStatusCodeType =>
  value === '0' || value === '1' || value === '2' || value === '4' || value === '6';

export const isAuthSuccess = (authResponse: Auth.TokenInfoResponse): authResponse is Auth.TokenInfoResponseSuccess =>
  isObjectType(authResponse) && 'access_token' in authResponse;

export const isSettingTargetType = (value: string): value is SettingTargetValue => {
  const settingTargetValues: string[] = Object.values(SETTING_TARGET);

  return settingTargetValues.includes(value);
};

export const getSettingTargetType = (value: string): SettingTargetValue | null =>
  isSettingTargetType(value) ? value : null;

export const isAppModeValueType = (value: string): value is AppModeValue =>
  value === APP_MODE.LOCAL ||
  value === APP_MODE.DEVELOPMENT ||
  value === APP_MODE.STAGING ||
  value === APP_MODE.PRODUCTION;

export const isWattHourUnitValueType = (unit: string): unit is WattHourUnitValue =>
  unit === WATT_HOUR_UNIT.WATT_HOUR || unit === WATT_HOUR_UNIT.KILO_WATT_HOUR || unit === WATT_HOUR_UNIT.MEGA_WATT_HOUR;

export const isLocaleValueType = (value: string): value is LanguageValue | Extract<EnvLocale, 'AU'> => {
  const localeList: string[] = [...Object.values(LANGUAGE), ENV_LOCALE.AU];

  return localeList.includes(value);
};

export const isDeviceInfoType = (
  item: MonitoringListSiteInfo | MonitoringListDeviceInfo
): item is MonitoringListDeviceInfo => 'total_device' in item;

export const isESSNumberType = (ESSNumber: number): ESSNumber is ESSNumberType => [1, 2, 3, 4].includes(ESSNumber);

export const getESSNumber = (ESSNumber: number): ESSNumberType | null =>
  isESSNumberType(ESSNumber) ? ESSNumber : null;

export const isUnitValueType = (unit: string): unit is UnitValueType => {
  const unitValueList: string[] = Object.values(UNIT);

  return unitValueList.includes(unit);
};

export const isRoleNameType = (value: string): value is RoleNameValue => {
  const roleNameList: string[] = Object.values(ROLE_NAME);

  return roleNameList.includes(value);
};

export const isRoleCodeType = (value: string): value is RoleCodeValue => {
  const roleCodeList: string[] = Object.values(ROLE_CODE);

  return roleCodeList.includes(value);
};

export const isLanguageValueType = (lang: string): lang is LanguageValue => {
  const languageList: string[] = Object.values(LANGUAGE);

  return languageList.includes(lang);
};

export const getLanguageValue = (lang: string): LanguageValue => (isLanguageValueType(lang) ? lang : LANGUAGE.EN);

export const isSupportedApplianceType = (applianceType: string): applianceType is ApplianceTypeValue => {
  const supportedApplianceList: string[] = Object.values(APPLIANCE_TYPE);

  return supportedApplianceList.includes(applianceType);
};

export const isNonNullableType = <T>(value: T | null | undefined): value is T => !isNil(value);

export const isGridCheckEventType = (event: unknown): event is Row => {
  return typeof event === 'object' && event !== null && 'rowKey' in event;
};

export const isGridEventType = (event: unknown): event is GridEventType => {
  return typeof event === 'object' && event !== null;
};

export const isHTMLElementType = (value: EventTarget | null): value is HTMLElement => {
  return value instanceof HTMLElement;
};

export const isSiteConfigurationType = (value: RouteValueType): value is SiteConfigurationType =>
  value === SITE_CONFIGURATION_TYPE.SOLAR ||
  value === SITE_CONFIGURATION_TYPE.BACKUP ||
  value === SITE_CONFIGURATION_TYPE.GRID;

export const isLowercaseFirmwareUpdateFileType = (value: string): value is LowercaseFirmwareUpdateFileType => {
  return ['ems', 'bms', 'pcs', 'hub', 'gem'].includes(value);
};

export const isFirmwareUpdateVersionType = (key: string): key is FirmwareFileVersionKey => {
  return ['emsVersion', 'pcsVersion', 'gemVersion', 'hubVersion', 'bmsVersion'].includes(key);
};

export const isFirmwareUpdateFileType = (key: string): key is FirmwareFileNameKey => {
  return ['emsFileName', 'pcsFileName', 'gemFileName', 'hubFileName', 'bmsFileName'].includes(key);
};

export const isFirmwareUpdateEMSFileType = (key: string): key is FirmwareEMSFileNameKey => {
  return ['appFileName', 'fullFileName'].includes(key);
};

export const isFirmwareUpdateType = (value: RouteValueType): value is FirmwareUpdateType =>
  value === FIRMWARE_UPDATE_TYPE.AUTO || value === FIRMWARE_UPDATE_TYPE.MANUAL;
