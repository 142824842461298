/** Copyright © 2025 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { AxiosInstance } from 'axios';
import omit from 'lodash-es/omit';

import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
import { formatDate } from '@hems/util/src/helper/dateHelper';

import type { CommonResponseWrapper } from 'hems';

import type { RecentActivity, ActivityListSearchOptions, ActivityItem } from 'hems/activityHistory';

export default class ActivityHistoryService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
  }

  getRecentActivityList = async (): Promise<RecentActivity[]> => {
    const { data } = await this.get<CommonResponseWrapper<RecentActivity[]>>(
      apiWrapper.managerApi('/managements/activities/dashboards')
    );

    return data;
  };

  getActivityList = async (params: ActivityListSearchOptions): Promise<ActivityItem[]> => {
    if (!params.date || !(params.date.start && params.date.end)) return [];

    const { start, end } = params.date;

    const newParams: Omit<ActivityListSearchOptions, 'date'> & { fromDate: string; toDate: string } = {
      ...omit(params, 'date'),
      fromDate: formatDate(start),
      toDate: formatDate(end),
    };

    const { data } = await this.get<CommonResponseWrapper<ActivityItem[]>>(
      apiWrapper.managerApi('/managements/activities'),
      newParams
    );

    return data;
  };
}
