/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { AxiosInstance } from 'axios';

import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
import type { ValidateSchedulePresetName } from '@hems/util/src/constant/energyManagement';

import type { CommonResponseWrapper } from 'hems';

import type {
  CommonListQueryParam,
  ScheduleListResponse,
  PresetListResponse,
  PresetOperationListResponse,
  ValidatePresetNameRequestBody,
  ValidatePresetNameResponse,
  CreatePresetRequestBody,
  EmptyResponse,
  OperationInfo,
  IdList,
  CreateTOURequestBody,
  CreateTOUResponse,
} from 'hems/energyManagement';

export default class EnergyManagementService extends Service {
  constructor(axios: AxiosInstance) {
    super(axios);
    this.setContentTypeHeaderInterceptor();
  }

  getScheduleList = async (siteId: number, scheduleListParam?: CommonListQueryParam): Promise<ScheduleListResponse> => {
    const { data } = await this.get<CommonResponseWrapper<ScheduleListResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou`),
      scheduleListParam
    );

    return data;
  };

  createScheduleList = async (siteId: number, siteTOUInfo: CreateTOURequestBody): Promise<CreateTOUResponse> => {
    const { data } = await this.post<CommonResponseWrapper<CreateTOUResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou`),
      siteTOUInfo
    );

    return data;
  };

  getPresetList = async (siteId: number, presetListParam?: CommonListQueryParam): Promise<PresetListResponse> => {
    const { data } = await this.get<CommonResponseWrapper<PresetListResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets`),
      presetListParam
    );

    return data;
  };

  getPresetOperationList = async (
    siteId: number,
    presetId: string,
    presetOperationListParam?: CommonListQueryParam
  ): Promise<PresetOperationListResponse> => {
    const { data } = await this.get<CommonResponseWrapper<PresetOperationListResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets/${presetId}/operations`),
      presetOperationListParam
    );

    return data;
  };

  validatePresetName = async (
    siteId: number,
    presetNameRequestBody: ValidatePresetNameRequestBody
  ): Promise<ValidateSchedulePresetName> => {
    const { data } = await this.post<CommonResponseWrapper<ValidatePresetNameResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets/name/validate`),
      presetNameRequestBody
    );

    return data.body.result;
  };

  createPreset = async (siteId: number, presetNameRequestBody: CreatePresetRequestBody): Promise<EmptyResponse> => {
    const { data } = await this.post<CommonResponseWrapper<EmptyResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets`),
      presetNameRequestBody
    );

    return data;
  };

  deletePreset = async (siteId: number, presetId: string): Promise<EmptyResponse> => {
    const { data } = await this.delete<CommonResponseWrapper<EmptyResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets/${presetId}`)
    );

    return data;
  };

  patchPresetName = async (
    siteId: number,
    presetId: string,
    presetNameRequestBody: CreatePresetRequestBody
  ): Promise<EmptyResponse> => {
    const { data } = await this.patch<CommonResponseWrapper<EmptyResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets/${presetId}`),
      presetNameRequestBody
    );

    return data;
  };

  postPresetOperation = async (siteId: number, presetId: string, operation: OperationInfo): Promise<EmptyResponse> => {
    const { data } = await this.post<CommonResponseWrapper<EmptyResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets/${presetId}/operations`),
      operation
    );

    return data;
  };

  putPresetOperation = async (
    siteId: number,
    presetId: string,
    operationId: string,
    operation: Omit<OperationInfo, 'operationId'>
  ): Promise<EmptyResponse> => {
    const { data } = await this.put<CommonResponseWrapper<EmptyResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets/${presetId}/operations/${operationId}`),
      operation
    );

    return data;
  };

  deletePresetOperationList = async (
    siteId: number,
    presetId: string,
    operationIdList: IdList
  ): Promise<EmptyResponse> => {
    const { data } = await this.post<CommonResponseWrapper<EmptyResponse>>(
      apiWrapper.managerApisV1(`/sites/${siteId}/tou/presets/${presetId}/operations/delete`),
      operationIdList
    );

    return data;
  };
}
