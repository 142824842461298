import type { ColumnOptions } from 'tui-grid';

import { CustomRenderer } from '@hems/component';
import type { ErrorHistoryGridTypeValue } from '@hems/util/src/constant/eventHistory';
import { ERROR_HISTORY_GRID_TYPE } from '@hems/util/src/constant/eventHistory';
import type { RoleNameValue } from '@hems/util/src/constant/role';
import { ROLE_NAME } from '@hems/util/src/constant/role';

import type { i18nTranslation } from 'hems';

const getCommonErrorHistoryColumns = (t: i18nTranslation, isInstaller = false): ColumnOptions[] => {
  return [
    {
      header: t('common.start_dt'),
      name: 'triggerDate',
      align: 'left',
      width: isInstaller ? 240 : 190,
      sortable: true,
      className: 'overflow-visible',
      resizable: true,
      renderer: {
        type: CustomRenderer.UTCTooltipRenderer,
        options: {
          tooltipColumnName: 'triggerDateUtc',
          position: ['bottom', 'right'],
        },
      },
    },
    {
      header: t('common.end_dt'),
      name: 'clearDate',
      align: 'left',
      width: isInstaller ? 240 : 190,
      sortable: true,
      className: 'overflow-visible',
      resizable: true,
      renderer: {
        type: CustomRenderer.UTCTooltipRenderer,
        options: {
          tooltipColumnName: 'clearDateUtc',
          position: ['bottom', 'left'],
        },
      },
    },
    {
      header: t('common.site_id'),
      name: 'siteId',
      align: 'left',
      sortable: true,
      width: isInstaller ? 145 : 108,
    },
    {
      header: t('device.serial_no'),
      name: 'deviceId',
      align: 'left',
      sortable: true,
      width: isInstaller ? 232 : 182,
      resizable: true,
    },
    {
      header: t('device.err_code'),
      name: 'errorCode',
      align: 'left',
      sortable: true,
      width: isInstaller ? 161 : 131,
    },
  ];
};

const getCommonSiteErrorHistoryColumns = (t: i18nTranslation): ColumnOptions[] => {
  return [
    {
      header: t('common.start_dt'),
      name: 'triggerDate',
      align: 'left',
      width: 197,
      sortable: true,
      className: 'overflow-visible',
      renderer: {
        type: CustomRenderer.UTCTooltipRenderer,
        options: {
          tooltipColumnName: 'triggerDateUtc',
          position: ['bottom', 'right'],
        },
      },
    },
    {
      header: t('common.end_dt'),
      name: 'clearDate',
      align: 'left',
      width: 197,
      sortable: true,
      className: 'overflow-visible',
      renderer: {
        type: CustomRenderer.UTCTooltipRenderer,
        options: {
          tooltipColumnName: 'clearDateUtc',
          position: ['bottom', 'left'],
        },
      },
    },
    {
      header: t('device.serial_no'),
      name: 'deviceId',
      align: 'left',
      sortable: true,
      width: 194,
      resizable: true,
    },
    {
      header: t('device.err_code'),
      name: 'errorCode',
      align: 'left',
      sortable: true,
      width: 121,
    },
  ];
};

const getErrorLevelColumn = (t: i18nTranslation): ColumnOptions => {
  return {
    header: t('common.error_level'),
    name: 'errorLevel',
    align: 'left',
    width: 105,
    resizable: true,
  };
};

const getDescriptionColumn = (t: i18nTranslation): ColumnOptions => {
  return {
    header: t('common.description'),
    name: 'description',
    renderer: {
      type: CustomRenderer.AlarmDetailRenderer,
    },
    align: 'left',
    resizable: true,
  };
};

const getColumnsForAdminSiteErrorHistory = (t: i18nTranslation): ColumnOptions[] => [
  ...getCommonSiteErrorHistoryColumns(t),
  getErrorLevelColumn(t),
  getDescriptionColumn(t),
];

const getColumnsForInstallerSiteErrorHistory = (t: i18nTranslation): ColumnOptions[] => [
  ...getCommonSiteErrorHistoryColumns(t),
  getDescriptionColumn(t),
];

const getColumnsForAdminErrorHistory = (t: i18nTranslation): ColumnOptions[] => [
  ...getCommonErrorHistoryColumns(t),
  getErrorLevelColumn(t),
  getDescriptionColumn(t),
];

const getColumnsForInstallerErrorHistory = (t: i18nTranslation): ColumnOptions[] => [
  ...getCommonErrorHistoryColumns(t, true),
  getDescriptionColumn(t),
];

// eslint-disable-next-line import/prefer-default-export
export const getColumns = (
  t: i18nTranslation,
  gridType: ErrorHistoryGridTypeValue = ERROR_HISTORY_GRID_TYPE.ALL,
  roleName: RoleNameValue = ROLE_NAME.INSTALLER
): ColumnOptions[] => {
  if (gridType === ERROR_HISTORY_GRID_TYPE.ALL) {
    if (roleName === ROLE_NAME.INSTALLER) {
      return getColumnsForInstallerErrorHistory(t);
    }

    return getColumnsForAdminErrorHistory(t);
  }
  if (roleName === ROLE_NAME.INSTALLER) {
    return getColumnsForInstallerSiteErrorHistory(t);
  }

  return getColumnsForAdminSiteErrorHistory(t);
};
