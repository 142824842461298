import { defineComponent } from 'vue';
import { BasicButton, PopupWrapper } from '@hems/component';
import { BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'IdleTimePopup',
    components: {
        PopupWrapper,
        BasicButton,
    },
    emits: ['confirm', 'close'],
    setup(_props, { emit }) {
        const close = () => {
            emit('close');
        };
        const confirm = () => {
            emit('confirm');
        };
        return {
            confirm,
            close,
            BASIC_BUTTON_COLOR_TYPE,
        };
    },
});
