import { defineComponent, computed, ref, watch } from 'vue';
import MultiSelect from 'primevue/multiselect';
import BasicButton from '@hems/component/src/buttons/BasicButton.vue';
import Image2 from '@hems/component/src/image/Image2.vue';
import BasicCheckbox from '@hems/component/src/inputs/checkbox/BasicCheckbox.vue';
import { SELECT_OPTION_LIST_EXPANDED_STATUS, BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'MultiCheckSelector',
    components: {
        MultiSelect,
        Image2,
        BasicCheckbox,
        BasicButton,
    },
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        maxSelectedLabels: {
            type: Number,
            default: 9,
        },
        isOpenMultiCheckSelector: {
            type: Boolean,
            default: false,
        },
        selectedOption: {
            type: Object,
            default: null,
        },
        isClearSelectorOptions: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'multiCheckSelectorClose'],
    setup(props, { emit }) {
        const selectedOptions = ref([]);
        const selectOptions = computed(() => props.options.map((item) => ({
            value: item,
            label: item,
        })));
        const isSelectAll = ref(false);
        const multiSelectRef = ref();
        const multiSelectExpandedStatus = ref(SELECT_OPTION_LIST_EXPANDED_STATUS.HIDE);
        const pcOptionCheckBoxClassOption = {
            root: 'multi-select-display-none',
            box: 'multi-select-check-box',
            icon: 'multi-select-display-none',
        };
        const placeholderClassOption = computed(() => selectedOptions.value.length >= 10 ? 'multi-select-placeholder-with-ellipsis' : 'multi-select-placeholder');
        const hasSelectedOption = computed(() => selectedOptions.value.length > 0);
        const showNumberOfSelectedOption = computed(() => selectedOptions.value.length > 3);
        const isEmptySelectOption = computed(() => selectOptions.value.length === 0);
        const changeMultiSelectExpandedStatus = (expandedStatus) => {
            multiSelectExpandedStatus.value = expandedStatus;
            if (multiSelectExpandedStatus.value === SELECT_OPTION_LIST_EXPANDED_STATUS.HIDE) {
                emit('multiCheckSelectorClose');
            }
        };
        const checkAll = () => {
            selectedOptions.value = selectOptions.value;
        };
        const clearAll = () => {
            selectedOptions.value = [];
            isSelectAll.value = false;
        };
        const openMultiCheckSelector = () => {
            multiSelectRef.value?.show();
            changeMultiSelectExpandedStatus(SELECT_OPTION_LIST_EXPANDED_STATUS.OPEN);
        };
        const closeMultiCheckSelector = () => {
            multiSelectRef.value?.hide();
            changeMultiSelectExpandedStatus(SELECT_OPTION_LIST_EXPANDED_STATUS.HIDE);
        };
        const onClickApply = () => {
            emit('update:modelValue', selectedOptions.value.map((item) => item.value));
            closeMultiCheckSelector();
        };
        const onClickRemove = (removeItem) => (selectedOptions.value = selectedOptions.value.filter((option) => option.value !== removeItem));
        const onClickChipXButton = (event, label) => {
            event.stopPropagation();
            onClickRemove(label);
        };
        const onChangeSelectAll = (isSelect) => {
            if (isSelect) {
                checkAll();
            }
            else {
                clearAll();
            }
        };
        const onChangeSelectedOptions = (e) => {
            isSelectAll.value = e.value.length === props.options.length;
        };
        watch(() => props.isOpenMultiCheckSelector, () => {
            if (props.isOpenMultiCheckSelector) {
                openMultiCheckSelector();
            }
            else {
                closeMultiCheckSelector();
            }
        });
        watch(() => props.selectedOption, () => {
            if (props.selectedOption) {
                const isSelectedOption = selectedOptions.value.find((item) => item.value === props.selectedOption?.value);
                if (!isSelectedOption) {
                    selectedOptions.value.push(props.selectedOption);
                }
            }
        });
        watch(() => props.isClearSelectorOptions, () => {
            clearAll();
        });
        return {
            selectedOptions,
            selectOptions,
            pcOptionCheckBoxClassOption,
            placeholderClassOption,
            isSelectAll,
            multiSelectRef,
            multiSelectExpandedStatus,
            SELECT_OPTION_LIST_EXPANDED_STATUS,
            BASIC_BUTTON_COLOR_TYPE,
            hasSelectedOption,
            showNumberOfSelectedOption,
            isEmptySelectOption,
            onClickApply,
            onClickRemove,
            clearAll,
            changeMultiSelectExpandedStatus,
            onClickChipXButton,
            onChangeSelectAll,
            onChangeSelectedOptions,
        };
    },
});
