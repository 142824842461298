
import type { PropType } from 'vue';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { SettingInfoBlock, SettingInfoItem } from '@hems/component';
import BasicButton from '@hems/component/src/buttons/BasicButton.vue';
import { BasicInput } from '@hems/component/src/inputs';
import {
  OPERATION_MODE_CODE,
  POWER_CONTROL_POINT_CODE,
  POWER_CONTROL_RANGE,
  REMOTE_CONTROL_CODE,
} from '@hems/util/src/constant/control';
import { INPUT_TYPE } from '@hems/util/src/constant/input';

import type { RemoteControlHistoryDetail } from 'hems/control';

export default defineComponent({
  name: 'ControlDataPopup',
  components: {
    SettingInfoBlock,
    SettingInfoItem,
    BasicInput,
    BasicButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    data: {
      type: Object as PropType<RemoteControlHistoryDetail>,
      required: true,
    },
    controlType: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  async setup(props, { emit }) {
    const { t } = useI18n();

    const controlHistoryData = ref<RemoteControlHistoryDetail>({ ...props.data });

    const modeText = t(`code.changeoperationmodegen3cd.${props.data.mode}`);
    const powerControlPointText = t(`code.powercontrolpointgen3cd.${props.data.powerControlPoint}`);
    const durationText = t(`code.reversessh_duration_gen3_cd.${props.data.duration}`);

    const close = () => {
      emit('close');
    };

    return {
      controlHistoryData,
      close,
      OPERATION_MODE_CODE,
      POWER_CONTROL_RANGE,
      modeText,
      powerControlPointText,
      durationText,
      POWER_CONTROL_POINT_CODE,
      INPUT_TYPE,
      REMOTE_CONTROL_CODE,
    };
  },
});
