import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { AuthHelper, useAlertModal } from '@hems/util';

import type { CommonRootState } from 'hems/common/store';

export const useLogout = () => {
  const store = useStore<CommonRootState>();

  const clearToken = () => {
    AuthHelper.removeAuthToken();
    window.axiosInstance.clearAccessToken();

    store.dispatch('user/clear');
  };

  const goToPortal = () => {
    // 현재 상태를 히스토리 스택에 저장
    history.pushState(null, '', window.location.href);

    // bfcache 방지를 위해 location.replace 사용
    location.replace('/');
  };

  const logout = () => {
    clearToken();

    goToPortal();
  };

  const { t } = useI18n();
  const authFailureModal = useAlertModal({ content: t('message.permission_not_granted'), callback: logout });

  const handleAuthFailure = () => {
    authFailureModal.open();
  };

  return { logout, clearToken, handleAuthFailure, goToPortal };
};
