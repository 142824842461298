
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import type { BasicButtonColorTypeValue, BasicButtonSizeValue } from '@hems/util/src/constant';
import { BASIC_BUTTON_COLOR_TYPE, BASIC_BUTTON_SIZE } from '@hems/util/src/constant';

import type { ImageInfo } from 'hems';

// 경로 문제로 상대 경로 사용
import Image2 from '../image/Image2.vue';

export default defineComponent({
  name: 'BasicButton',
  components: {
    Image2,
  },
  inheritAttrs: false,
  props: {
    isSubmit: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<BasicButtonSizeValue>,
      default: BASIC_BUTTON_SIZE.REGULAR,
    },
    colorType: {
      type: String as PropType<BasicButtonColorTypeValue>,
      default: BASIC_BUTTON_COLOR_TYPE.PRIMARY,
    },
    beforeImage: {
      type: Object as PropType<ImageInfo | null>,
      default: null,
    },
    afterImage: {
      type: Object as PropType<ImageInfo | null>,
      default: null,
    },
    isCritical: {
      type: Boolean,
      default: false,
    },
  },
});
