export const SELECTION_CONTROL_TAB_SIZE = {
    LARGE: 'large',
    REGULAR: 'regular',
    SMALL: 'small',
    XXSMALL: 'xxsmall',
};
export const SELECTION_CONTROL_TAB_SHAPE = {
    SOLID: 'solid',
    LINE: 'line',
    DISABLED: 'disabled',
};
export const TAB_SIZE = {
    REGULAR: 'regular',
    SMALL: 'small',
};
