
import type { PropType } from 'vue';
import { defineComponent, reactive } from 'vue';

import { Panel, LabelInput } from '@hems/component';

import type { MakeFieldNonNullable } from 'hems/common/utils';
import type { SiteListSearchBoxOptions } from 'hems/site';
export default defineComponent({
  name: 'SiteSearchBox',
  components: {
    Panel,
    LabelInput,
  },
  props: {
    condition: {
      type: Object as PropType<SiteListSearchBoxOptions>,
      default: () => ({
        searchFor: null,
      }),
    },
  },
  emits: ['search'],
  setup(props, { emit }) {
    const state = reactive<{ condition: MakeFieldNonNullable<SiteListSearchBoxOptions, 'searchFor'> }>({
      condition: { ...props.condition, searchFor: props.condition.searchFor ?? '' },
    });

    const onSearch = () => {
      state.condition.searchFor = state.condition.searchFor?.trim();
      emit('search', { ...state.condition });
    };

    return {
      state,
      onSearch,
    };
  },
});
