
import { defineComponent, reactive, defineAsyncComponent, ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { useIdle } from '@vueuse/core';
import axios from 'axios';
import isEmpty from 'lodash-es/isEmpty';

import { NOTICE_TARGET_WEB_VALUES } from '@hems/admin/src/config/noticeConfig';
import { CommonService } from '@hems/service';
import { useLanguage, useLocale, Helper } from '@hems/util';
import { useMSAL } from '@hems/util/src/composable/msal';
import type { LanguageValue } from '@hems/util/src/constant';
import { LANGUAGE, localStorageKey, ONE_HOUR_VALUE_IN_MILLISECONDS } from '@hems/util/src/constant';
import type { TenantType } from '@hems/util/src/constant/msal';
import { TENANT } from '@hems/util/src/constant/msal';
import { getCurrentTimestamp } from '@hems/util/src/helper/dateHelper';

import LoginButton from '@/components/button/LoginButton.vue';
import NewFooter from '@/components/footer/NewFooter.vue';
import NewHeader from '@/components/header/NewHeader.vue';
import { useAuthentication } from '@/composables/authentication';
import { useDashboard } from '@/composables/dashboard';
import type { AppStoreType } from '@/config/ConstantPortal';
import { APP_STORE_TYPE } from '@/config/ConstantPortal';
import bgImage from '@/resources/images/main/qcells-energy-solutions-bg.jpg';

import type { ValueType } from 'hems/common/utils';
import type { NoticeInfo } from 'hems/notice';

const API_BASE_URL = process.env.VUE_APP_API_URL;

export default defineComponent({
  name: 'NewHome',
  components: {
    NewFooter,
    NewHeader,
    LoginButton,
    AccountInfoPopup: defineAsyncComponent(() => import('@/components/popup/accountinfo/AccountInfoPopup.vue')),
    PrivacyPolicyPopup: defineAsyncComponent(() => import('@hems/container/src/terms/PrivacyPolicyPopup.vue')),
    MobileAppLinkPopup: defineAsyncComponent(() => import('@/components/popup/MobileAppLinkPopup.vue')),
    CyberSecurityPopup: defineAsyncComponent(() => import('@/components/popup/cyberSecurity/CyberSecurityPopup.vue')),
    NoticePopup: defineAsyncComponent(() => import('@hems/component/src/popup/NoticePopup.vue')),
    IdleTimePopup: defineAsyncComponent(() => import('@hems/component/src/popup/IdleTimePopup.vue')),
  },
  setup() {
    const { locale: i18nLocale } = useI18n();

    const { languageCode, setLanguage } = useLanguage();
    const { locale } = useLocale();

    const { idle } = useIdle(ONE_HOUR_VALUE_IN_MILLISECONDS);

    const { userName, isLogin } = useAuthentication();

    const { dashboardUrl } = useDashboard();
    const appStoreType = ref<AppStoreType>(APP_STORE_TYPE.APPLE);

    const noticeInfo = ref<NoticeInfo | null>(null);

    const isImageLoaded = ref(false);

    const axiosInstance = axios.create({
      baseURL: API_BASE_URL,
    });
    const commonService = new CommonService(axiosInstance);

    type PopupOpenState = {
      isOpenAccountInfo: boolean;
      isOpenWithdrawal: boolean;
      isOpenCyberSecurity: boolean;
      isOpenPrivacy: boolean;
      isOpenAppDownload: boolean;
      isOpenNotice: boolean;
      isOpenIdleTime: boolean;
    };

    const popupState = reactive<PopupOpenState>({
      isOpenAccountInfo: false,
      isOpenWithdrawal: false,
      isOpenCyberSecurity: false,
      isOpenPrivacy: false,
      isOpenAppDownload: false,
      isOpenNotice: false,
      isOpenIdleTime: false,
    });

    const changeLanguage = (selectedLanguage: LanguageValue) => {
      i18nLocale.value = selectedLanguage;
      setLanguage(selectedLanguage);
    };

    const LANDING_PAGE_POPUP = {
      ACCOUNT_INFO: 'ACCOUNT_INFO',
      APP_DOWNLOAD: 'APP_DOWNLOAD',
      CYBER_SECURITY: 'CYBER_SECURITY',
      PRIVACY: 'PRIVACY',
      NOTICE: 'NOTICE',
      IDLE_TIME: 'IDLE_TIME',
    } as const;

    type LandingPagePopupValueType = ValueType<typeof LANDING_PAGE_POPUP>;

    const popupTypePopupOpenMethodNameMap: Record<LandingPagePopupValueType, keyof PopupOpenState> = {
      [LANDING_PAGE_POPUP.ACCOUNT_INFO]: 'isOpenAccountInfo',
      [LANDING_PAGE_POPUP.APP_DOWNLOAD]: 'isOpenAppDownload',
      [LANDING_PAGE_POPUP.PRIVACY]: 'isOpenPrivacy',
      [LANDING_PAGE_POPUP.CYBER_SECURITY]: 'isOpenCyberSecurity',
      [LANDING_PAGE_POPUP.NOTICE]: 'isOpenNotice',
      [LANDING_PAGE_POPUP.IDLE_TIME]: 'isOpenIdleTime',
    } as const;

    const openPopup = (popupType: LandingPagePopupValueType) => {
      popupState[popupTypePopupOpenMethodNameMap[popupType]] = true;
    };

    const closePopup = (popupType: LandingPagePopupValueType) => {
      popupState[popupTypePopupOpenMethodNameMap[popupType]] = false;
    };

    const openAppDownloadPopup = (type: AppStoreType) => {
      openPopup(LANDING_PAGE_POPUP.APP_DOWNLOAD);
      appStoreType.value = type;
    };

    const getNotice = async () => {
      // 1. API 호출로 공지 여부(utc 시간으로 비교) 및 내용 가져오기
      // 2. localStorage에서 공지 Expired Time 비교하여 notice 표시 여부 확인
      try {
        /**
         *  공지 API 호출
         * GET /user/notice
         * @params lang ('en', 'de', 'fr', 'ko')
         * @response { title: string, contents: string, notice_target: string, notice_start_dt: string, notice_end_dt: string }
         */
        // 우선 공지 언어는 영어만 가능하도록 처리 추후 변경 필요 시 재수정

        const notice = await commonService.getNotice(LANGUAGE.EN);
        // 공지가 없는 경우 localStorage에 저장한 공지 Expired Time 삭제 / 공지 띄우지 않음
        if (isEmpty(notice) && !Helper.isNull(localStorage.getItem(localStorageKey.notice))) {
          localStorage.setItem(localStorageKey.notice, '');
        }

        const isNoticeTarget = NOTICE_TARGET_WEB_VALUES.includes(notice?.notice_target); // 공지 target 여부
        if (isNoticeTarget) {
          noticeInfo.value = notice;
          const expiredTime = localStorage.getItem(localStorageKey.notice); // 하루동안 다시 보지 않기의 만료 시간
          // 현재 시간이 Expired Time 이후 (사용자가 Close 버튼을 클릭하고 24시간 후) 라면 공지를 띄움
          const showNotice = !Helper.isNull(expiredTime) ? getCurrentTimestamp() > Number(expiredTime) : true;
          if (showNotice) {
            openPopup(LANDING_PAGE_POPUP.NOTICE);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    const { logoutRedirect, loginRedirect, getAuthRedirectPromiseResponse, acquireToken, processLogin } = useMSAL();

    const onLogout = async () => {
      if (popupState.isOpenIdleTime) {
        closePopup(LANDING_PAGE_POPUP.IDLE_TIME);
      }

      logoutRedirect();
    };

    const login = async (tenant: TenantType) => {
      loginRedirect(tenant);
    };

    const onClickLoginButton = (tenant: TenantType) => {
      if (isLogin.value) {
        window.location.href = dashboardUrl.value;
      } else {
        login(tenant);
      }
    };

    const handleAuthRedirectResponse = async () => {
      try {
        const { responseHomeowner, responsePartners } = await getAuthRedirectPromiseResponse();

        /** redirect 되는 시점에 homeowner, partners의 응답 값이 둘 중 하나라도 있으면 신규 로그인 */
        const authenticationResult = responseHomeowner || responsePartners;

        if (authenticationResult) {
          processLogin({ responseHomeowner, responsePartners });
        } else {
          /** 만약 토큰이 있으면 토큰만 갱신 */
          acquireToken();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const onLoadImage = () => {
      isImageLoaded.value = true;
    };

    watch(idle, (idleValue) => {
      if (idleValue) {
        openPopup(LANDING_PAGE_POPUP.IDLE_TIME);
      }
    });

    getNotice();

    onMounted(() => {
      handleAuthRedirectResponse();
    });

    return {
      userName,
      popupState,
      locale,
      languageCode,
      appStoreType,
      noticeInfo,
      LANDING_PAGE_POPUP,
      isLogin,
      isImageLoaded,
      changeLanguage,
      openPopup,
      closePopup,
      openAppDownloadPopup,
      onLogout,
      onClickLoginButton,
      onLoadImage,
      bgImage,
      TENANT,
    };
  },
});
