import orderBy from 'lodash-es/orderBy';
import moment from 'moment';

import { GENERAL_DATE_FORMAT } from '@hems/util/src/constant';
import { getSortNameAndOrder } from '@hems/util/src/helper/helper';

import type { CommonResponseWrapper, CommonResponse, ListResponse, ListContextResponse } from 'hems';

export const getTimeStamp = () => moment().format(GENERAL_DATE_FORMAT);

export const getCommonResponseData = <T>(
  data: T,
  path: string,
  options: Partial<Omit<CommonResponse, 'data'>> = {}
): CommonResponseWrapper<T> => {
  return {
    code: 200,
    status: 'OK',
    message: 'Success.',
    timestamp: getTimeStamp(),
    path,
    data,
    ...options,
  };
};

export const getListResponseData = <T>(body: T, context: ListContextResponse): ListResponse<T> => {
  return {
    context,
    body,
  };
};

export const getPagingData = <T>(listData: T[], pageNumber: number, listSize: number): T[] => {
  const startIndex = listSize * (pageNumber - 1);
  const endIndex = startIndex + listSize;

  return listData.slice(startIndex, endIndex);
};

export const getSortingData = <T>(listData: T[], sort: string): T[] => {
  const { sortColumnName, isAscending } = getSortNameAndOrder(sort);

  return orderBy(listData, [sortColumnName], [isAscending ? 'asc' : 'desc']);
};
