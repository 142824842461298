import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-251e1b5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "basic-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_PopupWrapper = _resolveComponent("PopupWrapper")!

  return (_openBlock(), _createBlock(_component_PopupWrapper, { class: "ty_6 idle-time-popup" }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.timeout', { min: 60 })), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BasicButton, {
          "color-type": _ctx.BASIC_BUTTON_COLOR_TYPE.SECONDARY,
          onClick: _ctx.close
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('account.logout')), 1)
          ]),
          _: 1
        }, 8, ["color-type", "onClick"]),
        _createVNode(_component_BasicButton, { onClick: _ctx.confirm }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.continue')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 3
  }))
}