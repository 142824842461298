import { defineComponent, reactive, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import isNil from 'lodash-es/isNil';
import { useModelWrapper, useLocale, useAlertModal } from '@hems/util';
import { isPeriod } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'VCalendarWrapper',
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [Date, Object],
            required: true,
        },
        isRange: Boolean,
        mode: {
            type: String,
            default: 'date',
        },
        minDate: Date,
        maxDate: Date,
        validate: {
            type: Function,
            default: () => false,
        },
        applyMode: {
            type: String,
            default: 'button',
        },
        isShowPermanentButton: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'close', 'permanent', 'done'],
    setup(props, { emit }) {
        const state = reactive({
            open: useModelWrapper(props, emit, 'open'),
            value: props.modelValue,
        });
        const { intlFormatLocale } = useLocale();
        const { t } = useI18n();
        const attributes = [
            {
                highlight: {
                    start: {
                        fillMode: 'solid',
                    },
                    base: {
                        fillMode: 'solid',
                    },
                    end: {
                        fillMode: 'solid',
                    },
                },
            },
        ];
        const modelConfig = {
            start: {
                timeAdjust: '00:00:00',
            },
            end: {
                timeAdjust: '23:00:00',
            },
        };
        const isValidateRange = computed(() => {
            if (state.value && isPeriod(state.value)) {
                return props.validate && props.validate(state.value);
            }
            return false;
        });
        const close = () => {
            emit('close');
        };
        const isValidateDate = computed(() => isNil(state.value));
        const alertModal = useAlertModal({ content: t('message.period_not_exceed') });
        const apply = () => {
            if (isValidateDate.value) {
                emit('done');
                close();
                return;
            }
            if (!props.isRange) {
                emit('update:modelValue', state.value);
                emit('done');
                close();
            }
            if (props.isRange && !isValidateRange.value) {
                alertModal.open();
            }
            else {
                emit('update:modelValue', state.value);
                emit('done');
                close();
            }
        };
        const applyPermanent = () => {
            state.value = null;
            apply();
            emit('permanent', true);
        };
        watch(() => props.open, () => {
            if (props.open)
                state.value = props.modelValue;
        });
        watch(() => state.value, () => {
            if (props.applyMode === 'picker') {
                apply();
            }
        });
        return {
            state,
            attributes,
            modelConfig,
            apply,
            close,
            applyPermanent,
            intlFormatLocale,
        };
    },
});
