
import type { PropType } from 'vue';
import { defineComponent, watch } from 'vue';

import { useField } from 'vee-validate';

import Image2 from '@hems/component/src/image/Image2.vue';
import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
import { Helper } from '@hems/util';

import type { ImageSelectorOption, SelectorValue } from 'hems';

import BasicRadio from './BasicRadio.vue';

export default defineComponent({
  name: 'RadioImageGroup',
  components: {
    BasicRadio,
    ErrorMessageLabel,
    Image2,
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number, Boolean] as PropType<SelectorValue>,
      required: true,
    },
    maxColumnNum: {
      type: Number,
      default: 4,
    },
    orientationOptions: {
      type: Array as PropType<ImageSelectorOption[]>,
      required: true,
    },
    direction: {
      type: String as PropType<'row' | 'col'>,
      default: 'row',
    },
    align: {
      type: String as PropType<'left' | 'right' | 'center'>,
      default: 'left',
    },
    valueType: {
      type: String as PropType<'string' | 'number'>,
      default: 'string',
    },
    notModify: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const inputName = props.name || Helper.getUUID();
    const { value: inputValue, errorMessage } = useField(inputName, undefined, {
      initialValue: props.modelValue,
      type: props.valueType,
    });

    watch(
      () => inputValue.value,
      () => {
        emit('update:modelValue', inputValue.value);
      }
    );

    watch(
      () => props.modelValue,
      () => {
        if (inputValue.value !== props.modelValue) inputValue.value = props.modelValue || '';
      }
    );

    return {
      errorMessage,
      inputValue,
      inputName,
    };
  },
});
