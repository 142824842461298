import { defineComponent } from 'vue';
export default defineComponent({
    name: 'MultiSettingTab',
    props: {
        tabList: {
            type: Array,
            required: true,
        },
        selectedTabValue: {
            type: [String, null],
            default: null,
        },
    },
    emits: ['change'],
    setup(_, { emit }) {
        const switchTab = (selectedTabValue) => {
            emit('change', selectedTabValue);
        };
        return {
            switchTab,
        };
    },
});
