import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "setting-info" }
const _hoisted_2 = { class: "title-text" }
const _hoisted_3 = {
  key: 0,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["setting-info-item text-item", { [`indent-${_ctx.indentDepth}`]: _ctx.isIndent, required: _ctx.required }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.title), 1),
          _renderSlot(_ctx.$slots, "tooltip"),
          (_ctx.description)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "(" + _toDisplayString(_ctx.description) + ")", 1))
            : _createCommentVNode("", true)
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["setting-info-item", { 'multiple-value': _ctx.isMultiValues }])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _renderSlot(_ctx.$slots, "banner")
  ]))
}