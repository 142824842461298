import { defineComponent } from 'vue';
import Image2 from '@hems/component/src/image/Image2.vue';
export default defineComponent({
    name: 'PermissionStatusCard',
    components: {
        Image2,
    },
    props: {
        permissionStatusCardInfo: {
            type: Array,
            required: true,
        },
    },
});
