/** Copyright © 2025 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { ValueType } from 'hems/common/utils';

export const AUTH_STATUS = {
  SUCCESS: 'SUCCESS',
  INVALID_ACCESS_TOKEN: 'INVALID_ACCESS_TOKEN',
  TOKEN_PARSING_ERROR: 'TOKEN_PARSING_ERROR',
  PERMISSION_ERROR: 'PERMISSION_ERROR',
} as const;

export type AuthStatusValue = ValueType<typeof AUTH_STATUS>;
