/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class FirmwareUpdateService extends Service {
    constructor(axios) {
        super(axios);
    }
    /** 펌웨어 업데이트 Group List */
    getFirmwareUpdateGroupList = (params) => {
        return this.get(apiWrapper.managerApiV1('/firmwares/update-groups'), params);
    };
    /** 펌웨어 업데이트 Device Info List */
    getFirmwareUpdateDeviceInfoList = (updateGroupId, params) => {
        return this.get(apiWrapper.managerApiV1(`/firmwares/update-groups/${updateGroupId}/devices`), params);
    };
    /** 펌웨어 업데이트 Group Name 중복 체크 */
    isValidGroupNameDuplicate = (params) => {
        return this.post(apiWrapper.managerApiV1('/firmwares/update-groups/id/validate'), params);
    };
    /** 펌웨어 업데이트 Create > Step2 Device List */
    getFirmwareUpdateCreateStep2DeviceList = (params) => {
        return this.get(apiWrapper.managerApiV1('/firmwares/devices'), params);
    };
    /** 펌웨어 정보 생성 */
    createFirmwareInfo = (firmwareInfo) => {
        return this.post(apiWrapper.managerApiV1('/firmwares'), firmwareInfo);
    };
    /** 펌웨어 업데이트 File Presigned URL 정보 */
    getFirmwareUpdateFilePresignedURL = (firmwareId, presignedInfo) => {
        return this.get(apiWrapper.managerApiV1(`/firmwares/${firmwareId}/storage`), presignedInfo);
    };
    /** 펌웨어 파일 S3에 등록 */
    uploadFirmwareUpdateFile = (fileURL, file) => {
        return fetch(fileURL, {
            method: 'PUT',
            body: file,
        });
    };
    /** 펌웨어 파일 S3에서 다운로드 */
    downloadFirmwareUpdateFile = (fileURL) => {
        return fetch(fileURL, {
            method: 'GET',
        });
    };
    /** 펌웨어 업데이트 Set 생성 */
    createFirmwareUpdateSet = (firmwareSetInfo) => {
        return this.post(apiWrapper.managerApiV1('/firmware-sets'), firmwareSetInfo);
    };
    /** 펌웨어 업데이트 그룹 정보 생성 */
    createFirmwareUpdateGroupInfo = (firmwareGroupInfo) => {
        return this.post(apiWrapper.managerApiV1('/firmwares/update-groups'), firmwareGroupInfo);
    };
    /** 펌웨어 업데이트 Manual 일때, 펌웨어 등록 및 siteId 등록 */
    pushManualFirmwareUpdate = (updateGroupId, firmwareInfo) => {
        return this.post(apiWrapper.managerApiV1(`/firmwares/update-groups/${updateGroupId}/update`), firmwareInfo);
    };
    /** 펌웨어 업데이트 Auto Update 일때, 펌웨어 등록 및 Exclude siteId 등록 */
    pushAutoFirmwareUpdate = (updateGroupId, excludeSiteIdInfo) => {
        return this.post(apiWrapper.managerApiV1(`/firmwares/update-groups/${updateGroupId}/excluded-sites`), excludeSiteIdInfo);
    };
    /** 펌웨어 release File List */
    getFirmwareReleaseList = async () => {
        return await this.get(apiWrapper.managerApiV1('/firmwares/releases'));
    };
    /** 펌웨 업데이트 Auto Update 정보 삭제 (Auto Update의 경우에만 삭제 할 수 있고 Manual은 삭제 불가능) */
    deleteFirmwareAutoUpdateInfo = (updateGroupId) => {
        return this.delete(apiWrapper.managerApiV1(`/firmwares/update-groups/${updateGroupId}`));
    };
}
