import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d3f64dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-expand-container" }
const _hoisted_2 = {
  ref: "div",
  class: "search-options-layer"
}
const _hoisted_3 = { class: "search_expend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        type: "button",
        class: "btn_expend",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSearchBox && _ctx.toggleSearchBox(...args)))
      }, [
        _withDirectives(_createVNode(_component_Image2, {
          src: "fleet/ic_dropdown_arrow_up",
          extension: "svg"
        }, null, 512), [
          [_vShow, _ctx.isShow]
        ]),
        _withDirectives(_createVNode(_component_Image2, {
          src: "fleet/ic_dropdown_arrow_down",
          extension: "svg"
        }, null, 512), [
          [_vShow, !_ctx.isShow]
        ])
      ])
    ])
  ]))
}