/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import floor from 'lodash-es/floor';

import { AC_DEVICE_TYPE, ENERGY_POLICY, NUMBER_UNIT, SITE_TYPE } from '@hems/util/src/constant';
import type { ACDeviceTypeValue, EnergyPolicy, NumberUnitValue, SiteTypeValue } from '@hems/util/src/constant';
import type { CapacityUnitTypeValue, WattPeakUnitValue, WattHourUnitValue } from '@hems/util/src/constant/unit';
import {
  CAPACITY_UINT_TYPE,
  NUMBER_UNIT_WATT_HOUR_UNIT_MAP,
  NUMBER_UNIT_WATT_PEAK_UNIT_MAP,
} from '@hems/util/src/constant/unit';
import { floorTwoDecimal, formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
import { isEnergyPolicyType } from '@hems/util/src/helper/tsguardHelper';

import type { i18nTranslation } from 'hems';

import type { DeviceProfileACSystem } from 'hems/device/dashboard/smartmodule/acsys';
import type { DeviceProfileAllGenType } from 'hems/device/siteinfo';

export const getMultipleDashboardData = (label: string[], data: (number | string | null)[]): string[] => {
  return data.map((value, index) => {
    return `${label[index]}: ${
      value && typeof value === 'number' ? formatUnitNumber(value).formattedNumber : value ?? ''
    }`;
  });
};

export const convertDeviceProfileData = (deviceInfo: DeviceProfileAllGenType): DeviceProfileAllGenType => {
  const convertedData = { ...deviceInfo };
  for (const key in deviceInfo) {
    if (typeof convertedData[key] === 'number') {
      convertedData[key] = floor(Number(convertedData[key]), 2);
    }
  }

  return convertedData;
};

/** PCS, Battery 버전 변환 함수 (00.00) */
export const formatToTwoDecimalVersion = (value: string): string => (Number(value) / NUMBER_UNIT.HUNDRED).toFixed(2);

/** BMS 버전 변환 함수 (00.000) */
export const formatToThreeDecimalVersion = (value: string): string => (Number(value) / NUMBER_UNIT.THOUSAND).toFixed(3);

export const calculatePowerFactor = (activePower: number, reactivePower: number): number | null => {
  if (!activePower && !reactivePower) {
    return null;
  }
  const apparentPower = Math.sqrt(activePower ** 2 + reactivePower ** 2);

  return floorTwoDecimal(activePower / apparentPower);
};

const getCapacityUnit = (
  unitType: CapacityUnitTypeValue,
  numberUnitValue: NumberUnitValue
): WattHourUnitValue | WattPeakUnitValue =>
  unitType === CAPACITY_UINT_TYPE.HOUR
    ? NUMBER_UNIT_WATT_HOUR_UNIT_MAP[numberUnitValue]
    : NUMBER_UNIT_WATT_PEAK_UNIT_MAP[numberUnitValue];

export const addUnitToCapacity = (capacity: number, unitType: CapacityUnitTypeValue): string => {
  if (capacity >= NUMBER_UNIT.MILLION) {
    return `${(capacity / NUMBER_UNIT.MILLION).toFixed(1)} ${getCapacityUnit(unitType, NUMBER_UNIT.MILLION)}`;
  }
  if (capacity >= NUMBER_UNIT.THOUSAND) {
    return `${(capacity / NUMBER_UNIT.THOUSAND).toFixed(1)} ${getCapacityUnit(unitType, NUMBER_UNIT.THOUSAND)}`;
  }

  return `${capacity.toFixed(1)} ${getCapacityUnit(unitType, NUMBER_UNIT.HUNDRED)}`;
};

export const getOperationModeTextKeyMap = (hasESS: boolean): { [k in EnergyPolicy]: string } => {
  return {
    [ENERGY_POLICY.STAND_BY]: 'device.standby',
    [ENERGY_POLICY.SELF_CONSUMPTION]: hasESS ? 'control.self_consumption' : 'device.auto',
    [ENERGY_POLICY.ZERO_EXPORT]: 'control.limit_export',
    [ENERGY_POLICY.TIME_BASED]: 'control.time_of_use',
    [ENERGY_POLICY.EXTERNAL_GENERATION]: 'code.energycontrolgen3cd.4',
    [ENERGY_POLICY.EOS]: 'code.energycontrolgen3cd.5',
    [ENERGY_POLICY.GATEWAY]: 'code.energycontrolgen3cd.6',
    [ENERGY_POLICY.ENGINEER]: 'code.energycontrolgen3cd.7',
    [ENERGY_POLICY.SMART_SAVING]: 'control.smart_saving',
    [ENERGY_POLICY.STORM_COVER]: 'control.storm_cover',
    [ENERGY_POLICY.VPP]: 'device.vpp',
  };
};

export const getOperationModeTextKey = (hasESS: boolean, operationMode: EnergyPolicy): string =>
  getOperationModeTextKeyMap(hasESS)[operationMode];

/** Case1에 대해서만 energy_control이 self consumption일때 op mode는 Auto로 표시 */
export const getEnergyControlValueForAC = (
  ACDeviceType: ACDeviceTypeValue,
  t: i18nTranslation,
  deviceInfo?: DeviceProfileACSystem
) =>
  deviceInfo && isEnergyPolicyType(deviceInfo.energy_control)
    ? t(getOperationModeTextKey(ACDeviceType !== AC_DEVICE_TYPE.AC_COMBINER, deviceInfo.energy_control))
    : null;

export const siteTypeTextKeyMap: { [k in SiteTypeValue]: string } = {
  [SITE_TYPE.RESIDENTIAL]: 'control.residential',
  [SITE_TYPE.COMMERCIAL]: 'control.commercial',
  [SITE_TYPE.TEST]: 'common.test_site',
  [SITE_TYPE.OTHER_PURPOSE]: 'control.other_purpose',
};
