import { defineComponent, onBeforeUnmount, reactive, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { Panel, BasicCheckbox, BasicButton } from '@hems/component';
import { useDownloadExcel } from '@hems/component/src/home/downloadExcel';
import DevicePieChartContainer from '@hems/container/src/highcharts/piechart/DevicePieChartContainer.vue';
import { StatisticsService } from '@hems/service';
import { useLoading } from '@hems/util/src/composable/loading';
import { ONE_MINUTE_VALUE_IN_MILLISECONDS, BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
import { formatNumber, downloadButtonIconInfo } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'SiteOverview',
    components: {
        Panel,
        DevicePieChartContainer,
        BasicCheckbox,
        BasicButton,
    },
    setup() {
        const { downloadExcel } = useDownloadExcel();
        const router = useRouter();
        const { startLoading, stopLoading } = useLoading();
        const statisticsService = new StatisticsService(window.axiosInstance.axios);
        const checkState = reactive({
            complete: true,
            inComplete: true,
        });
        const siteOverviewCountInfo = ref(null);
        const chartData = ref(null);
        const lastWeekInstalledSiteCount = ref(null);
        const getDashboardChartData = async (useLoading = false) => {
            try {
                useLoading && startLoading();
                const data = await statisticsService.getDeviceCount();
                chartData.value = data;
                const { totSiteCnt = 0, onRunCnt = 0, onWarnCnt = 0, onErrCnt = 0, disconRunCnt = 0, disconWarnCnt = 0, disconErrCnt = 0, offlineCnt = 0, stdCnt = 0, sleepCnt = 0, incompleteTestCount = 0, incompleteCustomerCount = 0, completeTestCount = 0, completeCustomerCount = 0, } = chartData.value;
                const onlineCount = onRunCnt + onWarnCnt + onErrCnt;
                const onlineDisconnectedCount = disconRunCnt + disconWarnCnt + disconErrCnt;
                siteOverviewCountInfo.value = {
                    totalSiteCount: formatNumber(totSiteCnt || 0),
                    totalCustomerCount: formatNumber(completeCustomerCount + incompleteCustomerCount),
                    totalTestCount: formatNumber(completeTestCount + incompleteTestCount),
                    onlineCount: formatNumber(onlineCount),
                    onlineDisconnectedCount: formatNumber(onlineDisconnectedCount),
                    offlineCount: formatNumber(offlineCnt),
                    inCompleteCount: formatNumber(stdCnt + sleepCnt),
                    installCompletedSiteCount: formatNumber(completeTestCount + completeCustomerCount),
                    installInCompletedSiteCount: formatNumber(incompleteTestCount + incompleteCustomerCount),
                    incompleteTestCount: formatNumber(incompleteTestCount),
                    incompleteCustomerCount: formatNumber(incompleteCustomerCount),
                    completeTestCount: formatNumber(completeTestCount),
                    completeCustomerCount: formatNumber(completeCustomerCount),
                };
            }
            catch (e) {
                console.error(e);
            }
            finally {
                useLoading && stopLoading();
            }
        };
        const { t } = useI18n();
        const getCustomerTestCountText = (customerCount, testCount) => {
            return `(${t('common.customer')} ${customerCount || '0'} | ${t('common.test')} ${testCount || '0'})`;
        };
        const getLastWeekInstalledSite = async (useLoading = false) => {
            try {
                useLoading && startLoading();
                const data = await statisticsService.getLastWeekInstalledDeviceCount();
                lastWeekInstalledSiteCount.value = {
                    totalCount: formatNumber(data.total_site_count || 0),
                    customerCount: formatNumber(data.customer_site_count || 0),
                    testCount: formatNumber(data.test_site_count || 0),
                };
            }
            catch (e) {
                console.error(e);
            }
            finally {
                useLoading && stopLoading();
            }
        };
        const loadData = (useLoading = false) => {
            getDashboardChartData(useLoading);
            getLastWeekInstalledSite(useLoading);
        };
        const goMonitoringListPage = (query) => {
            router.push({
                name: 'MonitoringList',
                query,
            });
        };
        const interval = setInterval(() => {
            loadData();
        }, ONE_MINUTE_VALUE_IN_MILLISECONDS);
        onMounted(() => {
            window.scrollTo({ top: 0 });
        });
        onBeforeUnmount(() => {
            clearInterval(interval);
        });
        loadData(true);
        return {
            checkState,
            siteOverviewCountInfo,
            lastWeekInstalledSiteCount,
            chartData,
            BASIC_BUTTON_COLOR_TYPE,
            downloadButtonIconInfo,
            goMonitoringListPage,
            downloadExcel,
            getCustomerTestCountText,
        };
    },
});
