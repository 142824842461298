import { computed, defineComponent, reactive } from 'vue';
import moment from 'moment';
import VCalendarWrapper from '@hems/component/src/calendars/VCalendarWrapper.vue';
import { BasicInput } from '@hems/component/src/inputs';
import { useLocale, useModelWrapper } from '@hems/util';
import { INPUT_TYPE } from '@hems/util/src/constant/input';
export default defineComponent({
    name: 'VCalendar',
    components: {
        VCalendarWrapper,
        BasicInput,
    },
    inheritAttrs: false,
    props: {
        modelValue: {
            type: Date,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: 'DD MMM, YYYY',
        },
        minDate: Date,
        maxDate: Date,
        validate: {
            type: Function,
            default: () => false,
        },
        mode: {
            type: String,
            default: 'date',
        },
    },
    emits: ['done'],
    setup(props, { emit }) {
        const state = reactive({
            open: false,
            value: useModelWrapper(props, emit),
            maxDate: new Date(),
        });
        const dateMode = computed(() => (props.mode ? props.mode : 'date'));
        const { intlFormatLocale } = useLocale();
        const displayValue = computed(() => props.format
            ? moment(state.value).locale(intlFormatLocale.value).format(props.format)
            : moment(state.value).locale(intlFormatLocale.value).format('LL'));
        const onOpen = () => (state.open = true);
        const onClose = () => (state.open = false);
        const onDone = () => {
            emit('done');
        };
        return {
            state,
            dateMode,
            displayValue,
            onOpen,
            onClose,
            onDone,
            INPUT_TYPE,
        };
    },
});
