/**
Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { AxiosInstance } from 'axios';
import codes from 'country-calling-code';
import countries from 'i18n-iso-countries';
import camelCase from 'lodash-es/camelCase';
import find from 'lodash-es/find';
import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';
import range from 'lodash-es/range';
import moment from 'moment-timezone';
import type { ColumnOptions } from 'tui-grid';

import type {
  AppTypeValue,
  SortOrderValue,
  LanguageValue,
  SmartThingsTemperatureUnit,
  ImageFileExtensionValue,
} from '@hems/util/src/constant';
import {
  AC_MODEL_TYPE,
  NUMBER_UNIT,
  alpha2CountryList,
  auAlpha2CountryList,
  euAlpha2CountryList,
  localStorageKey,
  usAlpha2CountryList,
  SORT_ORDER,
  INTL_LOCALE_MAP,
  DEFAULT_LANGUAGE,
  UNIT,
  LANGUAGE,
} from '@hems/util/src/constant';
import { APP_MODE, DEVICE_CONNECTION_STATUS, IMAGE_FILE_EXTENSION } from '@hems/util/src/constant/constant';
import { getEndDate, getSixDaysAgo, now } from '@hems/util/src/helper/dateHelper';
import { formatUnitNumber } from '@hems/util/src/helper/numberformatHelper';
import { isLanguageValueType, isLocaleValueType } from '@hems/util/src/helper/tsguardHelper';

import type {
  TransformKeysToCamelCase,
  CodeName,
  SelectorOption,
  LabelSelectorOption,
  CommonCode,
  AppEnv,
  EnvLocale,
  SelectorValue,
  ComparableType,
  FormattedUnitNumberData,
} from 'hems';

import type { ActivitySearchBoxOptions } from 'hems/activityHistory';
import type { ACModelType, GenType, ProductModelName } from 'hems/device';
import type { NoticeListItem } from 'hems/notice';

/* eslint-disable */
export const getUUID = function (): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 3) | 8;
    return v.toString(16);
  });
};

export function isNull(str: unknown): str is null | undefined | '' {
  return (typeof str === 'string' && str.trim() === '') || str === undefined || str === null;
}

export function padLeft(number: number, length: number, padString = '0') {
  const sign = number < 0 ? '-' : '';
  const padStrLength =
    length - Math.abs(number).toString().length < 0 ? 0 : length - Math.abs(number).toString().length;
  const padStr = Array(padStrLength + 1).join(padString);
  return sign + padStr + number;
}

export function setAxiosInterceptor(axiosInstance: AxiosInstance, accessToken: string) {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers.Authorization = 'Bearer ' + accessToken;
      return config;
    },
    (error) => Promise.reject(error)
  );
}

export function formatNumber(_num: number | string | null, decimal: number = 0): string | null {
  if (_num === undefined || _num === null) return null;
  const num = typeof _num === 'string' ? Number(_num) : _num;
  if (isNaN(num)) return null;

  const number = num.toFixed(decimal);
  return number.replace(/^[+-]?\d+/, function (int) {
    return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  });
}

export function getPartial(o: Record<string, any>, props: string[]) {
  const retVal = {} as Record<string, any>;
  for (let prop of props) {
    retVal[prop] = o[prop];
  }
  return retVal;
}

export function getNumberFormatter(langCd: string, options: Intl.NumberFormatOptions = {}) {
  return new Intl.NumberFormat(isLocaleValueType(langCd) ? INTL_LOCALE_MAP[langCd] : DEFAULT_LANGUAGE, options);
}

export function nullTo<T = any, K = any>(value: T, defaultValue: K) {
  return isNil(value) ? defaultValue : value;
}

export function isEmpty(param: any) {
  return Object.keys(param).length === 0;
}

export function keyToCamelCase<T extends Record<string, any>>(obj: T): TransformKeysToCamelCase<T> {
  const retObj = {} as any;
  for (let key in obj) {
    const newKey = camelCase(key);
    retObj[newKey] = obj[key];
  }
  return retObj;
}

export function convertSettingParam(
  obj?: Record<string, any>
): TransformKeysToCamelCase<Record<string, string>> | undefined {
  if (!obj) return;
  const temp = pickBy(obj, (value) => value !== undefined);

  for (let key in temp) {
    if (typeof temp[key] !== 'object') {
      temp[key] = '' + temp[key];
    }
  }
  return keyToCamelCase(temp);
}

export function codeNameToSelectorOption(codeName: CodeName, t: any): SelectorOption {
  if (codeName.description) {
    return {
      text: t ? t(`${codeName.name}`, { defaultValue: codeName.name }) : codeName.name,
      value: codeName.code,
      description: codeName.description,
    };
  } else {
    return {
      text: t ? t(`${codeName.name}`, { defaultValue: codeName.name }) : codeName.name,
      value: codeName.code,
    };
  }
}

export function codeNamesToSelectorOptions(
  codeNames: CommonCode.CodeMap[],
  t?: any,
  first?: boolean | SelectorOption
): SelectorOption[] {
  const firstItem: SelectorOption[] = [];
  if (typeof first === 'boolean' && first === true) {
    firstItem.push({ text: '', value: null });
  } else if (typeof first === 'object') {
    firstItem.push(first);
  }
  return firstItem.concat(codeNames.map((item) => codeNameToSelectorOption(item, t)));
}

export function addSelectorOptionAtFirst(
  options: SelectorOption[],
  option: SelectorOption = { text: 'word.select', value: '' }
) {
  return [option].concat(options);
}

export function codeNameToLabelSelectorOption(codeName: CodeName, t: any): LabelSelectorOption {
  return {
    label: t ? t(`${codeName.name}`, { defaultValue: codeName.name }) : codeName.name,
    value: codeName.code,
  };
}

export function codeNamesToLabelSelectorOptions(
  codeNames: CommonCode.CodeMap[],
  t?: any,
  first?: boolean | LabelSelectorOption
): LabelSelectorOption[] {
  const firstItem: LabelSelectorOption[] = [];
  if (typeof first === 'boolean' && first === true) {
    firstItem.push({ label: '', value: null });
  } else if (typeof first === 'object') {
    firstItem.push(first);
  }
  return firstItem.concat(codeNames.map((item) => codeNameToLabelSelectorOption(item, t)));
}

export function addLabelSelectorOptionAtFirst(
  options: LabelSelectorOption[],
  option: LabelSelectorOption = { label: 'word.select', value: '' }
) {
  return [option].concat(options);
}

export function download(data: Blob, filename: string): void {
  let fileURL = window.URL.createObjectURL(data);
  let fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', filename);
  document.body.appendChild(fileLink);
  fileLink.click();
}

export function convertParamToStringAttr(param: Record<string, any>): Record<string, string> {
  const retParam = {} as Record<string, string>;
  for (const k in param) {
    if (Array.isArray(param[k])) {
      retParam[k] = param[k].join(',');
      continue;
    }
    if (param[k] === null || param[k] === undefined) continue;

    const type = typeof param[k];
    switch (type) {
      case 'number':
      case 'boolean':
        retParam[k] = '' + param[k];
        break;
      case 'object':
        retParam[k] = JSON.stringify(param[k]);
        break;
      case 'string':
        retParam[k] = param[k];
        break;
    }
  }

  return retParam;
}

export function contains<T extends string | number>(list: ReadonlyArray<T>, value: string | number): value is T {
  return list.some((item) => item === value);
}

export function downloadCSV(data: any[], columns: ColumnOptions[], fileName = 'export') {
  const _fileName = `${fileName}_${moment(new Date()).format('YYYYMMDDHHmmss')}`;
  const header = columns.map((item) => `"${item.header}"`).join(',');
  const headerId = columns.map((item) => item.name);
  const rows = [] as any[];
  data.forEach((item) => {
    const row = [];
    for (const id of headerId) {
      if (
        ['device_id', 'grp_nm', 'mpn_no', 'user_id', 'comn_nm', 'site_id', 'battery1', 'battery2', 'battery3'].includes(
          id
        )
      ) {
        row.push(`"${isNull(item[id]) ? '' : item[id]}\t"`);
      } else {
        row.push(`"${isNull(item[id]) ? '' : item[id]}"`);
      }
    }
    rows.push(row.join(','));
  });
  const csvData = header + '\r\n' + rows.join('\r\n');

  const exportedFilenmae = _fileName + '.csv';
  const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function getOperStatusName(operStusStr: String) {
  switch (operStusStr) {
    case 'code.operstuscd.0':
      return 'device.normal';
    case 'code.operstuscd.1':
      return 'device.warning';
    case 'code.operstuscd.2':
      return 'common.error';
    case 'code.operstuscd.4':
      return 'common.incomplete';
    default:
      return '';
  }
}

export function toFixed(value: number, decimalPoint: number): string {
  try {
    if (decimalPoint <= 0) return value.toString();
    const assistant = Math.pow(10, decimalPoint);
    return (Math.floor(value * assistant) / assistant).toFixed(decimalPoint);
  } catch (e) {
    console.error(e);
    return value.toString();
  }
}

export function capitalize(str: string) {
  return str ? str.charAt(0).toUpperCase() + str.toLocaleLowerCase().substring(1) : '';
}

export function isJSON(jsonString: string) {
  try {
    return JSON.parse(jsonString) && !!jsonString;
  } catch (e) {
    return false;
  }
}

export function getAppEnv(): AppEnv {
  return process.env.VUE_APP_ENV as AppEnv;
}

export function getEnvLocale(): EnvLocale {
  return process.env.VUE_APP_LOCALE as EnvLocale;
}

export const getAppType = (): AppTypeValue => process.env.VUE_APP_TYPE as AppTypeValue;

// ref: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (!userAgent) return 'unknown';

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
}

export function getDialCodeByCountry(locale?: EnvLocale): { name: string; dial_code: string }[] {
  const languageList = countries.getSupportedLanguages();
  languageList.forEach((item) => {
    countries.registerLocale(require(`i18n-iso-countries/langs/${item}.json`));
  });

  let countryAndLang = alpha2CountryList;
  if (locale) {
    switch (locale) {
      case 'EU':
        countryAndLang = euAlpha2CountryList;
        break;
      case 'AU':
      case 'NZ':
        countryAndLang = auAlpha2CountryList.concat(euAlpha2CountryList);
        break;
      case 'US':
        countryAndLang = usAlpha2CountryList.concat(euAlpha2CountryList);
        break;
      default:
        countryAndLang = alpha2CountryList;
    }
  }

  return countryAndLang.map((item) => ({
    name: countries.getName(item.country, item.lang),
    dial_code: `+${find(codes, { isoCode2: item.country })?.countryCodes[0]}`,
  }));
}

export const getTemperatureUnit = (type: SmartThingsTemperatureUnit = 'C'): string => {
  return type === 'F' ? '°F' : '°C';
};

export function checkValidEmsVersion(genType: GenType = 'GEN3', currentEmsVersion = '', validEmsVersion: number) {
  if (genType === 'GEN2') return null;
  if (currentEmsVersion === '') return null;
  const versionSeperator = currentEmsVersion[0];
  if (['R', 'Y', 'X', 'D'].includes(versionSeperator)) {
    const emsVersion = Number(currentEmsVersion.replace(/\D/g, '').substring(0, 2));
    if (emsVersion >= validEmsVersion) return true;
    else return false;
  }
  return null;
}

export function getKeyByValue(obj: any, value: any) {
  const indexOfObj = Object.values(obj).indexOf(value);
  const key = Object.keys(obj)[indexOfObj];
  return key;
}

export function isEmptyDeep(obj: any) {
  for (let key in obj) {
    if (obj[key] instanceof Object === true) {
      if (isEmptyDeep(obj[key]) === false) return false;
    } else {
      if (obj[key] !== undefined && obj[key] !== null && obj[key].length !== 0) return false;
    }
  }
  return true;
}

export function preventKeyEnter(event: any) {
  if (event.type === 'keydown') {
    if (!(event.target.type === 'textarea')) {
      event.preventDefault();
      return false;
    }
  }
  return true;
}

export function getCategoryNameByCode(category: string, t: any) {
  const codeNames = [
    {
      code: category.slice(-1),
      name: category,
    },
  ] as CommonCode.CodeMap[];
  return codeNamesToSelectorOptions(codeNames, t, false)[0].text;
}

export const getLangCd = (): LanguageValue => {
  const lang = localStorage.getItem(localStorageKey.language);
  return lang && isLanguageValueType(lang) ? lang : LANGUAGE.EN;
};

export function convertDecimalToHex(value: number, digit: number) {
  return value.toString(16).toUpperCase().padStart(digit, '0');
}
export function getACModelType(productModelNm: ProductModelName): ACModelType {
  if (!productModelNm) return AC_MODEL_TYPE.AC_ESS;
  if (productModelNm.includes(AC_MODEL_TYPE.AC_ESS)) return AC_MODEL_TYPE.AC_ESS;
  if (productModelNm.includes(AC_MODEL_TYPE.AC_COMBINER)) return AC_MODEL_TYPE.AC_COMBINER;

  return AC_MODEL_TYPE.AC_ESS;
}

export const isEmptyArray = (arr: Array<unknown>): boolean => {
  return arr.length === 0;
};

export function attachUnit(value: string | number | undefined | null, unit: string) {
  if (value === undefined || value === null) return null;

  return `${value} [${unit}]`;
}

export const attachUnitToTitle = (title: string, unit: string) => {
  return `${title} [${unit}]`;
};

export const getKwhData = (arr: (number | null)[]): FormattedUnitNumberData => {
  const sum = arr.reduce((prev, curr) => (prev ?? 0) + (curr ?? 0) * NUMBER_UNIT.THOUSAND, 0) ?? 0;
  const { origin, formattedNumber, unit } = formatUnitNumber(sum, UNIT.WATT_HOUR);
  return { origin, formattedNumber, unit };
};

export function getConnectStatus(isConnect: boolean, operStatusCd: string) {
  if (isConnect) {
    return DEVICE_CONNECTION_STATUS['ONLINE'];
  } else if (operStatusCd in DEVICE_CONNECTION_STATUS) {
    return DEVICE_CONNECTION_STATUS[operStatusCd];
  } else {
    return DEVICE_CONNECTION_STATUS['ONLINE_DISCONNECTED'];
  }
}

export const isNullOrUndefined = (value: unknown): boolean => typeof value === 'undefined' || value === null;

export const addSortOrderMark = (sortColumnName: string, sortOrder: SortOrderValue) => {
  return sortOrder === SORT_ORDER.ASCENDING ? sortColumnName : `-${sortColumnName}`;
};

export const addSortOrderMarkNew = (sortColumnName: string, sortOrder: SortOrderValue) => {
  return `${sortOrder === SORT_ORDER.ASCENDING ? '+' : '-'}${sortColumnName}`;
};

export const getSortNameAndOrder = (sort: string): { sortColumnName: string; isAscending: boolean } => {
  const sortColumnName = sort.replace(/^-/, '');

  const isAscending = !sort.startsWith('-');

  return { sortColumnName, isAscending };
};

export const getOrderStatus = (sort: string): boolean => {
  return !sort.startsWith('-');
};

export const getSelectorValueByOptions = (options: SelectorOption[], targetValue: SelectorValue) =>
  options.find((option) => option.value === targetValue)?.text;

export const isEmptyString = (value: string): value is '' => {
  return value === '';
};

const createFileUrl = (file: Blob, fileName: string, fileExtension: string, dateFormat: string): HTMLAnchorElement => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = `${fileName}_${moment().utc().format(dateFormat)}.${fileExtension}`;
  return link;
};

export const downloadFile = (file: Blob, fileName: string, fileExtension: string, dateFormat = 'YYYYMMDDHHmmss') => {
  const link = createFileUrl(file, fileName, fileExtension, dateFormat);
  link.click();
  link.remove();
};

export const defaultInputErrorInfo = {
  isError: false,
  errorMessage: '',
} as const;

export const getDataTypeofValue = (target: unknown): string => {
  return Object.prototype.toString.call(target).slice(8, -1);
};

export const getNumberLeftPadStringArrayInRange = (
  startNumber: number,
  endNumber: number,
  step = 1,
  padLength = 2
): string[] => range(startNumber, endNumber + 1, step).map((numberValue) => padLeft(numberValue, padLength));

/** 범위 겹칩 체크 */
export const isOverlapping = (
  start1: ComparableType,
  end1: ComparableType,
  start2: ComparableType,
  end2: ComparableType
): boolean => start1 < end2 && start2 < end1;

export const getIntlLocale = () => INTL_LOCALE_MAP[getLangCd()];

export const downloadButtonIconInfo: { src: string; extension: ImageFileExtensionValue } = {
  src: 'fleet/ic_download',
  extension: IMAGE_FILE_EXTENSION.SVG,
};

export const activityHistoryInitialConditions: ActivitySearchBoxOptions = {
  date: {
    start: getSixDaysAgo(),
    end: getEndDate(),
  },
  actTypeCd: '',
  cntryCd: '',
  searchFor: '',
};

export const initialNoticeItem: NoticeListItem = {
  noticeId: 0,
  title: '',
  contents: [],
  category: '',
  lang: '',
  isActive: false,
  noticeTarget: '',
  homeAppVer: '',
  proAppVer: '',
  date: {
    start: now(),
    end: now(),
  },
  startDt: '',
  endDt: '',
};

export const isLocalEnv = process.env.VUE_APP_ENV === APP_MODE.LOCAL;
