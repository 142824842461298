import { TOAST_MESSAGE_STATUS_TYPE } from '@hems/util/src/constant';
export const initState = {
    isShow: false,
    message: '',
    type: TOAST_MESSAGE_STATUS_TYPE.SUCCESS,
    isTOU: true,
};
const prefix = 'toast';
export const TOAST_MUTATION = {
    SET_IS_SHOW: `${prefix}/setIsShow`,
    SET_MESSAGE_INFO: `${prefix}/setMessageInfo`,
};
export default {
    namespaced: true,
    state: { ...initState },
    mutations: {
        setIsShow: (state, payload) => {
            state.isShow = payload;
        },
        setMessageInfo: (state, payload) => {
            state.message = payload.message;
            state.type = payload.type || TOAST_MESSAGE_STATUS_TYPE.SUCCESS;
            state.isTOU = payload.isTOU;
        },
        clear(state) {
            state = initState;
        },
    },
};
