import * as yup from 'yup';
import { DeviceServiceAdmin, DeviceServiceInstaller } from '@hems/service';
import { FIRMWARE_UPDATE_API_RESPONSE_CODE } from '@hems/util/src/constant/firmwareUpdate';
import { isNull } from '@hems/util/src/helper/helper';
// eslint-disable-next-line import/prefer-default-export
export function initYup() {
    yup.setLocale({
        mixed: {
            default: () => ({ key: 'message.invalid_value' }),
            required: ({ label }) => ({ key: 'message.field_required', values: { label } }),
        },
        string: {
            email: ({ label }) => ({ key: 'message.email_format', values: { label } }),
            min: ({ min, label }) => ({
                key: 'message.longer_than_min',
                values: { min, label },
            }),
            max: ({ max, label }) => ({
                key: 'message.shorter_than_max',
                values: { max, label },
            }),
        },
        number: {
            min: ({ min, label }) => ({
                key: 'message.greater_min',
                values: { min, label },
            }),
            max: ({ max, label }) => ({
                key: 'message.greater_max',
                values: { max, label },
            }),
            integer: ({ label }) => ({ key: 'message.none_integer', values: { label } }),
        },
    });
    yup.addMethod(yup.number, 'isValidStep', function (step) {
        return this.test('isValidStep', function (value) {
            const { createError } = this;
            return value === undefined
                ? false
                : value % step === 0
                    ? true
                    : createError({
                        message: {
                            key: 'message.valid_number_step',
                            values: {
                                step,
                            },
                        },
                    });
        });
    });
    yup.addMethod(yup.number, 'unitNumber', function (unitNumber, min = 0) {
        const getDecimalPoint = (num) => num?.toString().split('.')[1]?.length ?? 0;
        const exNumber = (unitNumber, min) => Array.from(Array(3), (_, i) => ((i + 1) * unitNumber + (min || 0)).toFixed(getDecimalPoint(unitNumber))).join(', ');
        return this.test('unitNumber', function (value) {
            const { createError } = this;
            return value === undefined
                ? false
                : getDecimalPoint(value) <= getDecimalPoint(unitNumber)
                    ? true
                    : createError({
                        message: {
                            key: 'message.insert_units',
                            values: {
                                unit_number: unitNumber,
                                ex: exNumber(unitNumber, min),
                            },
                        },
                    });
        });
    });
    yup.addMethod(yup.string, 'deviceId', function (prevValue, callback) {
        return this.test('deviceId', async function (value) {
            const { createError } = this;
            if (isNull(value)) {
                return false;
            }
            const classification = value.substr(0, 2).toUpperCase();
            const foxessPrefix = value.substr(0, 4).toUpperCase();
            if (classification === 'AR' && value.length !== 21) {
                if (callback)
                    callback(false);
                return false;
            }
            if (classification === 'HS' && value.length !== 18) {
                if (callback)
                    callback(false);
                return false;
            }
            if (!['AR', 'HS'].includes(classification) && value.length !== 18 && value.length !== 15) {
                if (callback)
                    callback(false);
                return false;
            }
            if (value === prevValue.value.deviceId) {
                if (callback) {
                    callback(true, {
                        product_model_nm: prevValue.value.productModelNm,
                        device_type: prevValue.value.deviceType,
                    });
                }
                return true;
            }
            try {
                const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
                const result = await deviceService.checkDeviceId(value);
                if (callback)
                    callback(result.result, result);
                if (result.result) {
                    prevValue.status = result.result;
                    prevValue.value = {
                        deviceId: value,
                        productModelNm: result.product_model_nm,
                        deviceType: result.device_type,
                    };
                    return true;
                }
                return createError({
                    message: result.fail_type === '0'
                        ? {
                            key: 'message.invalid_serial',
                        }
                        : result.fail_type === '1'
                            ? { key: 'message.duplicate_serial' }
                            : result.fail_type === '2'
                                ? { key: 'message.mobile_only_serial' }
                                : {},
                });
            }
            catch (e) {
                console.error(e);
                return false;
            }
        });
    });
    yup.addMethod(yup.string, 'deviceDemoId', function (callback) {
        return this.test('deviceDemoId', async function (value) {
            const { createError } = this;
            if (isNull(value)) {
                return false;
            }
            try {
                const deviceService = new DeviceServiceInstaller(window.axiosInstance.axios);
                const result = await deviceService.checkValidAcDevice(value);
                if (callback)
                    callback(result.result, result);
                if (result.result) {
                    return true;
                }
                return createError({
                    message: result.fail_type === '0'
                        ? {
                            key: 'message.invalid_serial',
                        }
                        : result.fail_type === '1'
                            ? { key: 'message.duplicate_serial' }
                            : {},
                });
            }
            catch (e) {
                console.error(e);
                return false;
            }
        });
    });
    yup.addMethod(yup.string, 'deviceIdOfSmartModule', function (prevValue, callback) {
        return this.test('deviceId', async function (value) {
            const { createError } = this;
            const error = createError({
                message: {
                    key: 'message.duplicate_invalid_serial',
                },
            });
            if (isNull(value)) {
                return false;
            }
            const regex = /^\d{1}\.\d{1}\.\d{6}$/;
            if (!regex.test(value)) {
                return false;
            }
            if (value === prevValue.value.deviceId) {
                if (callback) {
                    callback(true, {
                        product_model_nm: prevValue.value.productModelNm,
                        device_type: prevValue.value.deviceType,
                    });
                }
                return true;
            }
            try {
                const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
                const result = await deviceService.checkDeviceId(value);
                if (callback)
                    callback(result.result, result);
                if (result.result) {
                    prevValue.status = result.result;
                    prevValue.value = {
                        deviceId: value,
                        productModelNm: result.product_model_nm,
                        deviceType: result.device_type,
                    };
                    return true;
                }
                return error;
            }
            catch (e) {
                console.error(e);
                return false;
            }
        });
    });
    yup.addMethod(yup.string, 'groupName', function isValidateGroupNameDuplicate(options) {
        return this.test('groupName', async function groupNameValidationTest(groupName) {
            const { createError } = this;
            if (isNull(groupName)) {
                return createError({
                    message: {
                        key: 'message.field_required',
                    },
                });
            }
            try {
                const result = (await options.validator(groupName));
                if (result.data.result === FIRMWARE_UPDATE_API_RESPONSE_CODE.DUPLICATE) {
                    return createError({
                        message: {
                            key: 'message.duplicate_invalid_grp',
                        },
                    });
                }
                return true;
            }
            catch (e) {
                return false;
            }
        });
    });
    const prevValues = {};
    const initValue = {
        status: false,
        value: undefined,
    };
    function clearPrevValues(name) {
        if (prevValues[name]) {
            delete prevValues[name];
        }
    }
    Object.assign(yup, { clearPrevValues });
    yup.addMethod(yup.string, 'isValidByFn', function (key, checkerFn, force = false) {
        return this.test('isValidByFn', async function (value) {
            const { createError } = this;
            try {
                return await isValidByFn(key, value, checkerFn, createError, force);
            }
            catch (error) {
                return false;
            }
        });
    });
    yup.addMethod(yup.number, 'isValidByFn', function (key, checkerFn, force = false) {
        return this.test('isValidByFn', { key: 'message.invalid' }, async function (value) {
            const { createError } = this;
            return await isValidByFn(key, Number(value), checkerFn, createError, force);
        });
    });
    async function isValidByFn(key, value, checkerFn, createError, force = false) {
        const isForceCallFn = typeof force === 'boolean' ? force : force();
        if (!prevValues[key]) {
            prevValues[key] = { ...initValue };
        }
        if (value === prevValues[key].value && !isForceCallFn)
            return prevValues[key].status;
        prevValues[key].value = value;
        try {
            const { isValid, errMsg } = await checkerFn(value, prevValues[key]);
            prevValues[key].status = isValid ?? false;
            if (errMsg)
                return createError({ message: errMsg });
            return prevValues[key].status;
        }
        catch (error) {
            prevValues[key].status = false;
            return prevValues[key].status;
        }
    }
    yup.addMethod(yup.string, 'isValidLatLon', function (prevValue, options, currValue) {
        return this.test('isValidLatLon', { key: 'message.duplicate_location' }, async function (value) {
            const { createError } = this;
            if (currValue) {
                const curr = currValue.value;
                const lat = this.parent[options.latKey || 'lat'];
                const lon = this.parent[options.lonKey || 'lon'];
                if (lat === curr.lat && lon === curr.lon)
                    return currValue.status;
            }
            try {
                if (value === null || value === undefined)
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                const lat = this.parent[options.latKey || 'lat'];
                const lon = this.parent[options.lonKey || 'lon'];
                if (lat === null || lat === undefined || lon === null || lon === undefined) {
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                }
                if (prevValue.value) {
                    const prev = prevValue.value;
                    if (prev.lat === lat && prev.lon === lon)
                        return prevValue.status;
                }
                const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
                const result = await deviceService.checkDuplicatedLocation({ latitude: lat, longitude: lon });
                prevValue.status = !result;
                prevValue.value = { lat, lon };
                return !result;
            }
            catch (e) {
                return createError({
                    message: {
                        key: 'message.invalid',
                    },
                });
            }
        });
    });
    yup.addMethod(yup.string, 'isValidPassword', function (options, callback, t) {
        return this.test('isValidPassword', { key: 'message.invalid' }, function (value) {
            try {
                const { createError } = this;
                if (isNull(value)) {
                    callback(true);
                    return true;
                }
                // 길이
                let regexpLength = new RegExp(`^.{${options.min},${options.max}}$`);
                if (options.min === options.max) {
                    regexpLength = new RegExp(`^.{${options.min}}$`);
                }
                const regexpNumberLetter = /^(?=.*[a-zA-Z])(?=.*\d).+$/; // 숫자 1개 이상 및 영문자
                const regexpSpecialCharacter = /.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*/; // 특수문자 1개 이상
                if (!regexpLength.test(value)) {
                    if (options.min !== options.max) {
                        callback(false);
                        return createError({
                            message: t
                                ? t('message.min_max_characters', { min: options.min, max: options.max })
                                : `It should be between ${options.min} and ${options.max} characters.`,
                        });
                    }
                    callback(false);
                    return createError({
                        message: t
                            ? t('message.num_characters', { num: options.min })
                            : `It should be ${options.min} characters.`,
                    });
                }
                if (!regexpNumberLetter.test(value)) {
                    callback(false);
                    return createError({ message: { key: 'message.should_number_letter' } });
                }
                if (!regexpSpecialCharacter.test(value)) {
                    callback(false);
                    return createError({ message: { key: 'message.should_one_special' } });
                }
                callback(true);
                return true;
            }
            catch (e) {
                callback(false);
                return false;
            }
        });
    });
}
