/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
export const HOME_APPLIANCE = 'home-appliance';
export const APPLIANCE_TYPE = {
    HEAT_PUMP: 'HeatPump',
    AIR_CONDITIONER: 'Air Conditioner',
    SMART_PLUG: 'Smart Plug',
    TV: 'TV',
};
export const SMARTTHINGS_TEMPERATURE_UNIT = {
    CELSIUS: 'C',
    FAHRENHEIT: 'F',
};
export const APPLIANCE_STATUS = {
    ON: 'On',
    OFF: 'Off',
};
export const SMARTTHINGS_COMPONENT_TYPE = {
    MAIN: 'main',
    INDOOR: 'indoor',
};
export const SMARTTHINGS_HEALTH_STATUS = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
};
export const APPLIANCE_NAME_KEY = {
    [APPLIANCE_TYPE.HEAT_PUMP]: 'device.heat_pump',
    [APPLIANCE_TYPE.AIR_CONDITIONER]: 'common.air_conditioner',
    [APPLIANCE_TYPE.SMART_PLUG]: 'common.smart_plug',
    [APPLIANCE_TYPE.TV]: 'common.tv',
};
export const APPLIANCE_CONTROL_TYPE = {
    MODE: 'mode',
    TEMPERATURE: 'temperature',
};
export const SMARTTHINGS_CONTROL_STATUS = {
    ACCEPTED: 'ACCEPTED',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
};
