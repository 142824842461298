import type { CommonCode } from 'hems';

import type { ValueType } from 'hems/common/utils';

export const REMOTE_CONTROL_CODE = {
  CONTROL_COMMAND: 'controlCommand',
  REBOOT: 'reboot',
  CHANGE_OPERATION_MODE: 'changeOperationMode',
  POWER_CONTROL_POINT: 'powerControlPoint',
  GET_SYSTEM_STATUS: 'getSystemStatus',
  CONNECT_REVERSE_SSH: 'connectReverseSsh',
  RELEASE_PCS_PROTECTION: 'releasePcsProtection',
  RELEASE_HUB_PROTECTION: 'releaseHubProtection',
  REALTIME_MONITORING: 'realtimeMonitoring',
} as const;

export type RemoteControlCodeValue = ValueType<typeof REMOTE_CONTROL_CODE>;

export const OPERATION_MODE_CODE = {
  NOP: '0',
  AUTO: '1',
  MANUAL: '2',
  RESTORE: '99',
} as const;

export type OprationModeValue = ValueType<typeof OPERATION_MODE_CODE>;

export const ENGINEERING_MODE_CODE = {
  OFF: 0,
  ON: 1,
} as const;

export type EngineeringModeValue = ValueType<typeof ENGINEERING_MODE_CODE>;

export const POWER_CONTROL_POINT_CODE = {
  BATTERY: '0',
  INVERTER: '1',
} as const;

export type PowerControlPointValue = ValueType<typeof POWER_CONTROL_POINT_CODE>;

export const POWER_CONTROL_RANGE = {
  INVERTER: { MIN: -32768, MAX: 32767 },
  BATTERY: { MIN: -32768, MAX: 32767 },
  PV: { MIN: 0, MAX: 32767 },
} as const;

export const REMOTE_CONTROL_AC_SITE_COMMAND: CommonCode.CodeMap[] = [
  {
    code: REMOTE_CONTROL_CODE.REBOOT,
    name: 'code.remotecontroltypecd.0',
  },
  {
    code: REMOTE_CONTROL_CODE.CHANGE_OPERATION_MODE,
    name: 'code.remotecontroltypecd.1',
  },
  {
    code: REMOTE_CONTROL_CODE.GET_SYSTEM_STATUS,
    name: 'code.remotecontroltypecd.2',
  },
];

export const REMOTE_CONTROL_ACES_DEVICE_COMMAND: CommonCode.CodeMap[] = [
  ...REMOTE_CONTROL_AC_SITE_COMMAND,
  {
    code: REMOTE_CONTROL_CODE.CONNECT_REVERSE_SSH,
    name: 'code.remotecontroltypecd.3',
  },
  {
    code: REMOTE_CONTROL_CODE.RELEASE_PCS_PROTECTION,
    name: 'code.remotecontroltypecd.5',
  },
  {
    code: REMOTE_CONTROL_CODE.RELEASE_HUB_PROTECTION,
    name: 'code.remotecontroltypecd.14',
  },
  {
    code: REMOTE_CONTROL_CODE.REALTIME_MONITORING,
    name: 'code.remotecontroltypecd.15',
  },
];

export const REMOTE_CONTROL_ACCB_DEVICE_COMMAND: CommonCode.CodeMap[] = [
  ...REMOTE_CONTROL_AC_SITE_COMMAND,
  {
    code: REMOTE_CONTROL_CODE.CONNECT_REVERSE_SSH,
    name: 'code.remotecontroltypecd.3',
  },
  {
    code: REMOTE_CONTROL_CODE.RELEASE_HUB_PROTECTION,
    name: 'code.remotecontroltypecd.14',
  },
  {
    code: REMOTE_CONTROL_CODE.REALTIME_MONITORING,
    name: 'code.remotecontroltypecd.15',
  },
];
