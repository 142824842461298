import { defineComponent, inject, computed } from 'vue';
import { BasicButton } from '@hems/component';
import { PROVIDE_INJECT_KEYS, BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'SettingButtonGroup',
    components: {
        BasicButton,
    },
    props: {
        isEditable: {
            type: Boolean,
            default: false,
        },
        isAdvancedTopButton: {
            type: Boolean,
            default: false,
        },
        isDisableApply: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['goToList', 'cancel', 'edit'],
    setup(props, { emit }) {
        const goToList = inject(PROVIDE_INJECT_KEYS.GO_TO_LIST);
        const buttonWrapperClass = computed(() => (props.isAdvancedTopButton ? 'advanced-top' : 'default'));
        const onEdit = () => {
            emit('edit');
        };
        const onCancel = () => {
            emit('cancel');
        };
        return {
            buttonWrapperClass,
            BASIC_BUTTON_COLOR_TYPE,
            goToList,
            onEdit,
            onCancel,
        };
    },
});
