import { defineComponent, onMounted, ref, watch } from 'vue';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';
import Grid from 'tui-grid';
import { isGridCheckEventType, isGridEventType, isHTMLElementType } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'ToastGrid',
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            required: true,
        },
        sortState: {
            type: Object,
            default: () => null,
        },
        options: {
            type: Object,
            default: () => null,
        },
        hiddenColumns: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['instance', 'iconClick', 'initCheckState', 'checkRow', 'uncheckRow'],
    setup(props, { emit, attrs }) {
        let instance = null;
        const tuiGrid = ref(null);
        const listener = attrs.events;
        const updateRowColor = (event, isChecked) => {
            if (!instance || !isGridCheckEventType(event))
                return;
            const className = 'checked-row';
            if (isChecked) {
                instance.addRowClassName(event.rowKey, className);
                emit('checkRow', event.rowKey, event);
            }
            else {
                instance.removeRowClassName(event.rowKey, className);
                emit('uncheckRow', event.rowKey, event);
            }
        };
        const updateAllRowColors = (isChecked, event) => {
            if (!instance)
                return;
            instance.getData().forEach((row) => {
                updateRowColor(row, isChecked);
                if (isGridEventType(event) && isChecked) {
                    emit('checkRow', row.rowKey, event);
                }
                else {
                    emit('uncheckRow', row.rowKey, event);
                }
            });
        };
        const syncRowHighlightEvents = () => {
            if (!instance)
                return;
            instance.on('check', (event) => updateRowColor(event, true));
            instance.on('uncheck', (event) => updateRowColor(event, false));
            instance.on('checkAll', (event) => updateAllRowColors(true, event));
            instance.on('uncheckAll', (event) => updateAllRowColors(false, event));
        };
        const addEventListeners = () => {
            if (!listener || !instance)
                return;
            Object.entries(listener).forEach(([eventName, listener]) => {
                if (typeof listener === 'function') {
                    instance?.on(eventName, listener);
                }
            });
            syncRowHighlightEvents();
        };
        const createChart = () => {
            if (!tuiGrid.value)
                return;
            Grid.applyTheme('clean');
            instance = new Grid(merge({
                contextMenu: null,
                el: tuiGrid.value,
                columns: props.columns,
                data: props.data,
                rowHeight: 'auto',
                minRowHeight: 50,
            }, props.options));
            addEventListeners();
            emit('instance', instance);
        };
        const handleGridCheck = (rowIndex) => {
            if (instance && instance.getRow(rowIndex)) {
                instance.check(rowIndex);
            }
        };
        const handleGridClick = (event) => {
            const target = isHTMLElementType(event.target) ? event.target : null;
            if (!target || !target.classList.contains('svg-icon'))
                return;
            const dataId = target.getAttribute('data-id');
            if (!dataId)
                return;
            emit('iconClick', dataId);
        };
        const addGridClickListener = () => {
            if (!tuiGrid.value)
                return;
            tuiGrid.value.addEventListener('click', handleGridClick);
        };
        const showAllColumns = () => {
            props.columns
                .filter((column) => !props.hiddenColumns.includes(column.name))
                .forEach((column) => {
                instance?.showColumn(column.name);
            });
        };
        watch(() => props.data, () => {
            instance?.resetData(cloneDeep(props.data) || [], {
                sortState: props.sortState,
            });
            /** resetData로 데이터를 업로드 해주고 나서, 체크되어 있는 항목을 표시해야하므로 이 위치에 추가 */
            emit('initCheckState', handleGridCheck);
        });
        watch(() => props.hiddenColumns, () => {
            showAllColumns();
            props.hiddenColumns.forEach((column) => {
                instance?.hideColumn(column);
            });
        });
        onMounted(() => {
            createChart();
            updateAllRowColors(false);
            addGridClickListener();
        });
        return {
            tuiGrid,
        };
    },
});
