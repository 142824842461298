
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useACDeviceType, useSiteDeviceGenType, useSiteInfo } from '@hems/util/src/composable';
import { NUMBER_UNIT } from '@hems/util/src/constant';
import { CAPACITY_UINT_TYPE } from '@hems/util/src/constant/unit';
import { addUnitToCapacity } from '@hems/util/src/helper/dashboardHelper';

export default defineComponent({
  name: 'Inventory',
  setup() {
    const { t } = useI18n();

    const { inventoryInfo } = useSiteInfo();

    const { isAC } = useSiteDeviceGenType();

    const { isSolarConfig, hasACCombiner } = useACDeviceType();

    const PVCapacity = inventoryInfo ? addUnitToCapacity(inventoryInfo.total_pv_cap, CAPACITY_UINT_TYPE.PEAK) : null;

    const batteryCapacity = inventoryInfo
      ? addUnitToCapacity(inventoryInfo.total_bat_cap * NUMBER_UNIT.THOUSAND, CAPACITY_UINT_TYPE.HOUR)
      : null;

    const PVLabel = computed(() => (isAC && hasACCombiner ? t('device.n_of_ac_module') : t('device.n_of_module')));

    return {
      inventoryInfo,
      PVCapacity,
      batteryCapacity,
      isSolarConfig,
      PVLabel,
      hasACCombiner,
    };
  },
});
