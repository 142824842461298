import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "pop_wrap_2 on custom-scrollbar",
    style: { "opacity": "1" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _mergeProps({ class: "pop_con" }, _ctx.$attrs), [
            _renderSlot(_ctx.$slots, "default")
        ], 16)
    ]));
}
