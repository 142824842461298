
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SettingInfoBlock',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
});
