import { computed, defineComponent } from 'vue';
import range from 'lodash-es/range';
import { useModelWrapper } from '@hems/util';
// 경로 문제로 상대경로 사용
import Image2 from '../image/Image2.vue';
const PAGE_COUNT = 5;
export default defineComponent({
    name: 'Pagination',
    components: {
        Image2,
    },
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        rowCountPerPage: {
            type: Number,
            default: 10,
        },
        totalRowCount: {
            type: Number,
            required: true,
        },
    },
    emits: ['changePage'],
    setup(props, { emit }) {
        const current = useModelWrapper(props, emit);
        const paging = computed(() => {
            const totalPages = Math.ceil(props.totalRowCount / props.rowCountPerPage);
            const pages = [];
            if (totalPages <= PAGE_COUNT) {
                pages.push(...range(1, totalPages + 1));
            }
            else {
                if (current.value <= 3) {
                    pages.push(...[...range(1, PAGE_COUNT), '', totalPages]);
                }
                else if (current.value >= totalPages - 2) {
                    pages.push(...[1, '', ...range(totalPages - 3, totalPages + 1)]);
                }
                else {
                    pages.push(...[1, '', ...range(current.value - 1, current.value + 2), '', totalPages]);
                }
            }
            return {
                totalPages,
                disablePrevArrow: current.value === 1,
                disableNextArrow: current.value === totalPages,
                pages,
            };
        });
        const hasPages = computed(() => paging.value.totalPages > 0);
        const prevImageSrc = computed(() => paging.value.disablePrevArrow ? 'fleet/ic_arrow_left_disabled' : 'fleet/ic_arrow_left');
        const nextImageSrc = computed(() => paging.value.disableNextArrow ? 'fleet/ic_arrow_right_disabled' : 'fleet/ic_arrow_right');
        const onChangePage = (pageNum) => {
            if (typeof pageNum !== 'number') {
                return;
            }
            if (pageNum >= 1 && pageNum <= paging.value.totalPages) {
                emit('changePage');
                current.value = pageNum;
            }
        };
        return {
            current,
            hasPages,
            paging,
            prevImageSrc,
            nextImageSrc,
            PAGE_COUNT,
            onChangePage,
        };
    },
});
