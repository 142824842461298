/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useScheduleDate } from '@hems/util/src/composable/energyManagement/scheduleDate';
import { ENERGY_MANAGEMENT_MUTATION } from '@hems/util/src/stores/modules/energyManagement';
// eslint-disable-next-line import/prefer-default-export
export const useEnergyManagement = () => {
    const store = useStore();
    const siteId = computed(() => store.state.energyManagement.siteId);
    const productModelName = computed(() => store.state.energyManagement.productModelName);
    const masterDeviceId = computed(() => store.state.energyManagement.masterDeviceId);
    const { convertScheduleDateRange, isStartDateLaterThanEndDate } = useScheduleDate();
    const scheduleInfoList = computed(() => store.state.energyManagement.scheduleInfoList.sort((a, b) => isStartDateLaterThanEndDate(a.startDate, b.startDate) ? 1 : -1));
    const selectedScheduleInfo = computed(() => store.state.energyManagement.selectedScheduleInfo);
    const scheduleNameList = computed(() => scheduleInfoList.value.map((schedule) => schedule.name));
    const scheduleDateList = computed(() => scheduleInfoList.value.map((schedule) => convertScheduleDateRange(schedule.startDate, schedule.endDate)));
    const disabledDateList = computed(() => {
        return scheduleInfoList.value
            .filter((scheduleInfo) => scheduleInfo.name !== selectedScheduleInfo?.value?.name)
            .map((schedule) => convertScheduleDateRange(schedule.startDate, schedule.endDate));
    });
    const defaultOperation = computed(() => store.state.energyManagement.defaultOperation);
    const presetInfoList = computed(() => store.state.energyManagement.presetInfoList);
    const currentEnergyManagementSettingType = computed(() => store.state.energyManagement.currentEnergyManagementSettingType);
    const selectedOperationInfo = computed(() => store.state.energyManagement.selectedOperationInfo);
    const weeklySchedulerEventList = computed(() => store.state.energyManagement.weeklySchedulerEventList);
    const totalOperationCount = computed(() => weeklySchedulerEventList.value.length);
    const getScheduleByName = (scheduleName) => store.state.energyManagement.scheduleInfoList.find((schedule) => schedule.name === scheduleName) ?? null;
    const getPresetOperationsById = (presetId) => store.state.energyManagement.presetOperationMap?.[presetId] ?? [];
    const updateWeeklySchedulerEventList = (scheduleEventList) => {
        store.commit(ENERGY_MANAGEMENT_MUTATION.UPDATE_WEEKLY_SCHEDULER_EVENT_LIST, scheduleEventList);
    };
    const setSelectedScheduleInfo = (schedule) => {
        store.commit(ENERGY_MANAGEMENT_MUTATION.SET_SELECTED_SCHEDULE_INFO, schedule);
    };
    return {
        siteId,
        productModelName,
        masterDeviceId,
        scheduleInfoList,
        selectedScheduleInfo,
        scheduleNameList,
        scheduleDateList,
        presetInfoList,
        defaultOperation,
        currentEnergyManagementSettingType,
        selectedOperationInfo,
        weeklySchedulerEventList,
        totalOperationCount,
        disabledDateList,
        getScheduleByName,
        getPresetOperationsById,
        updateWeeklySchedulerEventList,
        setSelectedScheduleInfo,
    };
};
