import { defineComponent, ref, onMounted, onBeforeUnmount, inject } from 'vue';
import { IconButton } from '@hems/component';
import { DateHelper, useSiteDeviceGenType, useSiteId, useSiteInfo } from '@hems/util';
import { NUMBER_UNIT, PROVIDE_INJECT_KEYS, SITE_STATUS_CODE_MAP } from '@hems/util/src/constant';
import { isSiteStatusCodeType } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'MonitoringDetailsHeader',
    components: {
        IconButton,
    },
    setup() {
        const { isAC } = useSiteDeviceGenType();
        const { siteId } = useSiteId();
        const { masterDeviceProfile } = useSiteInfo();
        const timezoneId = masterDeviceProfile?.timezone_id;
        const goToList = inject(PROVIDE_INJECT_KEYS.GO_TO_LIST);
        const statusCode = String(masterDeviceProfile?.site_status_cd).charAt(0) ?? '';
        const statusValue = isSiteStatusCodeType(statusCode) ? SITE_STATUS_CODE_MAP[statusCode] : '';
        const statusIndex = Object.entries(SITE_STATUS_CODE_MAP).findIndex(([, value]) => value === statusValue) + 1;
        const getLocalTime = () => DateHelper.getLocalDate(DateHelper.now(), {
            isTime: true,
            needTimezoneConvert: true,
            timezone: timezoneId,
        });
        const localTime = ref(getLocalTime());
        const updateTime = () => {
            localTime.value = getLocalTime();
        };
        let intervalId;
        onMounted(() => {
            intervalId = setInterval(updateTime, NUMBER_UNIT.THOUSAND);
        });
        onBeforeUnmount(() => clearInterval(intervalId));
        return {
            siteId,
            isAC,
            timezoneId,
            localTime,
            statusValue,
            statusIndex,
            goToList,
        };
    },
});
