/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
/* eslint-disable no-param-reassign */
import type { Module } from 'vuex';

import { AuthService } from '@hems/service';
import type { AuthStatusValue } from '@hems/util/src/constant';
import { AUTH_STATUS } from '@hems/util/src/constant';
import { ROLE_CODE, ROLE_NAME } from '@hems/util/src/constant/role';
import type { RoleCodeValue } from '@hems/util/src/constant/role';
import {
  getRoleName,
  isExpired,
  parseJwt,
  removeAuthToken,
  getAuthTokenFromCookie,
  saveAuthToken,
} from '@hems/util/src/helper/authHelper';

import type { Role } from 'hems';

import type { AuthToken } from 'hems/auth';
import type { CommonRootState } from 'hems/common/store';
import type { UserInfoState } from 'hems/user';

export const initState: UserInfoState = {
  accessToken: null,
  idToken: null,
  userId: null,
  userName: null,
  roleCd: null,
  roleNm: null,
};

const prefix = 'user' as const;

export const USER_ACTION = {
  SET_AUTH_TOKEN: `${prefix}/setAuthToken`,
  ACQUIRE_TOKEN: `${prefix}/acquireToken`,
  CLEAR: `${prefix}/clear`,
} as const;

const userModule: Module<UserInfoState, CommonRootState> = {
  namespaced: true,
  state: initState,
  getters: {
    isAuthenticated: (state) => !!state.accessToken,
    isTokenExpired: (state) => {
      const jwtObject = parseJwt(state.accessToken ?? '');

      return jwtObject ? isExpired(jwtObject) : false;
    },
  },
  mutations: {
    clear(state) {
      state.accessToken = null;
      state.idToken = null;
      state.userId = null;
      state.userName = null;
      state.roleCd = null;
      state.roleNm = null;
    },
    setRole(state, payload: { roleCd: RoleCodeValue; roleNm: Role }) {
      state.roleCd = payload.roleCd;
      state.roleNm = payload.roleNm;
    },
    setUserId(state, payload: string) {
      state.userId = payload;
    },
    setUserName(state, payload: string) {
      state.userName = payload;
    },
    setAccessToken(state, payload: string) {
      state.accessToken = payload;
    },
    setIdToken(state, payload: string) {
      state.idToken = payload;
    },
    removeToken(state) {
      state.accessToken = null;
      state.idToken = null;
    },
  },
  actions: {
    setRole({ commit }, payload: { roleCd: RoleCodeValue; roleNm: Role }) {
      commit('setRole', payload);
    },
    setUserId({ commit }, payload: string) {
      commit('setUserId', payload);
    },
    setUserName({ commit }, payload: string) {
      commit('setUserName', payload);
    },
    acquireToken: ({ commit }, { accessToken, idToken }: AuthToken): Promise<AuthStatusValue> => {
      if (!accessToken || !idToken) {
        return Promise.resolve(AUTH_STATUS.INVALID_ACCESS_TOKEN);
      }

      commit('setAccessToken', accessToken);
      commit('setIdToken', idToken);

      saveAuthToken({ accessToken, idToken });
      window.axiosInstance.setAccessToken(accessToken);

      return Promise.resolve(AUTH_STATUS.SUCCESS);
    },
    setAuthToken: async ({ commit }, authToken: AuthToken): Promise<AuthStatusValue> => {
      if (!authToken.accessToken) {
        return AUTH_STATUS.INVALID_ACCESS_TOKEN;
      }

      const accessTokenJwtObject = parseJwt(authToken.accessToken);

      if (!accessTokenJwtObject) {
        return AUTH_STATUS.TOKEN_PARSING_ERROR;
      }

      const userId = accessTokenJwtObject.preferred_username;
      const userName = accessTokenJwtObject.name;

      commit('setUserId', userId);
      commit('setUserName', userName);

      commit('setAccessToken', authToken.accessToken);
      commit('setIdToken', authToken.idToken);

      window.axiosInstance.setAccessToken(authToken.accessToken);

      return AUTH_STATUS.SUCCESS;
    },
    clear({ commit }) {
      removeAuthToken();
      window.axiosInstance.clearAccessToken();

      commit('clear');
    },
  },
};

export default userModule;
