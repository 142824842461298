import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7ae1de6a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "image-layer"
};
const _hoisted_2 = ["name", "accept"];
const _hoisted_3 = {
    key: 0,
    class: "image-content"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
    key: 1,
    class: "image-empty"
};
const _hoisted_6 = {
    key: 1,
    class: "image-layer"
};
const _hoisted_7 = { class: "image-content" };
const _hoisted_8 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_BasicButton = _resolveComponent("BasicButton");
    return (_ctx.editable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("input", {
                ref: "imageInput",
                name: _ctx.name,
                type: "file",
                accept: _ctx.accept?.join(','),
                hidden: "",
                onChange: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.onChangeImages && _ctx.onChangeImages(...args)))
            }, null, 40, _hoisted_2),
            (_ctx.state.imageUrl)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", {
                        class: "close",
                        onClick: _cache[1] || (_cache[1] =
                            //@ts-ignore
                            (...args) => (_ctx.clear && _ctx.clear(...args)))
                    }),
                    _createElementVNode("img", {
                        src: _ctx.state.imageUrl,
                        style: { "width": "100%" }
                    }, null, 8, _hoisted_4)
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_BasicButton, { onClick: _ctx.triggerImageUpload }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('common.upload')), 1)
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ]))
        ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
                (_ctx.state.imageUrl)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.state.imageUrl,
                        style: { "width": "100%" }
                    }, null, 8, _hoisted_8))
                    : _createCommentVNode("", true)
            ])
        ]));
}
