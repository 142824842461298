import { defineComponent, onMounted, ref } from 'vue';
import BasicButton from '@hems/component/src/buttons/BasicButton.vue';
import { BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
import { downloadButtonIconInfo } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'SignPadViewer',
    components: {
        BasicButton,
    },
    props: {
        agreeSign: {
            type: String,
            default: null,
        },
        agreeSignBinary: {
            type: String,
            default: null,
        },
        hideDownload: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const signPad = ref(null);
        const signImageUrl = ref(null);
        const toImage = (agreeSign) => {
            const data = JSON.parse(agreeSign || '[]');
            if (!signPad.value)
                return;
            $(signPad.value).signaturePad({ displayOnly: true }).regenerate(data);
            return $(signPad.value).signaturePad().getSignatureImage();
        };
        onMounted(() => {
            if (props.agreeSignBinary) {
                signImageUrl.value = `data:image/png;base64, ${props.agreeSignBinary}`;
            }
            else {
                signImageUrl.value = toImage(props.agreeSign);
            }
        });
        const download = () => {
            const link = document.createElement('a');
            link.download = 'sign.png';
            link.href = signImageUrl.value || '#';
            link.click();
        };
        return {
            signImageUrl,
            downloadButtonIconInfo,
            BASIC_BUTTON_COLOR_TYPE,
            download,
        };
    },
});
