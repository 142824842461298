import { computed, defineComponent, ref } from 'vue';
import { useRoute, RouterLink } from 'vue-router';
import { useIntervalFn } from '@vueuse/core';
import { useACDeviceType, useLanguage, useRole, useSiteInfo } from '@hems/util';
import { NUMBER_UNIT, SITE_DETAIL_ROUTE, OPERATION_STATUS_CODE } from '@hems/util/src/constant';
import { getDateFormatter, now } from '@hems/util/src/helper/dateHelper';
import { getRouteInfo, isStringType } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'SiteDetailTab',
    components: {
        RouterLink,
    },
    setup() {
        const route = useRoute();
        const { hasACCombiner } = useACDeviceType();
        const { isDevOrAdminOrService } = useRole();
        const { languageCode } = useLanguage();
        const { timezoneId, masterDeviceProfile } = useSiteInfo();
        const siteId = computed(() => Number(route.query.siteId));
        const deviceId = computed(() => getRouteInfo(route.query.deviceId, isStringType) ?? '');
        const statusId = computed(() => masterDeviceProfile?.site_status_cd ?? '');
        const tabname = computed(() => route.path.replace('/remote/monitoring/details/', '').split('/')[0]);
        const routerQuery = computed(() => ({
            siteId: siteId.value,
            deviceId: deviceId.value,
        }));
        const dateFormatOptions = computed(() => ({
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            timeZone: timezoneId,
            timeZoneName: 'short',
        }));
        const getLocalDate = () => getDateFormatter(languageCode.value, dateFormatOptions.value).format(now());
        const localDateString = ref(getLocalDate());
        useIntervalFn(() => {
            localDateString.value = getLocalDate();
        }, NUMBER_UNIT.THOUSAND);
        return {
            siteId,
            deviceId,
            statusId,
            tabname,
            hasACCombiner,
            routerQuery,
            SITE_DETAIL_ROUTE,
            isDevOrAdminOrService,
            localDateString,
            OPERATION_STATUS_CODE,
        };
    },
});
