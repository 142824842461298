export const firmwareUpdateGroupData = {
    context: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 9999,
    },
    data: [
        {
            updateGroupId: 'test-group-222',
            updateType: 'AUTO',
            configurationType: 'BACKUP',
            requestDate: '2024-09-21T00:00:00+09:00',
            firmwareVersions: {
                id: 'firmware-set-id-1',
                firmwareVersions: [
                    {
                        firmwareId: 'firmware-id-1',
                        firmwareVersion: 'R01.01.x5',
                        firmwareType: 'EMS',
                        fullFilename: 'ems-full-filename.zip',
                        fullFileSize: 1024000,
                        fullFileHash: 'vwev32f23ffewfewf',
                        appFilename: 'app-filename.zip',
                        appFileSize: 1024000,
                        appFileHash: 'acxvjw0f2j09ml',
                    },
                    {
                        firmwareId: 'firmware-id-2',
                        firmwareVersion: 'R01.01.x4',
                        firmwareType: 'PCS',
                        fullFilename: 'pcs-full-filename.zip',
                        fullFileSize: 1024000,
                        fullFileHash: 'vwev32f23ffewfewf',
                        appFilename: 'app-filename.zip',
                        appFileSize: 1024000,
                        appFileHash: 'acxvjw0f2j09ml',
                    },
                    {
                        firmwareId: 'firmware-id-1',
                        firmwareVersion: 'R01.01.x3',
                        firmwareType: 'GEM',
                        fullFilename: 'gem-full-filename.zip',
                        fullFileSize: 1024000,
                        fullFileHash: 'vwev32f23ffewfewf',
                        appFilename: 'app-filename.zip',
                        appFileSize: 1024000,
                        appFileHash: 'acxvjw0f2j09ml',
                    },
                    {
                        firmwareId: 'firmware-id-1',
                        firmwareVersion: 'R01.01.x2',
                        firmwareType: 'BMS',
                        fullFilename: 'bms-full-filename.zip',
                        fullFileSize: 1024000,
                        fullFileHash: 'vwev32f23ffewfewf',
                        appFilename: 'app-filename.zip',
                        appFileSize: 1024000,
                        appFileHash: 'acxvjw0f2j09ml',
                    },
                    {
                        firmwareId: 'firmware-id-1',
                        firmwareVersion: 'R01.01.x1',
                        firmwareType: 'HUB',
                        fullFilename: 'hub-full-filename.zip',
                        fullFileSize: 1024000,
                        fullFileHash: 'vwev32f23ffewfewf',
                        appFilename: 'app-filename.zip',
                        appFileSize: 1024000,
                        appFileHash: 'acxvjw0f2j09ml',
                    },
                ],
            },
            requests: [
                {
                    requestId: 1234,
                    totalDeviceCount: 10,
                    successDeviceCount: 1,
                },
            ],
        },
    ],
};
export const firmwareAutoUpdateHistoryData = {
    context: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 9999,
    },
    data: [
        {
            result: '265',
            responseDate: '2025-01-14T06:11:25.667+00:00',
            deviceId: '342611100003A01556',
        },
        {
            result: '265',
            responseDate: '2025-01-14T06:11:25.667+00:00',
            deviceId: '342611100003A01556',
        },
        {
            result: '265',
            responseDate: '2025-01-14T06:11:25.667+00:00',
            deviceId: '342611100003A01556',
        },
        {
            result: '265',
            responseDate: '2025-01-14T06:11:25.667+00:00',
            deviceId: '342611100003A01556',
        },
        {
            result: '265',
            responseDate: '2025-01-14T06:11:25.667+00:00',
            deviceId: '342611100003A01556',
        },
    ],
};
export const firmwareUpdateGroupNameDuplicateData = {
    context: {},
    data: {
        result: 'SUCCESS',
    },
};
export const firmwareUpdateCreateStep2DeviceData = {
    context: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 9999,
    },
    data: [
        {
            siteId: 100001234,
            deviceId: '342611115421D02902',
            emsVersion: 'R060004-AC',
            pcsVersion: '030008',
            bmsVersion: '01000001',
            hubVersion: '010027',
            gemVersion: '01022435',
        },
        {
            siteId: 100002132,
            deviceId: '34261111542AI30003',
            emsVersion: 'R070200-AC',
            pcsVersion: '',
            bmsVersion: '',
            hubVersion: '020210',
            gemVersion: '1265738',
        },
        {
            siteId: 100002133,
            deviceId: '34261111542AI30004',
            emsVersion: 'R070200-AC',
            pcsVersion: '',
            bmsVersion: '',
            hubVersion: '020210',
            gemVersion: '1265738',
        },
    ],
};
export const firmwareUpdatePostApiData = {
    context: {},
    body: {},
};
export const firmwareUpdatePresignedURLData = {
    context: {},
    data: {
        firmwareId: 'firmware-id-1',
        fullFileUrl: 'https://bucket.s3.region.amazonaws.com/path/to/full-firmware',
        appFileUrl: 'https://bucket.s3.region.amazonaws.com/path/to/app-firmware',
        fullFileUrlExpireDate: '2025-01-01T00:00:00Z',
        appFileUrlExpireDate: '2025-01-01T00:00:00Z',
    },
};
