import { DeviceService } from '@hems/service/src/api/device/DeviceService';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class DeviceServiceAdmin extends DeviceService {
    constructor(axios) {
        super(axios);
    }
    deleteDeviceForACSystem = async (siteId) => {
        return this.delete(apiWrapper.managerApi(`/devices/installations/sqa/delete/${siteId}`));
    };
}
