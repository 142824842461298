
import type { PropType } from 'vue';
import { computed, defineComponent, reactive } from 'vue';
import { useStore } from 'vuex';

import BasicInput from '@hems/component/src/inputs/BasicInput.vue';
import LabelInput from '@hems/component/src/inputs/LabelInput.vue';
import TruncateInput from '@hems/component/src/inputs/TruncateInput.vue';
import Selector from '@hems/component/src/selectors/Selector/Selector.vue';
import SettingInfoBlock from '@hems/component/src/tables/SettingInfoBlock.vue';
import SettingInfoItem from '@hems/component/src/tables/SettingInfoItem.vue';
import { Helper, useAppType } from '@hems/util';
import { INPUT_TYPE, BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';

import type { HomeownerAccountInfo } from 'hems/auth/account';

export default defineComponent({
  name: 'UserAccountInfo',
  components: {
    SettingInfoBlock,
    SettingInfoItem,
    BasicInput,
    LabelInput,
    Selector,
    TruncateInput,
  },
  props: {
    data: {
      type: Object as PropType<HomeownerAccountInfo>,
      required: true,
    },
  },
  emits: ['save'],
  setup(props) {
    const store = useStore();
    const { isUserApp } = useAppType();

    const accountInfo = reactive<HomeownerAccountInfo>(props.data);

    const state = reactive<{
      editable: boolean;
      privacyPopup: boolean;
      currentEmail: string | null | undefined;
    }>({
      editable: false,
      privacyPopup: false,
      currentEmail: props.data?.email,
    });

    const telephoneNumberText = computed(() => `${props.data.region ?? ''} ${props.data.telephone ?? ''}`);

    const onEdit = () => {
      state.editable = true;
    };

    const onCancel = (handleReset: () => void) => {
      state.editable = false;
      handleReset();
    };

    // TODO: 추후 계정 정보 변경 기능 추가 시 주석 해제 후 수정
    // const onSave = () => {
    //   emit(
    //     'save',
    //     pickBy(state.data, (v) => !Helper.isNull(v))
    //   );
    //   state.editable = false;
    // };

    const dialSelectOptions = Helper.getDialCodeByCountry().map((item) => ({
      text: `${item.name} ${item.dial_code}`,
      value: item.dial_code,
    }));

    return {
      accountInfo,
      state,
      store,
      onEdit,
      onCancel,
      dialSelectOptions,
      preventKeyEnter: Helper.preventKeyEnter,
      isUserApp,
      INPUT_TYPE,
      BASIC_BUTTON_COLOR_TYPE,
      telephoneNumberText,
    };
  },
});
