
import type { PropType } from 'vue';
import { defineComponent, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Form } from 'vee-validate';
import * as yup from 'yup';

import { withLoading, LabelInput, AutoResizeInput } from '@hems/component';
import PopupWrapper from '@hems/component/src/popup/PopupWrapper.vue';
import { SupportService } from '@hems/service';
import { useMessageBox, useRole } from '@hems/util';
import type { ErrorDetailPopupModeValue } from '@hems/util/src/constant';
import { ERROR_DETAIL_POPUP_MODE } from '@hems/util/src/constant';
import { isNull } from '@hems/util/src/helper/helper';

import type { Role } from 'hems';

import type { ErrorCodeDataDetailOptions, SupportErrorCodeInfo } from 'hems/support';

export default defineComponent({
  name: 'EventDetailPopup',
  components: {
    PopupWrapper,
    Form,
    LabelInput,
    AutoResizeInput,
  },
  props: {
    params: {
      type: String,
      required: true,
    },
    role: {
      type: String as PropType<Role>,
      required: true,
    },
    mode: {
      type: String as PropType<ErrorDetailPopupModeValue>,
      default: ERROR_DETAIL_POPUP_MODE.GUIDE,
    },
    data: {
      type: String,
      default: '',
    },
  },
  emits: ['confirm', 'close'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const supportService = new SupportService(window.axiosInstance.axios);
    const detailForm = ref(null as null | any);
    const { alertMessageBox } = useMessageBox();

    const title = ref(props.params ? t('common.info') : t('common.new'));
    const editable = ref(false);
    const alarmStart = ref(props.data);

    const defaultErrorCodeDetail: ErrorCodeDataDetailOptions = {
      alarmCd: '',
      alarmNm: '',
      deviceTypeCd: '',
      alarmDesc: '',
      alarmCheck: '',
      alarmDetail: '',
      alarmLevel: '',
    };

    const errorCodeDetail = ref<ErrorCodeDataDetailOptions>(defaultErrorCodeDetail);

    const { isAdmin, isDev } = useRole();
    const isAllowedEdit = computed(() => isAdmin || isDev);

    if (!props.params) {
      editable.value = true;
    }

    const defaultValidate = yup
      .string()
      .transform((v, o) => (o === 'null' || o === '' ? null : v))
      .nullable()
      .required();

    const schema = yup.object().shape({
      alarmCd: defaultValidate,
      alarmNm: defaultValidate,
      deviceTypeCd: defaultValidate,
      alarmDesc: defaultValidate,
      alarmCheck: defaultValidate,
      alarmDetail: defaultValidate,
    });

    const getParam = (): Omit<SupportErrorCodeInfo, 'alarmLevel'> => {
      const { alarmCd, alarmNm, deviceTypeCd, alarmDesc, alarmCheck, alarmDetail } = errorCodeDetail.value;

      return {
        alarmCd,
        alarmNm,
        deviceTypeCd,
        alarmDesc,
        alarmCheck,
        alarmDetail,
      };
    };

    const onEdit = () => {
      editable.value = true;
      title.value = t('common.edit');
    };

    const onSave = async () => {
      const params = getParam();

      if (!params) {
        alertMessageBox(t('message.invalid_set_value'));

        return;
      }
      try {
        withLoading(async () => {
          if (props.params) {
            // 수정할 경우
            await supportService.updateEventcode(props.params, params).then(() => emit('confirm'));

            return;
          }
          // 새로 등록할 경우
          await supportService.addEventcode(params).then(() => emit('confirm'));
        })();
      } catch (e) {
        alertMessageBox(t('message.error_apply_setting'));
      }
    };

    const onClose = () => {
      editable.value = false;
      emit('close');
    };

    if (!isNull(props.params)) {
      await supportService.getEventcodeList({ alarmCd: props.params }).then((result: SupportErrorCodeInfo[]) => {
        const detailData = result.find((data: SupportErrorCodeInfo) => data.alarmCd == props.params);

        errorCodeDetail.value = detailData ?? defaultErrorCodeDetail;
      });
    }

    return {
      onClose,
      onSave,
      schema,
      detailForm,
      onEdit,
      isAllowedEdit,
      errorCodeDetail,
      title,
      editable,
      alarmStart,
    };
  },
});
