import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3dfa63b8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "pop_tit" };
const _hoisted_2 = { class: "basic-button-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Selector = _resolveComponent("Selector");
    const _component_SettingInfoItem = _resolveComponent("SettingInfoItem");
    const _component_SettingInfoBlock = _resolveComponent("SettingInfoBlock");
    const _component_BasicButton = _resolveComponent("BasicButton");
    const _component_PopupWrapper = _resolveComponent("PopupWrapper");
    return (_openBlock(), _createBlock(_component_PopupWrapper, { class: "ty_5 annual-report-popup" }, {
        default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('common.annual_energy_report')), 1),
            _createElementVNode("button", {
                type: "button",
                class: "close_btn_2",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
            }, _toDisplayString(_ctx.$t('common.close')), 1),
            _createVNode(_component_SettingInfoBlock, null, {
                default: _withCtx(() => [
                    _createVNode(_component_SettingInfoItem, {
                        title: _ctx.$t('common.year')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                                modelValue: _ctx.year,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.year) = $event)),
                                name: "year",
                                options: _ctx.yearSelectorOptions
                            }, null, 8, ["modelValue", "options"])
                        ]),
                        _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingInfoItem, {
                        title: _ctx.$t('common.file_type')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                                modelValue: _ctx.fileType,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.fileType) = $event)),
                                name: "fileType",
                                options: _ctx.fileTypeSelectorOptions
                            }, null, 8, ["modelValue", "options"])
                        ]),
                        _: 1
                    }, 8, ["title"]),
                    _createVNode(_component_SettingInfoItem, {
                        title: _ctx.$t('common.language')
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_Selector, {
                                modelValue: _ctx.language,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.language) = $event)),
                                name: "language",
                                options: _ctx.languageSelectorOptions
                            }, null, 8, ["modelValue", "options"])
                        ]),
                        _: 1
                    }, 8, ["title"])
                ]),
                _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
                (_ctx.isUserApp)
                    ? (_openBlock(), _createBlock(_component_BasicButton, {
                        key: 0,
                        "color-type": _ctx.BASIC_BUTTON_COLOR_TYPE.SECONDARY,
                        onClick: _ctx.sendEmail
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('common.send_email')), 1)
                        ]),
                        _: 1
                    }, 8, ["color-type", "onClick"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_BasicButton, {
                    "color-type": _ctx.BASIC_BUTTON_COLOR_TYPE.SECONDARY,
                    onClick: _ctx.fileDownload
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.download')), 1)
                    ]),
                    _: 1
                }, 8, ["color-type", "onClick"])
            ])
        ]),
        _: 1
    }));
}
