import { createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88b84960"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch" }
const _hoisted_2 = ["checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessageLabel = _resolveComponent("ErrorMessageLabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("input", {
        type: "checkbox",
        checked: _ctx.isChecked,
        disabled: _ctx.readonly || _ctx.disabled,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, null, 40, _hoisted_2),
      _createElementVNode("span", {
        class: _normalizeClass(["slider", { 'slider-disabled': _ctx.readonly || _ctx.disabled }]),
        tabindex: 0,
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onKeyup && _ctx.onKeyup(...args)), ["enter"]))
      }, null, 34)
    ]),
    _createVNode(_component_ErrorMessageLabel, { "error-message": _ctx.errorMessage }, null, 8, ["error-message"])
  ], 64))
}