import { defineComponent } from 'vue';
import { SELECTION_CONTROL_TAB_SHAPE, SELECTION_CONTROL_TAB_SIZE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'SelectionControlTab',
    props: {
        selectedTabValue: {
            type: String,
            required: true,
        },
        tabList: {
            type: Array,
            required: true,
        },
        tabSize: {
            type: String,
            default: SELECTION_CONTROL_TAB_SIZE.LARGE,
        },
        tabShape: {
            type: String,
            default: SELECTION_CONTROL_TAB_SHAPE.SOLID,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['change'],
    setup(_props, { emit }) {
        const handleClickTab = (tabValue) => {
            emit('change', tabValue);
        };
        return {
            handleClickTab,
        };
    },
});
