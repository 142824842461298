import { computed } from 'vue';
import { useStore } from 'vuex';
import { parseJwt } from '@hems/util/src/helper/authHelper';
export const useUserInfo = () => {
    const store = useStore();
    const userId = computed(() => store.state.user.userId);
    const idToken = computed(() => store.state.user.idToken);
    const idTokenJwtObject = parseJwt(idToken.value ?? '');
    const siteId = computed(() => Number(idTokenJwtObject?.site_id) || null);
    return {
        siteId,
        userId,
        idTokenJwtObject,
    };
};
