
import type { PropType } from 'vue';
import { defineComponent, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { LabelInput, Searchbox, BasicButton } from '@hems/component';
import type { MonitoringListTabType } from '@hems/util/src/constant';
import { MONITORING_LIST_TAB } from '@hems/util/src/constant';

import type { Role } from 'hems';

import type { MonitoringListSearchOptions } from 'hems/device';

export default defineComponent({
  name: 'MonitoringSearchBox',
  components: {
    LabelInput,
    Searchbox,
    BasicButton,
  },
  props: {
    condition: {
      type: Object as PropType<Partial<MonitoringListSearchOptions>>,
      required: true,
    },
    role: {
      type: String as PropType<Role>,
      required: true,
    },
    monitoringListTabName: {
      type: String as PropType<MonitoringListTabType>,
      required: true,
    },
  },
  emits: ['search'],
  async setup(props, { emit }) {
    const { t } = useI18n();

    const tabName = computed(() => props.monitoringListTabName);

    const isEMSList = computed(() => tabName.value === MONITORING_LIST_TAB.DEVICE);

    const state = reactive({
      hide: true,
      condition: { ...props.condition },
    });

    const onSearch = () => {
      state.condition.searchFor = state.condition.searchFor?.trim();

      const { searchFor, emsVer, pcsVer } = state.condition;
      emit('search', {
        searchFor,
        emsVer,
        pcsVer,
      });
    };

    return {
      state,
      onSearch,
      placeholder: computed(() =>
        !['user', 'installer'].includes(props.role)
          ? t('message.enter_search_word').concat(
              ` (${[t('device.serial_no'), t('common.site_id'), t('account.owner_name')].join(', ')})`
            )
          : t('message.enter_search_word').concat(
              ` (${[t('device.serial_no'), t('common.site_id'), t('account.owner_name')].join(', ')})`
            )
      ),
      tabName,
      isEMSList,
    };
  },
});
