
import type { PropType } from 'vue';
import { defineComponent, ref, onMounted } from 'vue';

import Image2 from '@hems/component/src/image/Image2.vue';

type TooltipPositionClass =
  | 'top_start'
  | 'top_center'
  | 'top_end'
  | 'bottom_start'
  | 'bottom_center'
  | 'bottom_end'
  | 'left'
  | 'right';

type TooltipStyles = {
  left?: number;
  right?: number;
  arrowLeft?: number;
  arrowRight?: number;
};

export default defineComponent({
  name: 'Tooltip',
  components: {
    Image2,
  },
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
    tooltipPosition: {
      type: String as PropType<TooltipPositionClass>,
      required: true,
    },
    hasTooltipIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const tooltipTextWrapRef = ref<HTMLDivElement | null>(null);
    const tooltipArrowRef = ref<HTMLDivElement | null>(null);

    const TOOLTIP_ICON_WIDTH = 16;

    const setTooltipPosition = () => {
      if (tooltipArrowRef.value && tooltipTextWrapRef.value) {
        const tooltipIconHalfWidth = TOOLTIP_ICON_WIDTH / 2;

        const isCenter = props.tooltipPosition.includes('center');
        const isStart = props.tooltipPosition.includes('start');
        const isEnd = props.tooltipPosition.includes('end');
        const isTop = props.tooltipPosition.includes('top');

        const setTooltipStyles = ({ left, right, arrowLeft, arrowRight }: TooltipStyles) => {
          if (tooltipTextWrapRef.value) {
            tooltipTextWrapRef.value.style.left = left ? `${left}px` : '';
            tooltipTextWrapRef.value.style.right = right ? `${right}px` : '';
          }
          if (tooltipArrowRef.value) {
            tooltipArrowRef.value.style.left = arrowLeft ? `${arrowLeft}px` : '';
            tooltipArrowRef.value.style.right = arrowRight ? `${arrowRight}px` : '';
          }
        };

        if (isCenter) {
          setTooltipStyles({
            left: -(tooltipTextWrapRef.value.offsetWidth / 2 - tooltipIconHalfWidth),
            arrowRight: isTop
              ? tooltipTextWrapRef.value.offsetWidth / 2 - tooltipIconHalfWidth
              : tooltipTextWrapRef.value.offsetWidth / 2 + tooltipIconHalfWidth,
          });
        } else if (isStart) {
          setTooltipStyles({
            left: -tooltipIconHalfWidth,
            arrowLeft: isTop ? tooltipIconHalfWidth * 3 : tooltipIconHalfWidth,
          });
        } else if (isEnd) {
          setTooltipStyles({
            right: -tooltipIconHalfWidth,
            arrowRight: isTop ? tooltipIconHalfWidth : tooltipIconHalfWidth * 3,
          });
        }
      }
    };

    onMounted(() => {
      setTooltipPosition();
    });

    return {
      tooltipTextWrapRef,
      tooltipArrowRef,
    };
  },
});
