import { vModelRadio as _vModelRadio, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-534cc67d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fm_radio" }
const _hoisted_2 = ["name", "value", "disabled"]
const _hoisted_3 = ["data-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _withDirectives(_createElementVNode("input", _mergeProps({
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.value) = $event)),
      type: "radio",
      name: _ctx.name
    }, _ctx.$attrs, {
      value: _ctx.transformValue(_ctx.value),
      disabled: _ctx.readonly,
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }), null, 16, _hoisted_2), [
      [_vModelRadio, _ctx.state.value]
    ]),
    _createElementVNode("span", {
      class: "in",
      tabindex: "0",
      "data-value": _ctx.transformValue(_ctx.value),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onKeyUp && _ctx.onKeyUp(...args)), ["enter"]))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 40, _hoisted_3)
  ]))
}