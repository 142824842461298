
import type { PropType } from 'vue';
import { computed, defineComponent, watch } from 'vue';

import { useField } from 'vee-validate';

import ErrorMessageLabel from '@hems/component/src/labels/ErrorMessageLabel.vue';
export type ValueType = string | number | boolean | null;
export type ToggleTuple = [ValueType, string];

export default defineComponent({
  name: 'ToggleInput',
  components: {
    ErrorMessageLabel,
  },
  props: {
    name: String,
    modelValue: {
      type: [String, Number, Boolean] as PropType<ValueType>,
      default: false,
      required: true,
    },
    toggle: {
      // @TODO props 형식 변경 (사용부도 전부 바꿔주어야함)
      type: Array as PropType<ToggleTuple[]>,
      default: () => [
        [true, 'Enable'],
        [false, 'Disable'],
      ],
    },
    readonly: Boolean,
    disabled: Boolean,
    beforeChange: Function as PropType<(value: ValueType, beforeValue: ValueType) => boolean | Promise<boolean>>,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { value: inputValue, errorMessage } = useField(props.name || 'toggleValue', undefined, {
      initialValue: props.modelValue,
      type: typeof props.toggle[0][0],
    });

    const isChecked = computed(() => inputValue.value === props.toggle[0][0]);
    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue !== inputValue.value) inputValue.value = props.modelValue;
      }
    );

    const onChange = () => {
      if (props.beforeChange) {
        const val = !isChecked.value ? props.toggle[0][0] : props.toggle[1][0];
        if (props.beforeChange(val, props.modelValue)) {
          emit('update:modelValue', val);
        }
      } else {
        emit('update:modelValue', !isChecked.value ? props.toggle[0][0] : props.toggle[1][0]);
      }
    };

    const onKeyup = (e: KeyboardEvent) => {
      emit('update:modelValue', !isChecked.value ? props.toggle[0][0] : props.toggle[1][0]);
    };

    return {
      isChecked,
      onChange,
      errorMessage,
      onKeyup,
    };
  },
});
