/** Copyright © 2025 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { rest } from 'msw';
import { apiWrapper } from '@hems/service/src/util/helper';
import { FIRMWARE_UPDATE_SORT_COLUMN } from '@hems/util/src/constant/firmwareUpdate';
import { firmwareUpdateGroupData, firmwareAutoUpdateHistoryData, firmwareUpdateGroupNameDuplicateData, firmwareUpdateCreateStep2DeviceData, firmwareUpdatePostApiData, firmwareUpdatePresignedURLData, } from '@hems/util/src/mocks/data/controlData';
import { getPagingData, getSortingData } from '@hems/util/src/mocks/util';
const API_BASE_URL = process.env.VUE_APP_API_URL;
const CONTROL_API_URL = `${API_BASE_URL}${apiWrapper.managerApiV1('')}`;
const firmwareUpdateListHandler = rest.get(`${CONTROL_API_URL}/firmwares/update-groups?updateType=MANUAL`, (req, res, ctx) => {
    const pageNum = req.url.searchParams.get('pageNumber') || 1;
    const listSize = req.url.searchParams.get('pageSize') || 10;
    const sortState = req.url.searchParams.get('sort') || FIRMWARE_UPDATE_SORT_COLUMN.REQUEST_DATE_ASC;
    const sortingFirmwareUpdateGroupData = getSortingData(firmwareUpdateGroupData.data, sortState);
    const pagingFirmwareUpdateGroupData = getPagingData(sortingFirmwareUpdateGroupData, Number(pageNum), Number(listSize));
    const response = {
        context: firmwareUpdateGroupData.context,
        data: pagingFirmwareUpdateGroupData,
    };
    return res(ctx.status(200), ctx.json(response));
});
const firmwareAutoUpdateListHandler = rest.get(`${CONTROL_API_URL}/firmwares/update-groups?updateType=AUTO`, (req, res, ctx) => {
    const response = { context: firmwareUpdateGroupData.context, data: firmwareUpdateGroupData.data };
    return res(ctx.status(200), ctx.json(response));
});
const firmwareAutoUpdateHistoryListHandler = rest.get(`${CONTROL_API_URL}/firmwares/update-groups/:updateGroupId/devices`, (req, res, ctx) => {
    const response = { context: firmwareAutoUpdateHistoryData.context, data: firmwareAutoUpdateHistoryData.data };
    return res(ctx.status(200), ctx.json(response));
});
export const firmwareUpdateGroupNameDuplicateHandler = rest.post(`${CONTROL_API_URL}/firmwares/update-groups/id/validate`, async (req, res, ctx) => {
    const response = {
        context: firmwareUpdateGroupNameDuplicateData.context,
        data: firmwareUpdateGroupNameDuplicateData.data,
    };
    return res(ctx.status(200), ctx.json(response));
});
export const getFirmwareUpdateCreateStep2DeviceHandler = rest.get(`${CONTROL_API_URL}/firmwares/devices`, async (req, res, ctx) => {
    const pageNum = req.url.searchParams.get('pageNumber') || 1;
    const listSize = req.url.searchParams.get('pageSize') || 10;
    const sortState = req.url.searchParams.get('sort') || 'siteId';
    const sortingFirmwareUpdateGroupData = getSortingData(firmwareUpdateCreateStep2DeviceData.data, sortState);
    const pagingFirmwareUpdateGroupData = getPagingData(sortingFirmwareUpdateGroupData, Number(pageNum), Number(listSize));
    const response = { context: firmwareUpdateCreateStep2DeviceData.context, data: pagingFirmwareUpdateGroupData };
    return res(ctx.status(200), ctx.json(response));
});
/** 펌웨어 정보 생성 */
export const firmwareCreateHandler = rest.post(`${CONTROL_API_URL}/firmwares`, async (req, res, ctx) => {
    const requestBody = await req.json();
    const response = { context: firmwareUpdatePostApiData.context, data: firmwareUpdatePostApiData.body };
    return res(ctx.status(200), ctx.json(response));
});
/** 펌웨어 업데이트 File Presigned URL 정보 */
const getFirmwareUpdateFilePresignedURLHandler = rest.get(`${CONTROL_API_URL}/firmwares/:firmwareId/storage`, (req, res, ctx) => {
    const { firmwareId } = req.params;
    const response = { context: firmwareUpdatePresignedURLData.context, data: firmwareUpdatePresignedURLData.data };
    return res(ctx.status(200), ctx.json(response));
});
/** 펌웨어 파일 S3에 등록 */
export const putRemoteUpdateFileHandler = rest.put('https://bucket.s3.region.amazonaws.com/path/to/full-firmware', async (req, res, ctx) => {
    const requestBody = await req.arrayBuffer();
    const response = { context: firmwareUpdatePostApiData.context, data: firmwareUpdatePostApiData.body };
    return res(ctx.status(200), ctx.json(response));
});
/** 펌웨어 업데이트 Set 생성 */
export const createFirmwareUpdateSetHandler = rest.post(`${CONTROL_API_URL}/firmware-sets`, async (req, res, ctx) => {
    const requestBody = await req.json();
    const response = { context: firmwareUpdatePostApiData.context, data: firmwareUpdatePostApiData.body };
    return res(ctx.status(200), ctx.json(response));
});
/** 펌웨어 업데이트 그룹 정보 생성 */
export const createFirmwareUpdateGroupInfoHandler = rest.post(`${CONTROL_API_URL}/firmwares/update-groups`, async (req, res, ctx) => {
    const requestBody = await req.json();
    const response = { context: firmwareUpdatePostApiData.context, data: firmwareUpdatePostApiData.body };
    return res(ctx.status(200), ctx.json(response));
});
/** 펌웨어 업데이트 Manual 일떄, 펌웨어 등록 및 siteId 등록 */
export const pushManualFirmwareUpdateHandler = rest.post(`${CONTROL_API_URL}/firmwares/update-groups/:updateGroupId/update`, async (req, res, ctx) => {
    const requestBody = await req.json();
    const { updateGroupId } = req.params;
    const response = { context: firmwareUpdatePostApiData.context, data: firmwareUpdatePostApiData.body };
    return res(ctx.status(200), ctx.json(response));
});
/** 펌웨어 업데이트 Manual 일떄, 펌웨어 등록 및 siteId 등록 */
export const pushAutoFirmwareUpdateHandler = rest.post(`${CONTROL_API_URL}/firmwares/update-groups/:updateGroupId/excluded-sites`, async (req, res, ctx) => {
    const requestBody = await req.json();
    const { updateGroupId } = req.params;
    const response = { context: firmwareUpdatePostApiData.context, data: firmwareUpdatePostApiData.body };
    return res(ctx.status(200), ctx.json(response));
});
/** Auto 업데이트 정보 삭제 */
export const deleteFirmwareAutoUpdateHandler = rest.delete(`${CONTROL_API_URL}/firmwares/update-groups/:updateGroupId`, async (req, res, ctx) => {
    const { updateGroupId } = req.params;
    const response = {};
    return res(ctx.status(200), ctx.json(response));
});
export const controlHandlers = [
    firmwareUpdateListHandler,
    firmwareAutoUpdateListHandler,
    firmwareAutoUpdateHistoryListHandler,
    firmwareUpdateGroupNameDuplicateHandler,
    getFirmwareUpdateCreateStep2DeviceHandler,
    firmwareCreateHandler,
    createFirmwareUpdateSetHandler,
    createFirmwareUpdateGroupInfoHandler,
    pushManualFirmwareUpdateHandler,
    pushAutoFirmwareUpdateHandler,
    getFirmwareUpdateFilePresignedURLHandler,
    putRemoteUpdateFileHandler,
    deleteFirmwareAutoUpdateHandler,
];
