export const BASIC_MODAL = {
    ALERT: 'alert',
    CONFIRM: 'confirm',
    REMOVE: 'remove',
};
export const MESSAGE_MODAL_TYPE = {
    /** 경고 메시지 */
    WARNING: 'WARNING',
    /** 위험한 동작일 때 확인 모달 (ex 삭제) */
    DANGER_CONFIRMATION: 'DANGER_CONFIRMATION',
    /** 일반 확인 모달 */
    CONFIRMATION: 'CONFIRMATION',
};
export const CONTENT_MODAL_SIZE_TYPE = {
    /** width: 392px */
    SMALL: 'SMALL',
    /** width: 432px */
    MEDIUM: 'MEDIUM',
    /** width: 628px */
    LARGE: 'LARGE',
    /** width: 960px */
    XLARGE: 'XLARGE',
};
export const CONTENT_TEXT_ALIGN_TYPE = {
    /** 내부 컨텐츠 좌측 정렬 */
    LEFT: 'left',
    /** 내부 컨텐츠 중앙 정렬 */
    CENTER: 'center',
};
