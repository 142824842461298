import type { AccountInfo } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

import type { TenantType } from '@hems/util/src/constant/msal';
import { TENANT } from '@hems/util/src/constant/msal';
import {
  getHomeownerConfig,
  getPartnersConfig,
  getTenantByTenantId,
  getLoginRequestParams,
} from '@hems/util/src/helper/authHelper';

import type { AuthToken } from 'hems/auth';

const msalInstanceHomeowner = new PublicClientApplication(getHomeownerConfig());

const msalInstancePartners = new PublicClientApplication(getPartnersConfig());

const getMSALInstance = (tenant: TenantType): PublicClientApplication =>
  tenant === TENANT.HOMEOWNER ? msalInstanceHomeowner : msalInstancePartners;

export const acquireTokenSilent = async (account: AccountInfo): Promise<AuthToken> => {
  if (!account.idTokenClaims?.tid) {
    return {
      accessToken: null,
      idToken: null,
    };
  }

  const tenant = getTenantByTenantId(account.idTokenClaims.tid);

  const { accessToken, idToken } = await getMSALInstance(tenant).acquireTokenSilent({
    ...getLoginRequestParams(tenant),
    account,
  });

  return {
    accessToken,
    idToken,
  };
};

export const logoutRedirectByAccountInfo = async (account: AccountInfo) => {
  if (!account.idTokenClaims?.tid) {
    return;
  }

  const tenant = getTenantByTenantId(account.idTokenClaims.tid);

  await getMSALInstance(tenant).logoutRedirect({
    postLogoutRedirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI || '',
  });
};
