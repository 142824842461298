import { defineComponent, onMounted, ref, watch } from 'vue';
import { gsap } from 'gsap';
import { Image2 } from '@hems/component';
export default defineComponent({
    name: 'HiddenSearchbox',
    components: {
        Image2,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const div = ref(null);
        const isShow = ref(props.show);
        const toggleSearchBox = () => {
            isShow.value = !isShow.value;
        };
        watch(() => isShow.value, (show) => {
            if (show) {
                if (!div.value)
                    return;
                gsap
                    .to([div.value], {
                    height: 'auto',
                    duration: 0.15,
                })
                    .then(() => {
                    if (!div.value)
                        return;
                    div.value.style.overflow = 'visible';
                });
            }
            else {
                if (!div.value)
                    return;
                div.value.style.overflow = 'hidden';
                gsap
                    .to([div.value], {
                    height: '0',
                    duration: 0.15,
                })
                    .then(() => {
                    if (!div.value)
                        return;
                });
            }
        });
        onMounted(() => {
            if (!div.value)
                return;
            div.value.style.height = isShow.value ? 'auto' : '0';
        });
        return {
            div,
            toggleSearchBox,
            isShow,
        };
    },
});
