import { Service } from '@hems/service/src/api/Service';
import { apiWrapper } from '@hems/service/src/util/helper';
export default class AuthService extends Service {
    constructor(axios) {
        super(axios);
    }
    async getRealtimeApiAuthToken() {
        return await this.get(apiWrapper.daqApi('/v1/auth'));
    }
    exchangeEntraIdTokenToAccessToken = async (entraIdToken) => {
        const { data } = await this.post(apiWrapper.authenticationApi('/exchange-tokens'), {}, {
            headers: {
                Authorization: `Bearer ${entraIdToken}`,
            },
        });
        return data;
    };
    getAuthTypeAndRole = async (userId, accessToken) => {
        const { data } = await this.get(apiWrapper.managerApiV1(`/users/${userId}`), {}, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return data;
    };
}
