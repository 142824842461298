
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import isNil from 'lodash-es/isNil';

import { PopupWrapper, SettingInfoBlock, SettingInfoItem, Selector, BasicButton } from '@hems/component';
import { DownloadServiceUser, UserReportService } from '@hems/service';
import { useAlertModal, useAppType, useLanguage, useLoading, useSiteId, useSiteMasterDeviceInfo } from '@hems/util';
import type { LanguageValue } from '@hems/util/src/constant';
import { LANGUAGE, BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
import { STATUS_CODE } from '@hems/util/src/exception/exception';
import { today } from '@hems/util/src/helper/dateHelper';
import { downloadFile } from '@hems/util/src/helper/helper';

import type { SelectorOption } from 'hems';

import type { ValueType } from 'hems/common/utils';

const FILE_NAME = 'Report';

const FILE_TYPE = {
  PDF: 'PDF',
  EXCEL: 'EXCEL',
} as const;

type FileTypeValue = ValueType<typeof FILE_TYPE>;

export default defineComponent({
  name: 'AnnualReportPopup',
  components: {
    PopupWrapper,
    SettingInfoBlock,
    SettingInfoItem,
    Selector,
    BasicButton,
  },
  emits: ['close'],
  setup(_props, { emit }) {
    const { t } = useI18n();
    const downloadService = new DownloadServiceUser(window.axiosInstance.axios);
    const userReportService = new UserReportService(window.axiosInstance.axios);

    const fileTypeSelectorOptions = [
      { text: FILE_TYPE.PDF, value: FILE_TYPE.PDF },
      { text: FILE_TYPE.EXCEL, value: FILE_TYPE.EXCEL },
    ];

    const { siteId } = useSiteId();
    const { siteTimezoneId } = useSiteMasterDeviceInfo();

    const { languageOptionsForManagement } = useLanguage();

    const languageSelectorOptions = languageOptionsForManagement.value.filter(({ value }) => value === LANGUAGE.EN);

    const { startLoading, stopLoading } = useLoading();

    const year = ref('');
    const fileType = ref<FileTypeValue>(FILE_TYPE.PDF);
    const language = ref<LanguageValue>(LANGUAGE.EN);

    const yearSelectorOptions = ref<SelectorOption[]>([]);

    const params = computed(() => ({
      year: year.value,
      fileType: fileType.value,
      langCd: language.value,
    }));

    const closePopup = (e: Event) => {
      e.preventDefault();
      emit('close');
    };

    const loadSelectorOptions = async () => {
      let currentYear = '';
      let lastYear = '';
      try {
        const serverTime = await userReportService.getServerTime(siteTimezoneId.value);
        currentYear = serverTime.substring(0, 4);
      } catch (e) {
        console.error(e);
        currentYear = String(today().getFullYear());
      } finally {
        lastYear = String(Number(currentYear) - 1);
        yearSelectorOptions.value = [
          { text: lastYear, value: lastYear },
          { text: currentYear, value: currentYear },
        ];
        year.value = currentYear;
      }
    };

    const alertModal = useAlertModal({ content: t('message.data_not_exist') });

    const fileDownload = async () => {
      if (isNil(siteId.value)) return;

      try {
        startLoading();

        const annualReportBlobFile = await downloadService.annualReportFileDownload(String(siteId.value), params.value);

        if (!annualReportBlobFile.size) {
          alertModal.open();

          return;
        }

        const fileExtension = fileType.value === FILE_TYPE.EXCEL ? 'xlsx' : fileType.value.toLowerCase();
        downloadFile(annualReportBlobFile, FILE_NAME, fileExtension);
      } catch (e) {
        alertModal.patchOptions({ attrs: { content: t('message.error_download') } });
        alertModal.open();
      } finally {
        stopLoading();
      }
    };

    const { isUserApp } = useAppType();

    const sendEmail = async () => {
      if (isNil(siteId.value)) return;

      try {
        startLoading();

        const { status } = await downloadService.annualReportSendEmail(String(siteId.value), params.value);

        if (status === STATUS_CODE.NO_CONTENT) {
          alertModal.open();

          return;
        }

        alertModal.patchOptions({ attrs: { content: t('message.email_sent') } });
        alertModal.open();

        return;
      } catch (e) {
        alertModal.patchOptions({ attrs: { content: t('message.email_delivery_failed') } });
        alertModal.open();
      } finally {
        stopLoading();
      }
    };

    loadSelectorOptions();

    return {
      year,
      fileType,
      language,
      yearSelectorOptions,
      fileTypeSelectorOptions,
      languageSelectorOptions,
      BASIC_BUTTON_COLOR_TYPE,
      closePopup,
      fileDownload,
      sendEmail,
      isUserApp,
    };
  },
});
