
import type { PropType } from 'vue';
import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  getChartTemplateAndData,
  getGraphDataByGroupCodesACSystem,
  getXAxisCategories,
  updateChartData,
} from '@hems/container/src/forms/device/statistics/_shared';
import { getGraphMinuteConfig } from '@hems/container/src/forms/device/statistics/_shared/filterConfig';
import IntegratedDeviceLineGraph from '@hems/container/src/highcharts/spline/IntegratedDeviceLineGraph.vue';
import { useACDeviceType, useRole, useSiteInfo } from '@hems/util';
import {
  AC_COMBINER_STATISTICS_LIST,
  BATTERY_STATISTICS_LIST,
  ENERGY_MINUTE_STATISTICS_LIST,
  GRAPH_GROUP_CODE,
  UNIT,
  GRID_STATISTICS_LIST,
  POWER_STATISTICS_LIST,
} from '@hems/util/src/constant';
import type { GraphGroupCodeValue } from '@hems/util/src/constant';

import type {
  FilterGroupMinute,
  GroupCode,
  SimpleSeries,
  StatisticsDataACSystem,
  StatisticsMinuteData,
  SuperFilterItem,
} from 'hems/device/statistics/common';

export default defineComponent({
  name: 'StatisticsMinuteIntegratedContainer',
  components: {
    IntegratedDeviceLineGraph,
  },
  props: {
    data: {
      type: Array as PropType<StatisticsDataACSystem[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();
    const xAxisCategories = ref<string[]>([]);
    const graphData = ref<SimpleSeries[]>([]);

    const { hasACCombiner, isSolarConfig } = useACDeviceType();

    const isShowACCombinerGraph = hasACCombiner;

    const { isAdminGroup, isInstaller } = useRole();

    const { ESSCount } = useSiteInfo();

    const { chartDataTemplate, chartData } = getChartTemplateAndData<FilterGroupMinute>(
      getGraphMinuteConfig(isSolarConfig, isInstaller, ESSCount),
      t
    );

    const getFilterList = (filterGroup: GroupCode) => chartDataTemplate[filterGroup]?.filterList ?? [];

    const getFilterGroupName = (filterGroup: GroupCode) => chartDataTemplate[filterGroup]?.filterGroupName ?? '';

    const integratedChartFilterMap: {
      [K in GraphGroupCodeValue]: {
        title: string;
        filterList: (SuperFilterItem & {
          code: keyof StatisticsMinuteData;
        })[];
        unit?: string;
      } | null;
    } = {
      [GRAPH_GROUP_CODE.POWER]: {
        title: getFilterGroupName(GRAPH_GROUP_CODE.POWER),
        filterList: getFilterList(GRAPH_GROUP_CODE.POWER),
        unit: UNIT.WATT,
      },
      [GRAPH_GROUP_CODE.AC_COMBINER]:
        isShowACCombinerGraph && isAdminGroup
          ? {
              title: getFilterGroupName(GRAPH_GROUP_CODE.AC_COMBINER),
              filterList: getFilterList(GRAPH_GROUP_CODE.AC_COMBINER),
            }
          : null,
      [GRAPH_GROUP_CODE.BATTERY]: !isSolarConfig
        ? {
            title: getFilterGroupName(GRAPH_GROUP_CODE.BATTERY),
            filterList: getFilterList(GRAPH_GROUP_CODE.BATTERY),
          }
        : null,
      [GRAPH_GROUP_CODE.GRID]: {
        title: getFilterGroupName(GRAPH_GROUP_CODE.GRID),
        filterList: getFilterList(GRAPH_GROUP_CODE.GRID),
      },
      [GRAPH_GROUP_CODE.ENERGY]: {
        title: getFilterGroupName(GRAPH_GROUP_CODE.ENERGY),
        filterList: getFilterList(GRAPH_GROUP_CODE.ENERGY),
        unit: UNIT.WATT_HOUR,
      },
    };

    watch(
      () => props.data,
      (data) => {
        xAxisCategories.value = getXAxisCategories(data);
        updateChartData(chartData, data, {
          power: POWER_STATISTICS_LIST,
          energy: ENERGY_MINUTE_STATISTICS_LIST,
          ACCombiner: AC_COMBINER_STATISTICS_LIST,
          battery: BATTERY_STATISTICS_LIST,
          grid: GRID_STATISTICS_LIST,
        });
        const { POWER, AC_COMBINER, GRID, BATTERY, ENERGY } = GRAPH_GROUP_CODE;
        if (isSolarConfig) {
          graphData.value = getGraphDataByGroupCodesACSystem([POWER, AC_COMBINER, GRID, ENERGY], chartData);
        } else if (isShowACCombinerGraph) {
          graphData.value = getGraphDataByGroupCodesACSystem([POWER, AC_COMBINER, BATTERY, GRID, ENERGY], chartData);
        } else {
          graphData.value = getGraphDataByGroupCodesACSystem([POWER, BATTERY, GRID, ENERGY], chartData);
        }
      }
    );

    return {
      graphData,
      xAxisCategories,
      chartDataTemplate,
      integratedChartFilterMap,
    };
  },
});
