import { defineComponent } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { Image2, BasicButton } from '@hems/component';
import { useLoading } from '@hems/util';
import { BASIC_MODAL, CONTENT_TEXT_ALIGN_TYPE, BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'BasicModal',
    components: { VueFinalModal, Image2, BasicButton },
    props: {
        type: {
            type: String,
            default: BASIC_MODAL.ALERT,
        },
        title: {
            type: String,
            default: '',
        },
        content: {
            type: String,
            default: '',
        },
        customButtonText: {
            type: String,
            default: '',
        },
        contentAlign: {
            type: String,
            default: CONTENT_TEXT_ALIGN_TYPE.CENTER,
        },
        confirmButtonText: {
            type: String,
            default: '',
        },
    },
    emits: ['confirm', 'close'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const { resetLoading } = useLoading();
        resetLoading();
        const closeButtonTextCode = props.type === BASIC_MODAL.ALERT ? t('common.close') : t('common.cancel');
        const closeButtonColorType = props.type === BASIC_MODAL.ALERT ? BASIC_BUTTON_COLOR_TYPE.PRIMARY : BASIC_BUTTON_COLOR_TYPE.SECONDARY;
        const onClose = (close) => {
            emit('close');
            close();
        };
        return {
            closeButtonTextCode,
            onClose,
            BASIC_MODAL,
            closeButtonColorType,
        };
    },
});
