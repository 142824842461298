
import type { PropType } from 'vue';
import { defineComponent, reactive, watch } from 'vue';

import type { SelectorValue } from 'hems';

export default defineComponent({
  name: 'BasicRadio',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean] as PropType<SelectorValue>,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean] as PropType<SelectorValue>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    valueType: {
      type: String as PropType<'string' | 'number' | 'boolean'>,
      default: 'string',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      value: transformValue(props.modelValue),
    });

    watch(
      () => props.modelValue,
      () => {
        const value = transformValue(props.modelValue);
        if (state.value !== value) state.value = value;
      }
    );

    function transformValue(value?: string | number | boolean | null) {
      if (value === undefined || value === null) return null;

      if (props.valueType === 'boolean') return Boolean(value);
      if (props.valueType === 'number') return Number(value);

      return value;
    }

    function onChange(e: Event) {
      const target = e.target as HTMLInputElement;
      emit('update:modelValue', transformValue(target.value));
    }

    const onKeyUp = (e: KeyboardEvent) => {
      if (!(e.target instanceof HTMLElement)) {
        return;
      }

      const value = e.target.dataset.value;

      emit('update:modelValue', value);
    };

    return {
      state,
      onChange,
      transformValue,
      onKeyUp,
    };
  },
});
