/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { DurationInputArg1, DurationInputArg2, MomentSetObject } from 'moment';
import moment from 'moment-timezone';

import type { DateUnitValue, DaysOfWeekValues, LanguageValue } from '@hems/util/src/constant';
import {
  DASH_DATE_FORMAT,
  DAYS_OF_WEEK,
  DEFAULT_LANGUAGE,
  ENV_LOCALE,
  GRAPH_INTERVAL,
  GRAPH_TERM_UNIT,
  INTL_LOCALE_MAP,
  LANGUAGE,
  NORMAL_DATE_FORMAT,
  NORMAL_YEAR_FORMAT,
  ONE_DAY_VALUE_IN_MILLISECONDS,
  ONE_HOUR_VALUE_IN_MINUTE,
  START_TIME_VALUE,
  UTC_TIME_ZONE,
} from '@hems/util/src/constant';
import { MAPPER_PERIOD_DATA } from '@hems/util/src/constant/mapper';
import { getEnvLocale, getLangCd, padLeft, isNull } from '@hems/util/src/helper/helper';
import { isLocaleValueType } from '@hems/util/src/helper/tsguardHelper';

import type { DateFormatConfig, EnvLocale, LangCd, Period, TermUnit, TermUnitAll } from 'hems';

import type { Interval } from 'hems/report';

type CalendarRangeValue = { start: Date; end: Date };

/** 기간 조회 - Search Box의 기간 설정 용도 */
export const getDiffDate = (start: string, end?: string): number => {
  if (!end) {
    const now = new Date();
    end = String(now.getFullYear()) + String(now.getMonth() + 1) + String(now.getDate());
  }
  const year1 = Number(start.substring(0, 4));
  const month1 = Number(start.substring(4, 2)) - 1; // 1월=0,12월=11
  const day1 = Number(start.substring(6, 2));
  const hour1 = Number(start.substring(8, 2));
  const min1 = Number(start.substring(10, 2));

  const year2 = Number(end.substring(0, 4));
  const month2 = Number(end.substring(4, 2)) - 1;
  const day2 = Number(end.substring(6, 2));
  const hour2 = Number(end.substring(8, 2));
  const min2 = Number(end.substring(10, 2));

  const startDate = new Date(year1, month1, day1, hour1, min1);
  const endDate = new Date(year2, month2, day2, hour2, min2);
  const day = ONE_DAY_VALUE_IN_MILLISECONDS;
  const value = (endDate.getTime() - startDate.getTime()) / day;

  return parseInt(String(value), 10);
};

/** 일 기준 기간 조회 - 유효성 체크 용도 */
export const getRangeDate = (value: CalendarRangeValue): number => {
  const startValue = moment(value.start).valueOf();
  const endValue = moment(value.end).valueOf();

  return (endValue - startValue) / ONE_DAY_VALUE_IN_MILLISECONDS;
};

/** 월 기준 기간 조회 - 유효성 체크 용도 */
export const getRangeMonth = (value: CalendarRangeValue): number => {
  const startDate = moment(value.start);
  const endDate = moment(value.end);
  const startValue = startDate.year() * 12 + startDate.month();
  const endValue = endDate.year() * 12 + endDate.month();

  return endValue - startValue;
};

/** 날짜 스트링 조회 - 'YYYYMMDD' | 'YYYYMMDDHH' */
export const getDateToString = (date: Date, type?: string): string => {
  return (
    date.getFullYear() +
    padLeft(date.getMonth() + 1, 2) +
    padLeft(date.getDate(), 2) +
    (type === GRAPH_TERM_UNIT.HOUR ? padLeft(date.getHours(), 2) : '')
  );
};

/** 날짜 포맷 조회 - 그리드(테이블) 용도 */
export const getGridDateFormat = (termUnit: TermUnit, langCd: LangCd): string => {
  if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM/DD';
      default:
        return 'DD/MM/YYYY';
    }
  } else if (termUnit === GRAPH_TERM_UNIT.HOUR) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM/DD HH:mm:ss';
      default:
        return 'DD/MM/YYYY HH:mm:ss';
    }
  }

  return 'DD/MM/YYYY';
};

/** 날짜 포맷 조회 - 캘린더 용도 */
export const getCalendarDateFormat = (termUnit: TermUnitAll, langCd: LangCd): string => {
  if (termUnit === GRAPH_TERM_UNIT.MONTH) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM';
      default:
        return 'MM/YYYY';
    }
  } else if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM/DD';
      default:
        return 'DD/MM/YYYY';
    }
  } else if (termUnit === GRAPH_TERM_UNIT.HOUR) {
    switch (langCd) {
      case LANGUAGE.KO:
        return 'YYYY/MM/DD HH:mm';
      default:
        return 'DD/MM/YYYY HH:mm';
    }
  }

  return 'DD/MM/YYYY';
};

/** 날짜 포맷 변환 - Intl 내장 객체 적용 */
export const getDateFormatter = (langCd: string, options: Intl.DateTimeFormatOptions = {}): Intl.DateTimeFormat =>
  new Intl.DateTimeFormat(isLocaleValueType(langCd) ? INTL_LOCALE_MAP[langCd] : DEFAULT_LANGUAGE, options);

/** 오늘 기준 시작 Date 조회 - moment 적용 */
export const getHourlyStartValue = (): Date => moment().startOf('day').toDate();

/** 오늘 기준 끝 Date 조회 - moment 적용 */
export const getHourlyEndValue = (date: Date): Date => moment(date).endOf('day').toDate();

/** 현재 Date 조회 */
export const now = (): Date => new Date();

/** 현재 Date 조회 - 년, 월, 일까지 적용 */
export const today = (): Date => new Date(now().getFullYear(), now().getMonth(), now().getDate());

/** 현재 Date 포맷 - moment 적용 */
export const formatToday = (format: string): string => moment().format(format);

/** 어제 Date 조회 - 년, 월, 일까지 적용 */
export const getYesterday = (): Date => {
  const now = today();

  return new Date(now.setDate(now.getDate() - 1));
};

/** 2일 전 Date 조회 - 년, 월, 일까지 적용 */
export const twoDaysAgo = (): Date => new Date(now().getFullYear(), now().getMonth(), now().getDate() - 2);

/** 1달 후 Date 조회 - 년, 월, 일까지 적용 */
export const oneMonthAfter = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());

/** 날짜 조회 시 시작 Date 조회 (오늘 기준 6일 전) */
export const getSixDaysAgo = (): Date => new Date(now().getFullYear(), now().getMonth(), now().getDate() - 6);

/** 날짜 조회 시 끝 Date 조회 (오늘 기준 끝 시간) */
export const getEndDate = (): Date => new Date(now().getFullYear(), now().getMonth(), now().getDate(), 23, 59, 59, 999);

/** 날짜 포맷 변환 - moment 적용 (기본값: 'YYYYMMDD') */
export const formatDate = (date: Date, format = NORMAL_DATE_FORMAT): string => moment(date).format(format);

/** 현재 날짜 포맷 변환 - 정규식, Intl 내장 객체 적용 */
export const getSimpleDateFormat = (date: string, type: string, lang?: string, installedDate?: string): string => {
  const langCd = lang && isLocaleValueType(lang) ? INTL_LOCALE_MAP[lang] : DEFAULT_LANGUAGE;
  moment.locale(langCd);

  let now = new Date();

  if (date) {
    const year = Number(date.substring(0, 4));
    const month = Number(date.substring(4, 6)) > 0 ? Number(date.substring(4, 6)) : 1;
    const day = Number(date.substring(6, 8)) > 0 ? Number(date.substring(6, 8)) : 1;

    now = new Date(year, month - 1, day);
  }
  let options = { year: 'numeric', month: 'short', day: 'numeric' } as Intl.DateTimeFormatOptions;
  let replaceValue = '$2 $1';
  let additionalValue = ' ';
  if (type === MAPPER_PERIOD_DATA.MONTH) {
    options = { year: 'numeric', month: 'short' };
    replaceValue = '$1 $2';
  } else if (type === MAPPER_PERIOD_DATA.YEAR) {
    options = { year: 'numeric' };
  } else if (type === MAPPER_PERIOD_DATA.LIFETIME) {
    options = { year: 'numeric' };
    const today = new Date();
    additionalValue = ` - ${today.getUTCFullYear()}`;
  }
  const re = /(\w+)\s(\w+)/;

  return type === MAPPER_PERIOD_DATA.LIFETIME
    ? moment(installedDate).format(NORMAL_YEAR_FORMAT) + additionalValue
    : now.toLocaleDateString(langCd, options).replace(re, replaceValue);
};

/** Interval에 따른 날짜 포맷 변환 */
export const formatDateByInterval = (date: Date, interval: Interval): string => {
  switch (interval) {
    case GRAPH_INTERVAL.HOURLY:
      return moment(date).format('YYYYMMDDHH');
    case GRAPH_INTERVAL.DAILY:
      return moment(date).format('YYYYMMDD');
    case GRAPH_INTERVAL.MONTHLY:
      return moment(date).format('YYYYMM');
    case GRAPH_INTERVAL.YEARLY:
      return moment(date).format('YYYY');
  }
};

/** Interval에 따른 timestamp 조회 */
export const getTime = (date: string | number, interval: Interval): number => {
  if (typeof date == 'number') return 0;
  switch (interval) {
    case GRAPH_INTERVAL.HOURLY:
      return new Date(
        Number(date.substring(0, 4)),
        Number(date.substring(4, 6)) - 1,
        Number(date.substring(6, 8)),
        Number(date.substring(8, 10))
      ).getTime();
    case GRAPH_INTERVAL.DAILY:
      return new Date(
        Number(date.substring(0, 4)),
        Number(date.substring(4, 6)) - 1,
        Number(date.substring(6, 8))
      ).getTime();
    case GRAPH_INTERVAL.MONTHLY:
      return new Date(Number(date.substring(0, 4)), Number(date.substring(4, 6)) - 1).getTime();
    case GRAPH_INTERVAL.YEARLY:
      return moment(date, 'YYYY').toDate().getTime();
  }
};

/** 날짜의 '일' 반환 */
export const getNumberOfDaysByMonth = (value: string): number =>
  new Date(Number(value.substring(0, 4)), Number(value.substring(4, 6)), 0).getDate();

/** 끝 날짜가 시작 날짜 이후인지 체크함 */
export const isValidDateRange = (start: Date | null, end: Date | null): boolean => {
  const startDate = start;
  const endDate = end;
  if (!startDate || !endDate) return true;
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  return startDate <= endDate;
};

/** 리전에 따른 Date 포맷 조회 */
// FIXME: complexity 줄이기
// eslint-disable-next-line complexity
export const getLocalDateFormat = (dateFormatConfig: DateFormatConfig): string => {
  const { locale, isTime, isDay, isMonthly } = dateFormatConfig;
  const isSecond = dateFormatConfig.isSecond ?? true;

  if (isMonthly) {
    return 'MMM, YYYY';
  }

  switch (locale) {
    case ENV_LOCALE.EU:
    case ENV_LOCALE.AU:
    case ENV_LOCALE.NZ:
      if (isDay) {
        if (isTime && isSecond) {
          return 'dddd, DD MMM, YYYY (HH:mm:ss)';
        }
        if (isTime) {
          return 'dddd, DD MMM, YYYY (HH:mm)';
        }

        return 'dddd, DD MMM, YYYY';
      }
      if (isTime && isSecond) {
        return 'DD MMM, YYYY (HH:mm:ss)';
      }
      if (isTime) {
        return 'DD MMM, YYYY (HH:mm)';
      }

      return 'DD MMM, YYYY';

    case ENV_LOCALE.US:
      if (isDay) {
        if (isTime && isSecond) {
          return 'dddd, MMM DD, YYYY (HH:mm:ss)';
        }
        if (isTime) {
          return 'dddd, MMM DD, YYYY (HH:mm)';
        }

        return 'dddd, MMM DD, YYYY';
      }
      if (isTime && isSecond) {
        return 'MMM DD, YYYY (HH:mm:ss)';
      }
      if (isTime) {
        return 'MMM DD, YYYY (HH:mm)';
      }

      return 'MMM DD, YYYY';

    default:
      return 'DD MMM, YYYY';
  }
};

/** 리전에 따른 Date 포맷(타임존 포함) 조회 */
// FIXME: complexity 줄이기
// eslint-disable-next-line complexity
export const getDateFormatWithTimeZone = (dateFormatConfig: DateFormatConfig): string => {
  const { locale, isTime, isDay } = dateFormatConfig;
  const isSecond = dateFormatConfig.isSecond ?? true;

  switch (locale) {
    case ENV_LOCALE.EU:
    case ENV_LOCALE.AU:
    case ENV_LOCALE.NZ:
      if (isDay) {
        if (isTime && isSecond) {
          return 'dddd, DD MMM, YYYY (HH:mm:ss z, UTCZ)';
        }
        if (isTime) {
          return 'dddd, DD MMM, YYYY (HH:mm z, UTCZ)';
        }

        return 'dddd, DD MMM, YYYY (z, UTCZ)';
      }
      if (isTime && isSecond) {
        return 'DD MMM, YYYY (HH:mm:ss z, UTCZ)';
      }
      if (isTime) {
        return 'DD MMM, YYYY (HH:mm z, UTCZ)';
      }

      return 'DD MMM, YYYY (z, UTCZ)';

    case ENV_LOCALE.US:
      if (isDay) {
        if (isTime && isSecond) {
          return 'dddd, MMM DD, YYYY (HH:mm:ss z, UTCZ)';
        }
        if (isTime) {
          return 'dddd, MMM DD, YYYY (HH:mm z, UTCZ)';
        }

        return 'dddd, MMM DD, YYYY (z, UTCZ)';
      }
      if (isTime && isSecond) {
        return 'MMM DD, YYYY (HH:mm:ss z, UTCZ)';
      }
      if (isTime) {
        return 'MMM DD, YYYY (HH:mm z, UTCZ)';
      }

      return 'MMM DD, YYYY (z, UTCZ)';

    default:
      return 'DD MMM, YYYY (HH:mm:ss z, UTCZ)';
  }
};

/** 날짜 스트링 조회  'YYYY-MM-DD' */
export const getFormattedDate = (date: Date): string => moment(date).format('YYYY-MM-DD');

/** 날짜+시간 스트링 조회 'YYYY-MM-DD HH:mm:ss' */
export const getFormattedDateTime = (date: Date): string => moment(date).format('YYYY-MM-DD HH:mm:ss');

/** 리전에 따른 기본 타임존 스트링 조회 */
export const getDefaultTimeZoneByLocale = (envLocale: EnvLocale): string => {
  switch (envLocale) {
    case ENV_LOCALE.EU:
      return 'Europe/Berlin';
    case ENV_LOCALE.AU:
    case ENV_LOCALE.NZ:
      return 'Australia/Sydney';
    case ENV_LOCALE.US:
      return 'America/Los_Angeles';
  }
};

/**
 * locale 날짜(date)값에 대해 GMT(UTC+0) String 타입 포맷(YYYY-MM-DD HH:mm:ss)으로 변환
 * @param envLocale
 * @param date
 * @returns
 */
export const convertDefaultToGMTDateTime = (envLocale: EnvLocale, date: Date): string => {
  const defaultDateTime = moment.tz(date, getDefaultTimeZoneByLocale(envLocale));
  const gmtDateTime = defaultDateTime.clone().tz('GMT').toDate();

  return getFormattedDateTime(gmtDateTime);
};

/**
 * timestamp 값에 대해 locale 날짜 String 타입 포맷(YYYY-MM-DD)으로 변환
 * @param envLocale
 * @param timestamp
 * @returns
 */
export const getTimestampToLocaleDate = (envLocale: EnvLocale, timestamp: string): Date =>
  new Date(moment.unix(Number(timestamp)).tz(getDefaultTimeZoneByLocale(envLocale)).format('YYYY-MM-DD'));

export const getTimestampToLocaleDateString = (
  envLocale: EnvLocale,
  timestamp: string,
  format = DASH_DATE_FORMAT
): string => moment.unix(Number(timestamp)).tz(getDefaultTimeZoneByLocale(envLocale)).format(format);

export const getTimeZoneOffsetByLocale = (envLocale: EnvLocale, withTime = false, date?: number | string): string => {
  const timeZoneId = getDefaultTimeZoneByLocale(envLocale);
  const offsetMinutes = date ? moment.tz(date, timeZoneId).utcOffset() : moment.tz(timeZoneId).utcOffset();
  const hours = Math.floor(Math.abs(offsetMinutes) / 60);
  const minutes = Math.abs(offsetMinutes) % 60;

  let offsetString = offsetMinutes < 0 ? `-${padLeft(hours, 2)}` : `+${padLeft(hours, 2)}`;
  if (withTime) {
    offsetString = `${offsetString}:${padLeft(minutes, 2)}`;
  }

  return offsetString;
};

/** momentTimeZone의 short term 조회 */
export const getShortFormTimeZone = (momentTimeZone: string): string => moment.tz(momentTimeZone).format('z');

/** momentTimeZone의 long term 조회 */
export const getLongFormTimeZone = (momentTimeZone: string): string => {
  const abbrs: Record<string, string> = {
    AEDT: 'Australian Eastern Daylight Time',
  };

  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();

    return abbrs[abbr] || abbr;
  };

  return moment.tz(momentTimeZone).format('zz');
};

/** UTC 기준 날짜 포맷 변환 */
export const getUtcDate = (
  date: number | string,
  locale: EnvLocale,
  formatConfig: Omit<DateFormatConfig, 'locale'>
): string => moment.utc(date).format(getDateFormatWithTimeZone({ locale, ...formatConfig }));

/** Date with Timezone 날짜 포맷 변환 */
export const getTimezoneDate = (
  date: Date | number | string,
  formatConfig: Omit<DateFormatConfig, 'locale'>
): string => {
  const { needUtcConvert, timezone = UTC_TIME_ZONE } = formatConfig;
  const locale = getEnvLocale();
  const lang = getLangCd() ?? DEFAULT_LANGUAGE;
  const dateFormat = getDateFormatWithTimeZone({ locale, ...formatConfig });
  moment.locale(INTL_LOCALE_MAP[lang]);
  if (needUtcConvert) {
    moment(date).utc().tz(timezone).format(dateFormat);
  }

  return moment.tz(date, timezone).format(dateFormat);
};

/** Date의 시작 시간 조회 */
export const getStartOfDate = (date: Date | number): Date => moment(date).startOf('date').toDate();

/** Date의 끝 시간 조회 */
export const getEndOfDate = (date: Date | number): Date => moment(date).endOf('date').toDate();

/** 오늘 시작, 끝 Date 조회 (포맷 변환 포함) */
export const getTodayStartEndDateTimes = (timeZone: string, format?: string): string[] => {
  const startDate = moment(getStartOfDate(new Date())).tz(timeZone).format(format);
  const endDate = moment(getEndOfDate(new Date())).tz(timeZone).format(format);

  return [startDate, endDate];
};

/** local Date String */
export const getLocalDate = (date: Date | number | string, formatConfig: Omit<DateFormatConfig, 'locale'>): string => {
  const { isTime, isDay, isSecond, isMonthly, needUtcConvert, needTimezoneConvert, timezone } = formatConfig;
  const locale = getEnvLocale();
  const lang: LanguageValue = getLangCd() ?? DEFAULT_LANGUAGE;
  const dateFormat = getLocalDateFormat({ locale, isTime, isDay, isSecond, isMonthly });
  moment.locale(INTL_LOCALE_MAP[lang]);
  if (needUtcConvert) {
    if (needTimezoneConvert && !isNull(timezone)) {
      return moment.utc(date).tz(timezone, true).format(dateFormat);
    }

    return moment.utc(date).format(dateFormat);
  }
  if (needTimezoneConvert && !isNull(timezone)) {
    return moment.tz(date, timezone).format(dateFormat);
  }

  return moment(date).format(dateFormat);
};

/** Graph Date 파라미터 */
export const getGraphDateParameter = (date: Date | number | string, termUnit: TermUnit): string => {
  if (termUnit === GRAPH_TERM_UNIT.MINUTE) {
    return moment(date).format('YYYYMMDD');
  }

  return moment(date).format('YYYYMMDDHH');
};

/** Graph Date String */
export const getConvertedGraphDateString = (date: string, termUnit: TermUnit): string => {
  const locale = getEnvLocale();
  const lang = getLangCd() ?? DEFAULT_LANGUAGE;
  const dateFormat =
    termUnit === GRAPH_TERM_UNIT.MINUTE
      ? 'HH:mm'
      : getLocalDateFormat({ locale, isTime: true, isDay: false, isSecond: false });
  moment.locale(INTL_LOCALE_MAP[lang]);

  return moment(date, 'YYYYMMDDHHmm').format(dateFormat);
};

/** 두 날짜의 차이 조회 (일, 월, 년) */
export const getDateDifference = (date: Period, dateUnit: DateUnitValue): number =>
  moment(date.end).diff(moment(date.start), dateUnit);

/** 시간 설정 옵션으로 변경된 날짜 조회 */
export const getDateWithAdjustedTime = (date: Date | number | string, adjustedOptions: MomentSetObject): Date =>
  moment(date).set(adjustedOptions).toDate();

/** 타임존에 해당하는 현재 시간 조회 */
export const getCurrentHourByTimezone = (timezone: string): number => moment().tz(timezone).hour();

/** 브라우저 타임존 조회 */
export const getBrowserTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

/** 오늘 기준 빼기 */
export const subtractDate = (amount: DurationInputArg1, unit: DurationInputArg2): Date =>
  moment().subtract(amount, unit).toDate();

/** 오늘 기준 더하기 */
export const addDate = (amount: DurationInputArg1, unit: DurationInputArg2): Date =>
  moment().add(amount, unit).toDate();

/** 시작 시간 기본 옵션 */
export const defaultStartDateTimeOptions: MomentSetObject = { hour: 0, minute: 0, second: 0, millisecond: 0 };

/** 끝 시간 기본 옵션 */
export const defaultEndDateTimeOptions: MomentSetObject = { hour: 23, minute: 59, second: 59, millisecond: 999 };

/** 기본 기간 옵션 (3일) */
export const defaultPeriod: Period = {
  start: getDateWithAdjustedTime(twoDaysAgo(), defaultStartDateTimeOptions),
  end: getDateWithAdjustedTime(now(), defaultEndDateTimeOptions),
};

/** 31일 기간 옵션 */
export const thirtyOneDaysPeriod: Period = {
  start: getDateWithAdjustedTime(subtractDate(30, 'days'), defaultStartDateTimeOptions),
  end: getDateWithAdjustedTime(now(), defaultEndDateTimeOptions),
};

/** 6달 기간 옵션 */
export const sixMonthsPeriod: Period = {
  start: getDateWithAdjustedTime(subtractDate(6, 'months'), defaultStartDateTimeOptions),
  end: getDateWithAdjustedTime(now(), defaultEndDateTimeOptions),
};

/** timezone offset을 제외한 날짜 부분만 추출 */
export const extractDateWithoutOffset = (dateString: string): string => dateString.replace(/([+-]\d{2}:\d{2}|Z)$/, '');

/** 기간에 포함된 날짜인지 체크 */
export const isDateInRange = (targetDate: Date, period: Period): boolean =>
  moment(targetDate).isBetween(period.start, period.end, undefined, '[]');

/** 이번 주의 시작일 조회 */
export const startOfWeek: Date = moment().startOf('week').toDate();

/** 특정 Date + N days 조회 */
export const getAddedDate = (date: Date, days: number): Date => moment(date).add(days, 'day').toDate();

const weekdayList: DaysOfWeekValues[] = Object.values(DAYS_OF_WEEK).filter(
  (day) => day !== DAYS_OF_WEEK.SATURDAY && day !== DAYS_OF_WEEK.SUNDAY
);

const weekendList: DaysOfWeekValues[] = Object.values(DAYS_OF_WEEK).filter(
  (day) => day === DAYS_OF_WEEK.SATURDAY || day === DAYS_OF_WEEK.SUNDAY
);

const everyDayList: DaysOfWeekValues[] = Object.values(DAYS_OF_WEEK);

/** 주중 요일 배열인지 여부 */
export const isWeekdayList = (daysOfWeek: DaysOfWeekValues[]): boolean =>
  weekdayList.every((day) => daysOfWeek.includes(day)) && !weekendList.some((day) => daysOfWeek.includes(day));

/** 주말 요일 배열인지 여부 */
export const isWeekendList = (daysOfWeek: DaysOfWeekValues[]): boolean =>
  weekendList.every((day) => daysOfWeek.includes(day)) && !weekdayList.some((day) => daysOfWeek.includes(day));

/** 모든 요일 배열인지 여부 */
export const isEverydayList = (daysOfWeek: DaysOfWeekValues[]): boolean =>
  everyDayList.every((day) => daysOfWeek.includes(day));

/** 날짜 - Date 타입 객체 */
export const getDateByDateString = (dateString: string): Date => moment(dateString).toDate();

export const getCurrentTimestamp = (): number => new Date().getTime();

/** 00:00:00 의 형식의 시간을 다른 시간으로 변경  */
export const changeTimeToOthers = (time: string, format: string = START_TIME_VALUE): string =>
  time.replace(/\d{2}:\d{2}:\d{2}$/, format);

/** Date 를 2024-12-02 00:00:00 형식으로 변환 */
export const dateToLocalString = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

/**
 * 시간(HHmm)을 분 단위 값으로 변환
 * @param colect_dt
 * @returns
 */
export const convertHourToMin = (date: string): number => Number(date.slice(-4, -2)) * 60 + Number(date.slice(-2));

/**
 * 분 단위 값을 시간(HH:mm)으로 변환
 * @param colect_dt
 * @returns
 */
export const convertMinToHour = (value: number, hasUnit = true): string => {
  let hour = String(Math.floor(value / ONE_HOUR_VALUE_IN_MINUTE));
  let min = String(value % ONE_HOUR_VALUE_IN_MINUTE);
  min = Number(min) < 10 ? `0${min}` : min;
  hour = Number(hour) < 10 ? `0${hour}` : hour;

  const unit = Number(hour) < 12 ? 'AM' : 'PM';

  if (hasUnit) {
    min = `${min}${unit}`;
  }

  return `${hour}:${min}`;
};

export const convertMinTo12Hour = (value: number, hasUnit = true): string => {
  const hour = Math.floor(value / ONE_HOUR_VALUE_IN_MINUTE);
  const min = value % ONE_HOUR_VALUE_IN_MINUTE;

  /** 오후 12시, 밤 24시 일때는 hour를 12로 할당 */
  const convertedTo12Hour = hour % 12 || 12;

  const convertedHour = padLeft(convertedTo12Hour, 2);
  const convertedMin = padLeft(min, 2);

  const timeUnit = hour < 12 || hour == 24 ? 'AM' : 'PM';

  return `${convertedHour}:${convertedMin}${hasUnit ? timeUnit : ''}`;
};

/** 특정 date 기준 날짜 빼기 */
export const subtractFromDate = (date: Date | number, amount: DurationInputArg1, unit: DurationInputArg2): Date =>
  moment(date).subtract(amount, unit).toDate();

/** 특정 date 기준 날짜 더하기 */
export const addFromDate = (date: Date | number, amount: DurationInputArg1, unit: DurationInputArg2): Date =>
  moment(date).add(amount, unit).toDate();
