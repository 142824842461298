import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import Image2 from '@hems/component/src/image/Image2.vue';
import { TermsService } from '@hems/service';
import { TERMS_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'TermsPage',
    components: {
        Image2,
    },
    setup() {
        const route = useRoute();
        const termsTitle = route.name ?? '';
        const axiosInstance = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
        });
        const termsService = new TermsService(axiosInstance);
        const terms = ref('');
        const getTermsList = async () => {
            try {
                const termsList = await termsService.getTerms(TERMS_TYPE.USER);
                terms.value = termsList.find((terms) => terms.terms_nm === termsTitle)?.terms_contents ?? '';
            }
            catch (e) {
                console.error(e);
            }
        };
        getTermsList();
        return {
            termsTitle,
            terms,
        };
    },
});
