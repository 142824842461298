import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7f44aca0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "error-msg"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.state.errorMessage !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.state.errorMessage), 1))
        : _createCommentVNode("", true);
}
