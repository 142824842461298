import { defineComponent } from 'vue';
import { Accordion, BasicButton } from '@hems/component';
import { BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'SettingConfirmPopup',
    components: {
        Accordion,
        BasicButton,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        data: {
            type: Object,
            default: () => [],
        },
    },
    emits: ['cancel', 'confirm'],
    setup(_props, { emit }) {
        const onCancel = () => {
            emit('cancel');
        };
        const onConfirm = () => {
            emit('confirm');
        };
        return {
            BASIC_BUTTON_COLOR_TYPE,
            onCancel,
            onConfirm,
        };
    },
});
