import { defineComponent, ref, watch } from 'vue';
import { formatNumber } from '@hems/util/src/helper/helper';
// @FIXME 절대경로로 설정시, node_modules로 경로가 잡혀 상대경로로 설정
import Pagination from '../../src/pagination/Pagination.vue';
/* ListSize의 경우 @hems/component로 변경시, 순환참조가 되어서 절대 경로로 해줘야함 */
import ListSize from '../../src/selectors/ListSize/ListSize.vue';
export default defineComponent({
    name: 'ListNavigator',
    components: {
        ListSize,
        Pagination,
    },
    props: {
        listTotalCount: {
            type: Number,
            required: true,
        },
        pageCondition: {
            type: Object,
            default: () => ({ pageNumber: 1, pageSize: 10 }),
        },
    },
    emits: ['changeListSize', 'changePage'],
    setup(props, { emit }) {
        const pageSize = ref(props.pageCondition.pageSize || 10);
        const pageNumber = ref(props.pageCondition.pageNumber || 1);
        const onChangeListSize = () => {
            pageNumber.value = 1;
            emit('changeListSize', pageSize.value, pageNumber.value);
        };
        const onChangePage = () => {
            emit('changePage', pageNumber.value);
        };
        watch(() => [props.pageCondition.pageNumber], () => {
            pageNumber.value = props.pageCondition.pageNumber || 1;
        });
        return {
            pageSize,
            pageNumber,
            formatNumber,
            onChangeListSize,
            onChangePage,
        };
    },
});
