import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67c8f746"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "pop_tit" };
const _hoisted_2 = { class: "tit_ty_6 scroll" };
const _hoisted_3 = { class: "container" };
const _hoisted_4 = {
    key: 0,
    class: "container"
};
const _hoisted_5 = { class: "container" };
const _hoisted_6 = { class: "container" };
const _hoisted_7 = { class: "container" };
const _hoisted_8 = { class: "container" };
const _hoisted_9 = { class: "container" };
const _hoisted_10 = { class: "btn_wrap_m mt50" };
const _hoisted_11 = { class: "is_default" };
const _hoisted_12 = { key: 1 };
const _hoisted_13 = {
    key: 0,
    type: "submit",
    class: "btn_m btn_c_3 btn_w_2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LabelInput = _resolveComponent("LabelInput");
    const _component_AutoResizeInput = _resolveComponent("AutoResizeInput");
    const _component_Form = _resolveComponent("Form");
    const _component_PopupWrapper = _resolveComponent("PopupWrapper");
    return (_openBlock(), _createBlock(_component_PopupWrapper, {
        class: "ty_8",
        style: { "opacity": "1", "left": "50%", "margin-left": "-440px", "top": "60.5px" }
    }, {
        default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_Form, {
                "validation-schema": _ctx.schema,
                onSubmit: _ctx.onSave
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('device.err_code')), 1),
                            _createVNode(_component_LabelInput, {
                                modelValue: _ctx.errorCodeDetail.alarmCd,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.errorCodeDetail.alarmCd) = $event)),
                                name: "alarmCd",
                                type: "text",
                                placeholder: _ctx.$t('message.enter_error_code'),
                                readonly: !_ctx.editable
                            }, null, 8, ["modelValue", "placeholder", "readonly"])
                        ]),
                        (_ctx.mode === 'device')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.started')), 1),
                                _createVNode(_component_LabelInput, {
                                    modelValue: _ctx.alarmStart,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.alarmStart) = $event)),
                                    name: "alarmStart",
                                    type: "text",
                                    readonly: !_ctx.editable
                                }, null, 8, ["modelValue", "readonly"])
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.error_name')), 1),
                            _createVNode(_component_LabelInput, {
                                modelValue: _ctx.errorCodeDetail.alarmNm,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.errorCodeDetail.alarmNm) = $event)),
                                name: "alarmNm",
                                type: "text",
                                placeholder: _ctx.$t('message.enter_error_name'),
                                readonly: !_ctx.editable
                            }, null, 8, ["modelValue", "placeholder", "readonly"])
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.device')), 1),
                            _createVNode(_component_LabelInput, {
                                modelValue: _ctx.errorCodeDetail.deviceTypeCd,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.errorCodeDetail.deviceTypeCd) = $event)),
                                name: "deviceTypeCd",
                                type: "text",
                                placeholder: "Enter Device",
                                readonly: !_ctx.editable
                            }, null, 8, ["modelValue", "readonly"])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.description')), 1),
                            _createVNode(_component_LabelInput, {
                                modelValue: _ctx.errorCodeDetail.alarmDesc,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.errorCodeDetail.alarmDesc) = $event)),
                                name: "alarmDesc",
                                type: "text",
                                placeholder: _ctx.$t('message.enter_description'),
                                readonly: !_ctx.editable
                            }, null, 8, ["modelValue", "placeholder", "readonly"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.check_point')), 1),
                            _createVNode(_component_AutoResizeInput, {
                                modelValue: _ctx.errorCodeDetail.alarmCheck,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.errorCodeDetail.alarmCheck) = $event)),
                                style: _normalizeStyle(!_ctx.editable ? 'background-color: #a8dcf0; color:#8c8c8c;' : 'background-color: #a8dcf0; color:#000;'),
                                name: "alarmCheck",
                                placeholder: _ctx.$t('message.enter_check_point'),
                                readonly: !_ctx.editable
                            }, null, 8, ["modelValue", "style", "placeholder", "readonly"])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.more_details')), 1),
                            _createVNode(_component_AutoResizeInput, {
                                modelValue: _ctx.errorCodeDetail.alarmDetail,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.errorCodeDetail.alarmDetail) = $event)),
                                name: "alarmDetail",
                                type: "text",
                                placeholder: _ctx.$t('message.enter_more_details'),
                                readonly: !_ctx.editable
                            }, null, 8, ["modelValue", "placeholder", "readonly"])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                            (!_ctx.isAllowedEdit)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    type: "button",
                                    class: "btn_m btn_c_2 btn_w_2",
                                    onClick: _cache[7] || (_cache[7] =
                                        //@ts-ignore
                                        (...args) => (_ctx.onClose && _ctx.onClose(...args)))
                                }, _toDisplayString(_ctx.$t('common.close')), 1))
                                : _createCommentVNode("", true),
                            (_ctx.isAllowedEdit)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createElementVNode("button", {
                                        type: "button",
                                        class: "btn_m btn_c_2 btn_w_2",
                                        onClick: _cache[8] || (_cache[8] =
                                            //@ts-ignore
                                            (...args) => (_ctx.onClose && _ctx.onClose(...args)))
                                    }, _toDisplayString(_ctx.$t('common.cancel')), 1),
                                    (_ctx.editable)
                                        ? (_openBlock(), _createElementBlock("button", _hoisted_13, _toDisplayString(_ctx.$t('common.apply')), 1))
                                        : _createCommentVNode("", true),
                                    (!_ctx.editable)
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 1,
                                            type: "button",
                                            class: "btn_m btn_c_3 btn_w_2",
                                            onClick: _cache[9] || (_cache[9] =
                                                //@ts-ignore
                                                (...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                                        }, _toDisplayString(_ctx.$t('common.edit')), 1))
                                        : _createCommentVNode("", true)
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["validation-schema", "onSubmit"])
        ]),
        _: 1
    }));
}
