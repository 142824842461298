
import type { PropType } from 'vue';
import { defineComponent, reactive, watch, onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { GuideWrapper, withLoading, Pagination, ListSize, Image2 } from '@hems/component';
import EventDetailPopup from '@hems/container/src/guide/EventDetailPopup.vue';
import SupportDetailPopup from '@hems/container/src/guide/SupportDetailPopup.vue';
import { SupportService } from '@hems/service';
import { Helper, useMessageBox, useRole } from '@hems/util';
import type { GuideTypeValue, SupportGuideCategoryCodeValue } from '@hems/util/src/constant/supportGuide';
import { GUIDE_TYPE, SUPPORT_GUIDE_CATEGORY_CODE, SUPPORT_GUIDE_TYPE } from '@hems/util/src/constant/supportGuide';
import { isNull } from '@hems/util/src/helper/helper';
import { getRouteInfo, isStringType } from '@hems/util/src/helper/tsguardHelper';

import type {
  SupportInfo,
  SupportSearchBoxOptions,
  SupportErrorCodeInfo,
  SupportGuideListQueryParam,
} from 'hems/support';

export default defineComponent({
  name: 'GuideContainer',
  components: {
    GuideWrapper,
    Image2,
    SupportDetailPopup,
    EventDetailPopup,
    Pagination,
    ListSize,
  },
  props: {
    searchCondition: {
      type: Object as PropType<SupportSearchBoxOptions>,
      required: true,
    },
    tabName: {
      type: String as PropType<string>,
      required: true,
      default: SUPPORT_GUIDE_TYPE.ALL,
    },
  },

  async setup(props) {
    const { confirmMessageBox, alertMessageBox } = useMessageBox();
    const { t } = useI18n();
    const supportService = new SupportService(window.axiosInstance.axios);
    const route = useRoute();
    const guidePopup = ref(false);
    const eventPopup = ref(false);

    const condition = ref<SupportGuideListQueryParam>({
      ...props.searchCondition,
      supportId: null,
      pdfFileName: '',
      pageNum: route.query.pageNum ? Number(getRouteInfo(route.query.pageNum, isStringType)) : 1,
      listSize: route.query.listSize ? Number(getRouteInfo(route.query.listSize, isStringType)) : 10,
      sortColumn: 'create_dt desc',
    });

    const errorCodeId = ref('');
    const totalCount = ref(0);

    const supportId = ref<number | null>(null);
    const allGuideData = ref<SupportInfo[]>([]);
    const hardwareData = ref<SupportInfo[]>([]);
    const softwareData = ref<SupportInfo[]>([]);
    const eventcodeData = ref<SupportErrorCodeInfo[]>([]);
    const searchedGuideData = ref<SupportInfo[]>([]);

    const gridProps = reactive<{
      guide: SupportInfo[];
      eventcode: SupportErrorCodeInfo[];
    }>({
      guide: [],
      eventcode: [],
    });

    const { roleName, isAdmin, isDev } = useRole();
    const isAllowedEdit = computed(() => isAdmin || isDev);

    const hardwareGuideCategoryList: SupportGuideCategoryCodeValue[] = [
      SUPPORT_GUIDE_CATEGORY_CODE.QUICK_GUIDE,
      SUPPORT_GUIDE_CATEGORY_CODE.INSTALLATION_MANUAL,
      SUPPORT_GUIDE_CATEGORY_CODE.SPEC_SHEET,
      SUPPORT_GUIDE_CATEGORY_CODE.USER_MANUAL,
    ];

    const filterHardwareData = (data: SupportInfo[]) =>
      data.filter((data) => (data.categoryCode ? hardwareGuideCategoryList.includes(data.categoryCode) : false));

    const softwareGuideCategoryList: SupportGuideCategoryCodeValue[] = [
      SUPPORT_GUIDE_CATEGORY_CODE.INSTALLER_APP_GUIDE,
      SUPPORT_GUIDE_CATEGORY_CODE.INSTALLER_WEB_GUIDE,
    ];

    const filterSoftwareData = (data: SupportInfo[]) =>
      data.filter((data) => (data.categoryCode ? softwareGuideCategoryList.includes(data.categoryCode) : false));

    const loadData = withLoading(async () => {
      try {
        gridProps.guide = await supportService.getGuideList(condition.value);
        gridProps.eventcode = await supportService.getEventcodeList(condition.value);

        allGuideData.value = gridProps.guide;
        hardwareData.value = filterHardwareData(gridProps.guide);
        softwareData.value = filterSoftwareData(gridProps.guide);
        eventcodeData.value = gridProps.eventcode;
      } catch (e) {
        gridProps.guide = [];
      }
    });

    const searchList = () => {
      condition.value.searchFor = condition.value.searchFor?.trim();
      if (!Helper.isNull(condition.value.searchFor)) {
        if (props.tabName === SUPPORT_GUIDE_TYPE.ALL) {
          const searchedData = gridProps.guide.filter((data: SupportInfo) => {
            return data.pdfFileName
              .substring(0, data.pdfFileName.length - 4)
              .toLowerCase()
              .includes(condition.value?.searchFor?.toLowerCase() ?? '');
          });
          allGuideData.value = searchedData;
          searchedGuideData.value = searchedData;
        } else if (props.tabName === SUPPORT_GUIDE_TYPE.HARDWARE_GUIDE) {
          const searchedData = Object.values(filterHardwareData(gridProps.guide)).filter((data: SupportInfo) => {
            return data.pdfFileName
              .substring(0, data.pdfFileName.length - 4)
              .toLowerCase()
              .includes(condition.value?.searchFor?.toLowerCase() ?? '');
          });
          hardwareData.value = searchedData;
          searchedGuideData.value = searchedData;
        } else if (props.tabName == SUPPORT_GUIDE_TYPE.SOFTWARE_GUIDE) {
          const searchedData = Object.values(filterSoftwareData(gridProps.guide)).filter((data: SupportInfo) => {
            return data.pdfFileName
              .substring(0, data.pdfFileName.length - 4)
              .toLowerCase()
              .includes(condition.value?.searchFor?.toLowerCase() ?? '');
          });
          softwareData.value = searchedData;
          searchedGuideData.value = searchedData;
        } else if (props.tabName === SUPPORT_GUIDE_TYPE.ERROR_GUIDE) {
          const searchedData = gridProps.eventcode.filter((data: SupportErrorCodeInfo) => {
            return (
              data.alarmNm.toLowerCase().includes(condition.value?.searchFor?.toLowerCase() ?? '') ||
              data.alarmDesc.toLowerCase().includes(condition.value?.searchFor?.toLowerCase() ?? '') ||
              data.alarmCd.toLowerCase().includes(condition.value?.searchFor?.toLowerCase() ?? '')
            );
          });
          eventcodeData.value = searchedData;
        }
      } else {
        allGuideData.value = gridProps.guide;
        hardwareData.value = filterHardwareData(gridProps.guide);
        softwareData.value = filterSoftwareData(gridProps.guide);
        eventcodeData.value = gridProps.eventcode;
      }
    };

    const onCreate = (type: GuideTypeValue) => {
      if (type == GUIDE_TYPE.GUIDE) {
        guidePopup.value = true;
      } else {
        eventPopup.value = true;
      }
    };

    const onEdit = (data: number) => {
      supportId.value = data;
      guidePopup.value = true;
    };

    const onDelete = async (data: number | string) => {
      const isOK = await confirmMessageBox(t('message.ask_delete'));
      if (isOK) {
        try {
          withLoading(async () => {
            if (typeof data === 'number') {
              supportId.value = data;
              if (!supportId.value) return;
              await supportService.deleteGuide(supportId.value);
              loadData();
            } else {
              errorCodeId.value = data;
              if (!errorCodeId.value) return;
              await supportService.deleteEventcode(errorCodeId.value);
              loadData();
            }
          })();
        } catch (e) {
          console.error(e);
          alertMessageBox(t('message.delete_fail'));
        }
      }
    };

    const onConfirm = () => {
      guidePopup.value = false;
      eventPopup.value = false;
      supportId.value = null;
      errorCodeId.value = '';
    };

    const onClose = () => {
      guidePopup.value = false;
      eventPopup.value = false;
      supportId.value = null;
      errorCodeId.value = '';
    };

    const onDetail = (data: string) => {
      errorCodeId.value = data;
      eventPopup.value = true;
    };

    watch(
      () => props.tabName,
      () => {
        searchList();
      }
    );

    watch(
      () => props.searchCondition,
      () => {
        Object.assign(condition.value, props.searchCondition);
        searchList();
      }
    );

    onMounted(() => {
      loadData();
    });

    return {
      supportId,
      hardwareData,
      softwareData,
      allGuideData,
      eventcodeData,
      searchedGuideData,
      SUPPORT_GUIDE_TYPE,
      guidePopup,
      eventPopup,
      GUIDE_TYPE,
      isAllowedEdit,
      roleName,
      condition,
      errorCodeId,
      totalCount,
      isNull,
      loadData,
      searchList,
      onCreate,
      onConfirm,
      onClose,
      onEdit,
      onDetail,
      onDelete,
    };
  },
});
