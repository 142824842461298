/** Copyright © 2025 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { useI18n } from 'vue-i18n';
import { PublicClientApplication } from '@azure/msal-browser';
import { useAuthentication } from '@hems/portal/src/composables/authentication';
import { acquireTokenSilent, logoutRedirectByAccountInfo } from '@hems/service/src/api/auth/MSALService';
import { useAlertModal, useLoading, useLogout } from '@hems/util';
import { AUTH_STATUS } from '@hems/util/src/constant';
import { TENANT } from '@hems/util/src/constant/msal';
import { getLoginRequestParams, getMSALAccountInfo, getHomeownerConfig, getPartnersConfig, } from '@hems/util/src/helper/authHelper';
export const useMSAL = () => {
    const msalInstanceHomeowner = new PublicClientApplication(getHomeownerConfig());
    const msalInstancePartners = new PublicClientApplication(getPartnersConfig());
    const getMSALInstance = (tenant) => tenant === TENANT.HOMEOWNER ? msalInstanceHomeowner : msalInstancePartners;
    const { startLoading, stopLoading } = useLoading();
    const loginRedirect = async (tenant) => {
        try {
            startLoading();
            await getMSALInstance(tenant).loginRedirect(getLoginRequestParams(tenant));
        }
        catch (error) {
            console.error(error);
        }
        finally {
            stopLoading();
        }
    };
    const getAuthRedirectPromiseResponse = async () => {
        try {
            startLoading();
            // handleRedirectPromise를 호출했을 때 response가 있으면 로그인 처리, 없으면 세션 갱신
            const responseHomeowner = await msalInstanceHomeowner.handleRedirectPromise();
            const responsePartners = await msalInstancePartners.handleRedirectPromise();
            return { responseHomeowner, responsePartners };
        }
        catch (error) {
            console.error(error);
            return { responseHomeowner: null, responsePartners: null };
        }
        finally {
            stopLoading();
        }
    };
    const getActiveAccount = () => {
        const accountPartners = msalInstancePartners.getActiveAccount();
        const accountHomeowner = msalInstanceHomeowner.getActiveAccount();
        return accountHomeowner || accountPartners;
    };
    const getTenantByTenantId = (tenantId) => tenantId === process.env.VUE_APP_MSAL_HOMEOWNER_TENANT_ID ? TENANT.HOMEOWNER : TENANT.PARTNERS;
    const { setTokenInfo } = useAuthentication();
    const { t } = useI18n();
    const loginErrorAlertModal = useAlertModal({ content: t('message.login_server_error') });
    const processLogin = async ({ responseHomeowner, responsePartners }) => {
        try {
            startLoading();
            const response = responseHomeowner || responsePartners;
            if (!response || !response.account) {
                throw new Error();
            }
            const tenant = getTenantByTenantId(response.tenantId);
            getMSALInstance(tenant).setActiveAccount(response.account);
            setTokenInfo({ accessToken: response.accessToken, idToken: response.idToken });
        }
        catch (error) {
            console.error(error);
            loginErrorAlertModal.open();
        }
        finally {
            stopLoading();
        }
    };
    const { clearToken } = useLogout();
    const acquireToken = async (accountInfo) => {
        try {
            startLoading();
            const account = accountInfo || getActiveAccount();
            if (!account) {
                /** 로그아웃에서 리다이렉트 되어 돌아오게 되면 account가 없기 때문에 토큰을 제거함 */
                clearToken();
                return AUTH_STATUS.SUCCESS;
            }
            const { accessToken, idToken } = await acquireTokenSilent(account);
            await setTokenInfo({ accessToken, idToken });
            return AUTH_STATUS.SUCCESS;
        }
        catch (error) {
            console.error(error);
            return AUTH_STATUS.INVALID_ACCESS_TOKEN;
        }
        finally {
            stopLoading();
        }
    };
    const logoutRedirect = async () => {
        try {
            startLoading();
            const accountInfo = getMSALAccountInfo();
            if (!accountInfo)
                return;
            await logoutRedirectByAccountInfo(accountInfo);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            stopLoading();
        }
    };
    return {
        loginRedirect,
        getAuthRedirectPromiseResponse,
        processLogin,
        acquireToken,
        logoutRedirect,
    };
};
