import type { RestHandler } from 'msw';

import { alarmHandlers } from '@hems/util/src/mocks/handlers/alarmHandlers';
import { authHandlers } from '@hems/util/src/mocks/handlers/authHandler';
import { controlHandlers } from '@hems/util/src/mocks/handlers/controlHandlers';
import { deviceHandlers } from '@hems/util/src/mocks/handlers/deviceHandlers';
import { historyHandlers } from '@hems/util/src/mocks/handlers/historyHandlers';

const handlers: RestHandler[] = [
  ...historyHandlers,
  ...alarmHandlers,
  ...deviceHandlers,
  ...controlHandlers,
  ...authHandlers,
];

export default handlers;
