/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useStore } from 'vuex';
export const useAccessToken = () => {
    const store = useStore();
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    const isTokenExpired = computed(() => store.getters['user/isTokenExpired']);
    const accessToken = computed(() => store.state.user.accessToken);
    return { isAuthenticated, isTokenExpired, accessToken };
};
