import { nextTick, defineComponent, watch, computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ToastGrid, Panel, ListNavigator, BasicButton } from '@hems/component';
import { getColumns } from '@hems/container/src/dataGrid/activityDataGrid/gridConfig';
import { ActivityHistoryService } from '@hems/service';
import { useAlertModal, useAppType, useLanguage, useLoading } from '@hems/util';
import { BASIC_BUTTON_COLOR_TYPE, SORT_ORDER } from '@hems/util/src/constant';
import { getLocalDate } from '@hems/util/src/helper/dateHelper';
import { downloadButtonIconInfo, isEmptyString, downloadCSV } from '@hems/util/src/helper/helper';
import { getRouteInfo, isStringType } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'ActivityDataGrid',
    components: {
        Panel,
        ToastGrid,
        ListNavigator,
        BasicButton,
    },
    props: {
        searchCondition: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute();
        const { t } = useI18n();
        const activityHistoryService = new ActivityHistoryService(window.axiosInstance.axios);
        const { languageCode } = useLanguage();
        const { isInstallerApp } = useAppType();
        const { startLoading, stopLoading } = useLoading();
        const condition = ref({
            ...props.searchCondition,
            pageNum: route.query.pageNum ? Number(getRouteInfo(route.query.pageNum, isStringType)) : 1,
            listSize: route.query.listSize ? Number(getRouteInfo(route.query.listSize, isStringType)) : 10,
            sortColumn: 'create_dt desc',
        });
        const showGrid = ref(true);
        const toastGrid = ref({
            gridOptions: {
                columns: computed(() => getColumns(t, isInstallerApp)),
                data: [],
                sortState: {
                    columnName: 'create_dt_str',
                    ascending: false,
                    multiple: false,
                },
                options: {
                    bodyHeight: 'fitToParent',
                },
            },
        });
        const dateFormatConfig = { isTime: true, needUtcConvert: true };
        const createActivityData = (activityData) => ({
            ...activityData,
            act_type_str: t(activityData.act_type_cd),
            device_id: activityData.device_id,
            ref_desc: activityData.act_type_cd === 'code.acttypecd.60' && !isEmptyString(activityData.prev_device_id)
                ? `${t('device.prev_device_id')} : ${activityData.prev_device_id}`
                : '',
            create_dt_str: activityData.create_dt ? getLocalDate(activityData.create_dt, dateFormatConfig) : '',
            create_dt_utc_str: activityData.create_dt_utc,
        });
        const activityList = computed(() => toastGrid.value.gridOptions.data);
        const totalCnt = computed(() => toastGrid.value.gridOptions.data[0]?.total_records || 0);
        const loadData = async () => {
            try {
                startLoading();
                const data = await activityHistoryService.getActivityList(condition.value);
                toastGrid.value.gridOptions.data = data.map((item) => createActivityData(item));
            }
            catch (e) {
                toastGrid.value.gridOptions.data = [];
                condition.value.pageNum = 1;
            }
            finally {
                stopLoading();
            }
        };
        const alertModal = useAlertModal({ content: t('message.error_download') });
        const onDownload = async () => {
            try {
                startLoading();
                const params = { ...condition.value, listSize: null, pageNum: null };
                const data = await activityHistoryService.getActivityList(params);
                const resData = data.map((item) => createActivityData(item));
                downloadCSV(resData, toastGrid.value.gridOptions.columns, 'ActivityList');
            }
            catch (e) {
                console.error(e);
                alertModal.open();
            }
            finally {
                stopLoading();
            }
        };
        const handleBeforeSortEvent = (gridEvent) => {
            gridEvent.stop();
            const { ascending, multiple } = gridEvent;
            let { columnName } = gridEvent;
            toastGrid.value.gridOptions.sortState = {
                columnName,
                ascending,
                multiple,
            };
            if (columnName === 'create_dt_str') {
                columnName = 'create_dt';
            }
            if (columnName === 'act_type_str') {
                columnName = 'change_type_cd';
            }
            if (columnName === 'ref_desc') {
                columnName = 'prev_device_id';
            }
            condition.value.sortColumn = `${columnName} ${ascending ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING}`;
            loadData();
        };
        const onChangeListSize = (listSize, pageNumber) => {
            condition.value.pageNum = pageNumber;
            condition.value.listSize = listSize;
            loadData();
        };
        const onChangePage = (pageNumber) => {
            condition.value.pageNum = pageNumber;
            loadData();
        };
        const rerenderDataGrid = () => {
            showGrid.value = false;
            nextTick(() => (showGrid.value = true));
        };
        watch(() => props.searchCondition, () => {
            condition.value = { ...condition.value, ...props.searchCondition, pageNum: 1 };
            loadData();
        });
        watch(() => languageCode, () => {
            rerenderDataGrid();
        });
        onMounted(() => {
            loadData();
        });
        return {
            condition,
            showGrid,
            toastGrid,
            totalCnt,
            activityList,
            BASIC_BUTTON_COLOR_TYPE,
            downloadButtonIconInfo,
            onDownload,
            events: {
                beforeSort: handleBeforeSortEvent,
            },
            onChangePage,
            onChangeListSize,
        };
    },
});
