import { defineComponent } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
export default defineComponent({
    name: 'SuspendLoading',
    components: {
        Vue3Lottie,
    },
    props: {
        width: {
            type: Number,
            default: 186,
        },
        height: {
            type: Number,
            default: 186,
        },
        isOverlay: {
            type: Boolean,
            default: true,
        },
    },
});
