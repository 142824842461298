
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import ListItem from '@hems/component/src/list/ListItem.vue';

import type { ListItemInfo } from 'hems';

export default defineComponent({
  name: 'List',
  components: {
    ListItem,
  },
  props: {
    itemList: {
      type: Array as PropType<ListItemInfo[]>,
      required: true,
    },
    isBorder: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['updateCheckedStatus', 'onDetail', 'onEdit'],
  setup(_props, { emit }) {
    const updateCheckedStatus = (itemId: string, isChecked: boolean) => {
      emit('updateCheckedStatus', itemId, isChecked);
    };

    const onDetail = (event: Event, itemId: string) => {
      if (event.target instanceof HTMLElement) {
        const element = event.target;

        if (element.tagName === 'DIV') {
          emit('onDetail', itemId);
        }
      }
    };

    const onEdit = (itemId: string) => {
      emit('onEdit', itemId);
    };

    return {
      updateCheckedStatus,
      onDetail,
      onEdit,
    };
  },
});
