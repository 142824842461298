/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import type { ValueType } from 'hems/common/utils';

export const INPUT_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  PASSWORD: 'password',
  HIDDEN: 'hidden',
  TOGGLE: 'toggle',
  SELECTOR: 'selector',
  RADIO: 'radio',
  HEADER: 'header',
  CALENDAR: 'calendar',
} as const;

export type InputTypeValue = ValueType<typeof INPUT_TYPE>;

export const INPUT_VALIDATE_MODE = {
  INPUT: 'input',
  BLUR: 'blur',
} as const;

export type InputValidateModeValue = ValueType<typeof INPUT_VALIDATE_MODE>;

export const INPUT_VALID_CLASS = {
  DEFAULT: '',
  VALID: 'ok',
  ERROR: 'error',
} as const;

export type InputValidClassValue = ValueType<typeof INPUT_VALID_CLASS>;

export const INPUT_CLASS_NAME = {
  SMALL: 'fm_ipt',
  MEDIUM: 'fm_ipt_2',
  LARGE: 'fm_ipt_4',
} as const;

export type InputClassNameValue = ValueType<typeof INPUT_CLASS_NAME>;
