/** Copyright © 2024 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { computed } from 'vue';
import { useACDeviceType } from '@hems/util/src/composable/device';
import { useRole } from '@hems/util/src/composable/role';
import { useSiteInfo } from '@hems/util/src/composable/site/siteInfo';
import { useUserSiteInfo } from '@hems/util/src/composable/site/userSiteInfo';
import { CONSUMPTION_CT_TYPE, ENERGY_POLICY } from '@hems/util/src/constant';
import { getBrowserTimezone } from '@hems/util/src/helper/dateHelper';
// eslint-disable-next-line import/prefer-default-export
export const useSiteMasterDeviceInfo = () => {
    const { isUser } = useRole();
    const { masterDeviceInfo = null } = isUser ? useUserSiteInfo() : {};
    const { masterDeviceProfile = null } = !isUser ? useSiteInfo() : {};
    const { isSolarConfig } = useACDeviceType();
    const siteMasterDeviceInfo = computed(() => {
        if (isUser) {
            return masterDeviceInfo?.value?.deviceInfo ?? null;
        }
        return masterDeviceProfile;
    });
    const masterDeviceId = computed(() => siteMasterDeviceInfo.value?.device_id ?? null);
    const siteTimezoneId = computed(() => siteMasterDeviceInfo.value?.timezone_id ?? getBrowserTimezone());
    const masterDeviceProductModelName = computed(() => siteMasterDeviceInfo.value?.product_model_nm ?? null);
    const EMSVersion = computed(() => siteMasterDeviceInfo.value?.ems_ver ?? '');
    const isEMSVersionAtLeast = (currentVersion, requiredVersion) => Number(currentVersion.replace(/\D/g, '').substring(0, requiredVersion.length)) >= Number(requiredVersion);
    const getConsumptionCTValue = computed(() => isEMSVersionAtLeast(EMSVersion.value, '04')
        ? siteMasterDeviceInfo.value?.accb_consumption_ct_installed_point
        : siteMasterDeviceInfo.value?.accb_external_ct_installed_point);
    const isEMSVersionAtLeastR04 = computed(() => isEMSVersionAtLeast(EMSVersion.value, '04'));
    const isEMSVersionAtLeastR06 = computed(() => isEMSVersionAtLeast(EMSVersion.value, '06'));
    const isEMSVersionAtLeastR07 = computed(() => isEMSVersionAtLeast(EMSVersion.value, '07'));
    const isConsumptionCT = computed(() => isSolarConfig ? getConsumptionCTValue.value !== CONSUMPTION_CT_TYPE.NOT_INSTALLED : true);
    const isLoadSideConsumptionCT = computed(() => isSolarConfig ? getConsumptionCTValue.value === CONSUMPTION_CT_TYPE.LOAD_SIDE : false);
    const currentOperationMode = computed(() => siteMasterDeviceInfo.value?.energy_control);
    const isStormCoverMode = computed(() => currentOperationMode.value === ENERGY_POLICY.STORM_COVER);
    return {
        siteMasterDeviceInfo,
        masterDeviceId,
        siteTimezoneId,
        masterDeviceProductModelName,
        isConsumptionCT,
        isLoadSideConsumptionCT,
        EMSVersion,
        isEMSVersionAtLeast,
        isEMSVersionAtLeastR04,
        isEMSVersionAtLeastR06,
        isEMSVersionAtLeastR07,
        currentOperationMode,
        isStormCoverMode,
    };
};
