import type { Module } from 'vuex';

import { Helper } from '@hems/util';
import { LANGUAGE, localStorageKey } from '@hems/util/src/constant';
import { THEME_LIGHT } from '@hems/util/src/constant/mapper';
import { getIntlLocale } from '@hems/util/src/helper/helper';
import { getLanguageValue } from '@hems/util/src/helper/tsguardHelper';

import type { LangCd } from 'hems';

import type { AppCtxState } from 'hems/common';
import type { CommonRootState } from 'hems/common/store';

const localStorageLanguage = localStorage.getItem(localStorageKey.language) ?? LANGUAGE.EN;

export const initState: AppCtxState = {
  langCd: getLanguageValue(localStorageLanguage),
  locale: getIntlLocale(),
  envLocale: Helper.getEnvLocale(),
  googleApiKey: null,
  timeZone: '',
  theme: THEME_LIGHT,
  termsVer: process.env[`VUE_APP_TERMS_VER_${Helper.getEnvLocale()}`] ?? '',
  isFleetApp: false,
};

const prefix = 'appCtx';

export const APP_CTX_ACTION = {
  SET_LANG_CD: `${prefix}/setLangCd`,
  SET_GOOGLE_API_KEY: `${prefix}/setGoogleApiKey`,
  SET_LOCALE: `${prefix}/setLocale`,
  SET_TIME_ZONE: `${prefix}/setTimeZone`,
  SET_THEME: `${prefix}/setTheme`,
  SET_IS_FLEET_APP: `${prefix}/setIsFleetApp`,
} as const;

const appCtxModule: Module<AppCtxState, CommonRootState> = {
  namespaced: true,
  state: { ...initState },
  mutations: {
    setLangCd: (state, payload: LangCd) => {
      localStorage.setItem(localStorageKey.language, payload);
      state.langCd = payload;
    },
    setGoogleApiKey: (state, payload: string) => {
      state.googleApiKey = payload;
    },
    setLocale: (state) => {
      state.locale = getIntlLocale();
    },
    setTimeZone: (state, payload: string) => {
      state.timeZone = payload;
    },
    setTheme: (state, payload: string) => {
      state.theme = payload;
    },
    setIsFleetApp: (state, payload: boolean) => {
      state.isFleetApp = payload;
    },
  },
  actions: {
    setLangCd: ({ commit }, payload: LangCd) => {
      commit('setLangCd', payload);
    },
    setGoogleApiKey: ({ commit }, payload: string) => {
      commit('setGoogleApiKey', payload);
    },
    setLocale: ({ commit }) => {
      commit('setLocale');
    },
    setTimeZone: ({ commit }, payload: string) => {
      commit('setTimeZone', payload);
    },
    setTheme: ({ commit }, payload: string) => {
      commit('setTheme', payload);
    },
    setIsFleetApp: ({ commit }, payload: boolean) => {
      commit('setIsFleetApp', payload);
    },
  },
};

export default appCtxModule;
