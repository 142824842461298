export const MAIN_SERVICE_BREAKER_RATING_MIN = 5;
export const MAIN_SERVICE_BREAKER_RATING_MAX_BFPL = 1000;
export const MAIN_SERVICE_BREAKER_RATING_MAX_BBOC = 200;
export const MAIN_SERVICE_PANEL_BUSBAR_RATING_MAX_BFPL = 1000;
export const MAIN_SERVICE_PANEL_BUSBAR_RATING_MAX_BBOC = 200;
export const DER_BREAKER_RATING_MIN = 1;
// 값 계산 시 필요한 변수값이 없거나 유효한 값이 아닐 때 화면에 보여줄 기본값
export const MAIN_SERVICE_PANEL_BUSBAR_RATING_DEFAULT_MIN = 5;
export const DER_BREAKER_RATING_DEFAULT_MAX_BFPL = 40;
export const DER_BREAKER_RATING_DEFAULT_MAX_BBOC = 200;
