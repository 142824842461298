export const apiWrapper = {
  api(url: string): string {
    return `/api/v2${url}`;
  },
  userAPI(url: string): string {
    return `/api/v2/user${url}`;
  },
  deviceAPI(url: string): string {
    return `/api/v2/device${url}`;
  },
  monitoringAPI(url: string): string {
    return `/api/v2/monitoring${url}`;
  },
  adminAPI(url: string): string {
    return `/api/v2/monitoring/admin${url}`;
  },
  installerAPI(url: string): string {
    return `/api/v2/monitoring/installer${url}`;
  },
  controlAPI(url: string): string {
    return `/api/v2/control${url}`;
  },
  smartModuleAPI(url: string): string {
    return `/api/v2/mlpe${url}`;
  },
  weatherAPI(url: string): string {
    return `/weather/api/v1/sites${url}`;
  },
  notificationAPI(url: string): string {
    return `/api/v2/notification${url}`;
  },
  daqApi(url: string): string {
    return `${url}`;
  },
  managerApi(url: string): string {
    return `/manager/apis/v2${url}`;
  },
  stateApi(url: string): string {
    return `/state/apis/v2${url}`;
  },
  controlApi(url: string): string {
    return `/control/apis/v2${url}`;
  },
  authenticationApi(url: string): string {
    return `/authentication/apis/v2${url}`;
  },
  notificationApi(url: string): string {
    return `/notification/apis/v2${url}`;
  },
  alarmApi(url: string): string {
    return `/alarm/apis/v1${url}`;
  },
  managerApisV1(url: string): string {
    return `/manager/apis/v1${url}`;
  },
  managerApiV1(url: string): string {
    return `/manager/api/v1${url}`;
  },
};

export const getApiVersionHeader = (isNew = false) => {
  return {
    'X-API-VERSION': isNew ? '300' : '200',
    'X-API-CHANNEL': 'WEB',
  };
};
