import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { TENANT } from '@hems/util/src/constant/msal';
export default defineComponent({
    name: 'LoginButton',
    props: {
        isLogin: {
            type: Boolean,
            default: true,
        },
        loginTenant: {
            type: String,
            default: TENANT.HOMEOWNER,
        },
    },
    emits: ['click'],
    setup(props) {
        const isHomeowner = computed(() => props.loginTenant === TENANT.HOMEOWNER);
        const isPartners = computed(() => props.loginTenant === TENANT.PARTNERS);
        const path = computed(() => isHomeowner.value
            ? 'M13.515 13.0131L8.848 13.0266L11.1421 0.760284C11.2591 0.136883 10.643 -0.26888 10.3095 0.210659L4.63723 9.3883L1.61928 14.2722L0.123039 16.692C0.0882971 16.7412 0.0616613 16.7953 0.041974 16.8507C-0.0958373 17.238 0.119565 17.7175 0.485518 17.7163L2.02576 17.7114L5.10972 17.7028L4.59438 20.8161L2.86884 31.2553C2.83642 31.4532 2.87579 31.6254 2.95454 31.7557C2.97075 31.7815 2.98812 31.8061 3.00665 31.8283C3.04371 31.8725 3.08656 31.9094 3.13288 31.9377C3.15604 31.9512 3.18036 31.9635 3.20468 31.9733C3.25332 31.9918 3.30544 32.0016 3.35639 32.0004C3.43398 31.9979 3.51157 31.9721 3.58338 31.918C3.6077 31.8996 3.63086 31.8787 3.65286 31.8541C3.67486 31.8295 3.69687 31.8012 3.71655 31.7705L9.46641 21.737L13.8903 14.0177C14.1497 13.6144 13.9239 13.0107 13.5162 13.0119L13.515 13.0131Z'
            : 'M25.4349 2.99726L21.6971 6.73505C21.656 6.77619 21.5894 6.77619 21.5483 6.73505L19.3206 4.50738C19.2795 4.46624 19.2795 4.39968 19.3206 4.35854L23.0766 0.602597C22.3336 0.217807 21.4914 0 20.5972 0C17.6133 0 15.1956 2.41886 15.1956 5.40159C15.1956 6.24983 15.3916 7.05087 15.7389 7.76479L7.76479 15.7389C7.05087 15.3904 6.24862 15.1956 5.40159 15.1956C2.41765 15.1956 0 17.6145 0 20.5972C0 21.4914 0.219017 22.3336 0.602597 23.0766L4.35733 19.3218C4.39847 19.2807 4.46503 19.2807 4.50617 19.3218L6.73384 21.5495C6.77498 21.5906 6.77498 21.6572 6.73384 21.6983L2.99725 25.4349C3.72206 25.7955 4.53763 26 5.40159 26C8.38554 26 10.8032 23.5811 10.8032 20.5984C10.8032 19.8034 10.6301 19.0496 10.3216 18.3695L18.3683 10.3228C19.0484 10.6314 19.8022 10.8044 20.5972 10.8044C23.5811 10.8044 25.9988 8.38554 25.9988 5.4028C25.9988 4.53884 25.7943 3.72207 25.4337 2.99846L25.4349 2.99726Z');
        const size = computed(() => (isHomeowner.value ? { width: 14, height: 32 } : { width: 26, height: 26 }));
        const { t } = useI18n();
        const loginButtonText = computed(() => (isHomeowner.value ? t('common.homeowner') : t('common.partners')));
        return { path, size, loginButtonText, isHomeowner, isPartners };
    },
});
