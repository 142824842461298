import { acquireTokenSilent } from '@hems/service/src/api/auth/MSALService';
import { Exceptions } from '@hems/util';
import { API_METHOD_TYPE, CONTENT_TYPE_HEADER } from '@hems/util/src/constant';
import { getMSALAccountInfo } from '@hems/util/src/helper/authHelper';
import { isLocalEnv } from '@hems/util/src/helper/helper';
import store from '@hems/util/src/stores/modules/user';
const { AxiosErrorException } = Exceptions;
export class Service {
    axios;
    constructor(axios) {
        this.axios = axios;
        const isMobile = location.href.includes('/mobile/');
        if (!isLocalEnv && !isMobile) {
            this.setTokenExpirationCheckInterceptor();
        }
    }
    async download(url, params, defaultFileName) {
        try {
            const { data, headers } = await this.axios.get(url, {
                params,
                headers: {
                    Accept: '*/*',
                },
                responseType: 'blob',
            });
            const items = headers['content-disposition']?.split(';');
            const filename = items
                .map((item) => {
                const temp = item.split('=');
                return { key: temp?.[0]?.trim(), value: temp?.[1]?.replace(/[" ]/gi, '') };
            })
                .find((item) => item.key === 'filename');
            return { data, filename: filename?.value || defaultFileName || 'unknown' };
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async get(url, params, config) {
        try {
            const { data } = await this.axios.get(url, { params, ...config });
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async put(url, params, config) {
        try {
            const { data } = await this.axios.put(url, params, config);
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async post(url, params, config) {
        try {
            const { data } = await this.axios.post(url, params, config);
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async delete(url, params, config) {
        try {
            const { data } = await this.axios.delete(url, { params, ...config });
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async patch(url, params, config) {
        try {
            const { data } = await this.axios.patch(url, params, config);
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    setTokenExpirationCheckInterceptor = () => {
        this.axios.interceptors.request.use(async (config) => {
            const isTokenExpired = store.getters?.isTokenExpired;
            if (!isTokenExpired) {
                return config;
            }
            const accountInfo = getMSALAccountInfo();
            if (!accountInfo) {
                return config;
            }
            const { accessToken } = await acquireTokenSilent(accountInfo);
            if (accessToken) {
                window.axiosInstance.setAccessToken(accessToken);
            }
            return config;
        }, (error) => {
            return Promise.reject(error);
        });
    };
    setContentTypeHeaderInterceptor = () => {
        this.axios.interceptors.request.use((config) => {
            const methodType = config.method?.toLowerCase();
            if (methodType === API_METHOD_TYPE.GET || methodType === API_METHOD_TYPE.DELETE) {
                /* GET METHOD에 대해서 Content-Type 헤더를 설정하려면 body가 있어야 함
                Axios가 body 없는 요청에 대해서는 Content-Type 헤더를 삭제하고 전달하기 때문인데
                GET의 경우 기본적으로 body를 설정하지 않기 때문에 임시처리로 빈 객체를 넣어준 상태이며
                백엔드 파트에 요청하여 GET METHOD API에 대해서는 Content-Type 헤더 제거 요청을 드렸음
                FIXME: 추후 수정 예정
                */
                config.data = {};
            }
            config.headers[CONTENT_TYPE_HEADER.KEY] = CONTENT_TYPE_HEADER.APPLICATION_JSON;
            return config;
        }, (error) => {
            return Promise.reject(error);
        });
    };
}
