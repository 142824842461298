/** Copyright © 2025 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/
import { rest } from 'msw';
import { apiWrapper } from '@hems/service/src/util/helper';
import { authTypeAndRoleData } from '@hems/util/src/mocks/data/authData';
const API_BASE_URL = process.env.VUE_APP_API_URL;
const AUTH_API_URL = `${API_BASE_URL}${apiWrapper.managerApiV1('')}`;
export const authTypeAndRoleHandler = rest.get(`${AUTH_API_URL}/users/:userId`, (req, res, ctx) => {
    const response = authTypeAndRoleData;
    console.log(response);
    return res(ctx.status(200), ctx.json(response));
});
export const authHandlers = [authTypeAndRoleHandler];
