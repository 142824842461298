
import { defineComponent } from 'vue';

import { Loading, MobilePopup } from '@hems/component';
import { useTheme } from '@hems/util';
export default defineComponent({
  name: 'MobileTemplate',
  components: {
    Loading,
    MobilePopup,
  },
  setup() {
    const { themeClass } = useTheme();

    return {
      themeClass,
    };
  },
});
