import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image2 = _resolveComponent("Image2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`button-wrapper-${_ctx.size}`),
    tabindex: 0
  }, [
    _createElementVNode("button", _mergeProps({
      type: _ctx.isSubmit ? 'submit' : 'button',
      class: `button-${_ctx.size} ${_ctx.colorType} ${_ctx.isCritical ? 'critical' : ''}`
    }, _ctx.$attrs), [
      (_ctx.beforeImage)
        ? (_openBlock(), _createBlock(_component_Image2, {
            key: 0,
            class: "before-image",
            src: _ctx.beforeImage.src,
            extension: _ctx.beforeImage.extension
          }, null, 8, ["src", "extension"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.afterImage)
        ? (_openBlock(), _createBlock(_component_Image2, {
            key: 1,
            class: "after-image",
            src: _ctx.afterImage.src,
            extension: _ctx.afterImage.extension
          }, null, 8, ["src", "extension"]))
        : _createCommentVNode("", true)
    ], 16, _hoisted_1)
  ], 2))
}