/** Copyright © 2025 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import type { AuthenticationResult } from '@azure/msal-browser';

import type { ValueType } from 'hems/common/utils';

export const TENANT = {
  /** Admin, Pro Web 인증을 시도하는 경우 사용하는 MS 테넌트 값 */
  PARTNERS: 'Partners',
  /** Homeowner Web 인증을 시도하는 경우 사용하는 MS 테넌트 값 */
  HOMEOWNER: 'Homeowner',
} as const;

export type TenantType = ValueType<typeof TENANT>;

export type AuthRedirectResponse = {
  responseHomeowner: AuthenticationResult | null;
  responsePartners: AuthenticationResult | null;
};
