import { useModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';

import { BasicModal } from '@hems/component';
import type { ContentTextAlignType } from '@hems/util/src/constant';
import { BASIC_MODAL } from '@hems/util/src/constant';

type BasicModalConfig = {
  content?: string;
  customButtonText?: string;
  confirmButtonText?: string;
  callback?: (...args: unknown[]) => unknown;
  contentAlign?: ContentTextAlignType;
};

export const useAlertModal = ({ content, customButtonText, callback, contentAlign }: BasicModalConfig) => {
  const alertModal = useModal({
    component: BasicModal,
    attrs: {
      type: BASIC_MODAL.ALERT,
      content,
      customButtonText,
      contentAlign,
      onClose: callback,
    },
  });

  return alertModal;
};

export const useSuccessModal = ({ content, callback, contentAlign }: BasicModalConfig) => {
  const { t } = useI18n();

  const successModal = useModal({
    component: BasicModal,
    attrs: {
      type: BASIC_MODAL.ALERT,
      content: content || t('message.save_success'),
      contentAlign,
      onClose: callback,
    },
  });

  return successModal;
};

export const useConfirmModal = ({
  content,
  callback,
  customButtonText,
  contentAlign,
  confirmButtonText,
}: BasicModalConfig) => {
  const { t } = useI18n();

  const confirmModal = useModal({
    component: BasicModal,
    attrs: {
      type: BASIC_MODAL.CONFIRM,
      content: content || t('message.apply_set_value'),
      customButtonText,
      contentAlign,
      confirmButtonText,
      onConfirm: callback,
    },
  });

  return confirmModal;
};

export const useFailModal = ({ content, callback, contentAlign }: BasicModalConfig) => {
  const { t } = useI18n();

  const failModal = useModal({
    component: BasicModal,
    attrs: {
      type: BASIC_MODAL.ALERT,
      content: content || t('message.save_fail'),
      contentAlign,
      onClose: callback,
    },
  });

  return failModal;
};
