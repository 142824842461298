
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import UnauthorizedPage from '@hems/component/src/redirectPages/UnauthorizedPage.vue';
import { useRole } from '@hems/util';

import type { Role } from 'hems';

export default defineComponent({
  name: 'AuthTemplateContainer',
  components: {
    UnauthorizedPage,
  },
  props: {
    allowRole: {
      type: Array as PropType<Role[]>,
      required: true,
    },
  },
  setup() {
    const { roleName } = useRole();

    return {
      roleName,
    };
  },
});
