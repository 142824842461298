/** Copyright © 2025 Qcells. All rights reserved.
This software is proprietary and confidential. Unauthorized use,
duplication, or distribution of software is strictly prohibited.
*/

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import type { AccountInfo } from '@azure/msal-browser';

import { AuthService } from '@hems/service';
import { logoutRedirectByAccountInfo } from '@hems/service/src/api/auth/MSALService';
import { useLoading } from '@hems/util/src/composable/loading';
import { useLogout } from '@hems/util/src/composable/logout';
import { useConfirmModal } from '@hems/util/src/composable/modal';
import type { RoleCodeValue } from '@hems/util/src/constant/role';
import { ROLE_CODE } from '@hems/util/src/constant/role';
import { getMSALAccountInfo, getRoleName, parseJwt } from '@hems/util/src/helper/authHelper';

import type { AuthToken } from 'hems/auth';
import type { CommonRootState } from 'hems/common/store';

export const usePermission = () => {
  const store = useStore<CommonRootState>();
  const { t } = useI18n();

  const { logout } = useLogout();

  const { startLoading, stopLoading } = useLoading();

  const invalidPermissionModal = useConfirmModal({
    content: `${t('message.login_authority_issue')}\n${t('message.try_again')}`,
    customButtonText: t('common.close'),
    confirmButtonText: t('account.logout'),
  });

  const logoutRedirectProcess = async (accountInfo: AccountInfo): Promise<void> => {
    try {
      startLoading();

      return logoutRedirectByAccountInfo(accountInfo);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  const checkUserPermissionByToken = async ({
    accessTokenJwtObject,
    accessToken,
  }: {
    accessTokenJwtObject: Record<string, string>;
    accessToken: string;
  }): Promise<RoleCodeValue | null> => {
    const isHomeowner = accessTokenJwtObject.tid === process.env.VUE_APP_MSAL_HOMEOWNER_TENANT_ID;
    const isPartners = accessTokenJwtObject.tid === process.env.VUE_APP_MSAL_PARTNERS_TENANT_ID;

    const userId = accessTokenJwtObject.preferred_username;

    if (isHomeowner) {
      return ROLE_CODE.USER;
    }
    if (isPartners) {
      const authService = new AuthService(window.axiosInstance.axios);
      try {
        startLoading();
        const { accountType } = await authService.getAuthTypeAndRole(userId, accessToken);

        return accountType;
      } catch (error) {
        console.error(error);

        return null;
      } finally {
        stopLoading();
      }
    } else {
      /** @FIXME fleet iframe 에서 호출되는 경우에도 처리 필요
       * 4월 중순 운영 서버 배포까지 유지하고, 이후 setRole 코드 제거하고 error 리턴하도록 수정 필요
       */
      return ROLE_CODE.SERVICE;

      /** return AUTH_STATUS.PERMISSION_ERROR; */
    }
  };

  const getParsedAccessToken = (authToken: AuthToken) => {
    const { accessToken } = authToken;

    if (!accessToken) return null;

    const accessTokenJwtObject = parseJwt(accessToken);

    if (!accessTokenJwtObject) {
      return null;
    }

    return { accessTokenJwtObject, accessToken };
  };

  const permissionErrorProcess = async (authToken: AuthToken): Promise<boolean> => {
    const accountInfo = getMSALAccountInfo(authToken);

    if (!accountInfo) return false;

    invalidPermissionModal.patchOptions({
      attrs: {
        onConfirm: () => {
          logoutRedirectProcess(accountInfo);
          invalidPermissionModal.close();
        },
        onClose: () => {
          logout();
          invalidPermissionModal.close();
        },
      },
    });

    await invalidPermissionModal.open();

    return false;
  };

  const processPermissionAndSetUserRole = async (authToken: AuthToken): Promise<boolean> => {
    const parsedAccessTokenResult = getParsedAccessToken(authToken);

    if (!parsedAccessTokenResult) return false;

    const roleCode = await checkUserPermissionByToken(parsedAccessTokenResult);

    if (roleCode) {
      store.commit('user/setRole', { roleCd: roleCode, roleNm: getRoleName(roleCode) });

      return true;
    }

    return permissionErrorProcess(authToken);
  };

  return {
    processPermissionAndSetUserRole,
  };
};
