
import { defineComponent } from 'vue';

import { Panel } from '@hems/component';

export default defineComponent({
  name: 'Searchbox',
  components: {
    Panel,
  },
});
