import { defineComponent, onBeforeUnmount, ref } from 'vue';
import { useRouter } from 'vue-router';
import TitlePanel from '@hems/component/src/panels/TitlePanel.vue';
import { ActivityHistoryService } from '@hems/service';
import { DateHelper, useLoading } from '@hems/util';
import { ONE_MINUTE_VALUE_IN_MILLISECONDS } from '@hems/util/src/constant';
export default defineComponent({
    name: 'RecentActivities',
    components: { TitlePanel },
    setup() {
        const router = useRouter();
        const { startLoading, stopLoading } = useLoading();
        const activityHistoryService = new ActivityHistoryService(window.axiosInstance.axios);
        const activityList = ref([]);
        const loadRecentActivityList = async (useLoading = false) => {
            try {
                useLoading && startLoading();
                const data = await activityHistoryService.getRecentActivityList();
                activityList.value = data.map((item) => ({
                    ...item,
                    create_dt: DateHelper.getLocalDate(item.create_dt, { isTime: true, needUtcConvert: true }),
                }));
            }
            catch (error) {
                console.error(error);
            }
            finally {
                useLoading && stopLoading();
            }
        };
        const onClickMoreActivity = () => {
            router.push({
                name: 'ActivityList',
            });
        };
        loadRecentActivityList(true);
        const interval = setInterval(() => {
            loadRecentActivityList();
        }, ONE_MINUTE_VALUE_IN_MILLISECONDS);
        onBeforeUnmount(() => {
            clearInterval(interval);
        });
        return {
            activityList,
            onClickMoreActivity,
        };
    },
});
