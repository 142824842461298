
import { defineComponent, computed, ref } from 'vue';

import { BasicButton } from '@hems/component';
import {
  WeatherContainer,
  DashboardEnergyValueFlowContainer,
  AnnualReportPopup,
  SiteEnergyContainer,
} from '@hems/container';
import { useSiteMasterDeviceInfo } from '@hems/util';
import { SITE_ENERGY_MQTT_EMS_VERSION, BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
import { downloadButtonIconInfo } from '@hems/util/src/helper/helper';

export default defineComponent({
  name: 'SiteEnergy',
  components: {
    WeatherContainer,
    DashboardEnergyValueFlowContainer,
    AnnualReportPopup,
    SiteEnergyContainer,
    BasicButton,
  },
  setup() {
    const isOpenAnnualReportPopup = ref(false);
    const { EMSVersion, isEMSVersionAtLeast } = useSiteMasterDeviceInfo();

    const isMQTTRealTime = computed(() =>
      EMSVersion ? isEMSVersionAtLeast(EMSVersion.value, SITE_ENERGY_MQTT_EMS_VERSION) : false
    );

    const openAnnualReportPopup = () => {
      isOpenAnnualReportPopup.value = true;
    };

    const closeAnnualReportPopup = () => {
      isOpenAnnualReportPopup.value = false;
    };

    return {
      isOpenAnnualReportPopup,
      isMQTTRealTime,
      downloadButtonIconInfo,
      BASIC_BUTTON_COLOR_TYPE,
      openAnnualReportPopup,
      closeAnnualReportPopup,
    };
  },
});
