import { computed } from 'vue';
import { useStore } from 'vuex';
import { parseJwt } from '@hems/util/src/helper/authHelper';
export const useHomeownerInfo = () => {
    const store = useStore();
    const idToken = computed(() => store.state.user.idToken || '');
    const idTokenJwtObject = parseJwt(idToken.value);
    const siteId = computed(() => Number(idTokenJwtObject?.site_id) || null);
    const homeOwnerInfo = computed(() => {
        return {
            siteId: idTokenJwtObject?.site_id || '',
            id: idTokenJwtObject?.preferred_username || '',
            firstName: idTokenJwtObject?.given_name || '',
            lastName: idTokenJwtObject?.family_name || '',
            displayName: idTokenJwtObject?.name || '',
            email: idTokenJwtObject?.email || '',
            telephone: idTokenJwtObject?.telephone || '',
            region: idTokenJwtObject?.region || '',
        };
    });
    return {
        siteId,
        idTokenJwtObject,
        homeOwnerInfo,
    };
};
