
import type { PropType } from 'vue';
import { defineComponent, reactive, computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { LabelInput, Panel, SelectNew } from '@hems/component';
import { CommonService } from '@hems/service';
import { SUPPORT_GUIDE_FILTER_TYPE } from '@hems/util/src/constant';
import { codeNamesToSelectorOptions } from '@hems/util/src/helper/helper';

import type { CommonCode, GroupSelectOption, SelectorOption } from 'hems';

import type { SupportSearchBoxOptions } from 'hems/support';

export default defineComponent({
  name: 'SupportSearchBox',
  components: {
    Panel,
    LabelInput,
    // SelectNew,
  },
  props: {
    condition: {
      type: Object as PropType<SupportSearchBoxOptions>,
      default: () => ({
        category: null,
        searchFor: null,
      }),
    },
  },
  emits: ['search'],
  async setup(props, { emit }) {
    const commonService = new CommonService(window.axiosInstance.axios);
    const { t } = useI18n();
    const state = reactive<{
      searchCondition: SupportSearchBoxOptions;
      categoryFilterOptions: (SelectorOption | GroupSelectOption)[];
    }>({
      searchCondition: props.condition,
      categoryFilterOptions: [],
    });

    const countryCode = ref<CommonCode.CodeMap[]>([]);

    const searchInputPlaceholder = computed(
      () => `${t('common.search')} ${t('common.guide')}, ${t('device.err_code')}`
    );

    const getCodeGroups = async () => {
      const { CNTRY_CD } = await commonService.getCodesByGroupCode([{ grpCd: 'CNTRY_CD' }]);
      countryCode.value = CNTRY_CD;
    };

    const onSearch = () => {
      if (state.searchCondition) {
        state.searchCondition.searchFor = state.searchCondition.searchFor?.trim();
      }
      emit('search', { ...state.searchCondition });
    };

    // 이중 필터링 목록으로 수정 필요
    const guideFilteringList = computed(() => [
      { text: t('common.all'), value: '' },
      ...codeNamesToSelectorOptions(countryCode.value, t),
    ]);

    // TODO: 카테고리 정의된 후 옵션 재수정 예정
    const guideSubCategoryFilterOptions = computed<SelectorOption[]>(() => [
      { text: t('device.battery'), value: SUPPORT_GUIDE_FILTER_TYPE.BATTERY },
      { text: t('common.box'), value: SUPPORT_GUIDE_FILTER_TYPE.BOX },
      { text: t('device.micro_inverter'), value: SUPPORT_GUIDE_FILTER_TYPE.MICRO_INVERTER },
      { text: t('common.solar'), value: SUPPORT_GUIDE_FILTER_TYPE.SOLAR },
    ]);

    state.categoryFilterOptions = [
      {
        groupTitle: '',
        items: [
          {
            text: t('common.all'),
            value: null,
          },
        ],
      },
      {
        groupTitle: t('common.quick_guide'),
        items: guideSubCategoryFilterOptions.value,
      },
      { groupTitle: t('common.install_manual'), items: guideSubCategoryFilterOptions.value },
      {
        groupTitle: t('common.spec_sheet'),
        items: guideSubCategoryFilterOptions.value,
      },
      { groupTitle: t('common.user_maual'), items: guideSubCategoryFilterOptions.value },
      {
        groupTitle: t('common.installer_app_guide'),
        items: guideSubCategoryFilterOptions.value,
      },
      {
        groupTitle: t('common.installer_web_guide'),
        items: guideSubCategoryFilterOptions.value,
      },
    ];

    onMounted(() => {
      getCodeGroups();
    });

    return {
      state,
      onSearch,
      guideFilteringList,
      searchInputPlaceholder,
    };
  },
});
