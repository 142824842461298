import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import isNil from 'lodash-es/isNil';
import pick from 'lodash-es/pick';
import InstallationContainer from '@hems/container/src/forms/device/settings/installation/InstallationContainer.vue';
import { CommonService, DeviceServiceAdmin } from '@hems/service';
import { Helper, useConfirmModal, useFailModal, useLoading, useSiteId, useSiteInfo, useSuccessModal } from '@hems/util';
import { PROVIDE_INJECT_KEYS } from '@hems/util/src/constant';
export default defineComponent({
    name: 'InstallationSetting',
    components: {
        InstallationContainer,
    },
    setup() {
        const deviceService = new DeviceServiceAdmin(window.axiosInstance.axios);
        const { t } = useI18n();
        const { siteId } = useSiteId();
        const installationInfo = ref(null);
        const loadInstallationInfo = async () => {
            try {
                if (isNil(siteId.value))
                    return;
                const installationInfoResponse = await deviceService.getInstallationInfo(siteId.value);
                installationInfo.value = installationInfoResponse;
            }
            catch (e) {
                console.error(e);
            }
        };
        const siteImage = ref(null);
        const loadSiteImage = async () => {
            try {
                if (isNil(siteId.value))
                    return;
                const siteImageResponse = await deviceService.getImageBySiteId(siteId.value);
                if (siteImageResponse.size === 0) {
                    siteImage.value = null;
                    return;
                }
                siteImage.value = siteImageResponse;
            }
            catch (e) {
                console.error(e);
            }
        };
        const siteType = ref(null);
        const loadSiteType = async () => {
            try {
                if (isNil(siteId.value))
                    return;
                const siteTypeResponse = await deviceService.getSiteType(siteId.value);
                siteType.value = siteTypeResponse.siteType;
            }
            catch (error) {
                console.error(error);
            }
        };
        const goToList = inject(PROVIDE_INJECT_KEYS.GO_TO_LIST);
        const { startLoading, stopLoading } = useLoading();
        const saveLocationInfo = async (siteId, locationInfo) => {
            return deviceService.saveLocationInfo(siteId, {
                ...locationInfo,
                pinCode: String(locationInfo.instlPinCode),
            });
        };
        const saveHomeownerInfo = async (siteId, homeownerInfo) => {
            return deviceService.saveHomeownerInfo(siteId, homeownerInfo);
        };
        const saveImage = (siteId, file) => {
            const formData = new FormData();
            if (file) {
                formData.append('file', file);
            }
            else {
                formData.append('file', new Blob());
            }
            return deviceService.saveImageBySiteId(siteId, formData);
        };
        const saveNotiAlarms = async (siteId, enabledNotiAlarms) => {
            if (isNil(enabledNotiAlarms))
                return Promise.resolve();
            return deviceService.saveEnabledNotiAlarms(siteId, { enabledNotiAlarms });
        };
        const saveOffGrid = async (siteId, offGrid) => {
            return deviceService.updateOffGrid(siteId, { offGrid });
        };
        const saveSiteType = async (siteId, siteType) => {
            if (isNil(siteType))
                return;
            return deviceService.updateSiteType(siteId, { siteType });
        };
        const saveComments = async (siteId, memo) => {
            return deviceService.updateComments(siteId, { memo });
        };
        const saveSuccessModal = useSuccessModal({});
        const saveErrorModal = useFailModal({ content: t('message.error_apply_setting') });
        const saveConfirmModal = useConfirmModal({});
        const processSave = async (installationInfo) => {
            try {
                if (!siteId.value)
                    throw new Error();
                startLoading();
                const convertParams = Helper.keyToCamelCase(installationInfo);
                await Promise.all([
                    saveLocationInfo(siteId.value, pick(convertParams, [
                        'instlAddr',
                        'cntryCd',
                        'latitude',
                        'longitude',
                        'timezoneId',
                        'instlPinCode',
                        'instlState',
                        'instlPostalCode',
                        'forceInstlAddr',
                    ])),
                    saveHomeownerInfo(siteId.value, pick(convertParams, ['homeownerNm', 'homeownerPn', 'homeownerEmail'])),
                    saveImage(siteId.value, convertParams.userInfoImage),
                    saveNotiAlarms(siteId.value, convertParams.enabledNotiAlarms),
                    saveOffGrid(siteId.value, convertParams.offGrid),
                    saveSiteType(siteId.value, convertParams.siteType),
                    saveComments(siteId.value, convertParams.memo),
                ]);
                stopLoading();
                saveSuccessModal.open();
            }
            catch (error) {
                stopLoading();
                saveErrorModal.open();
                console.error(error);
            }
        };
        const onSave = async (installationInfo) => {
            saveConfirmModal.patchOptions({
                attrs: {
                    onConfirm: () => {
                        processSave(installationInfo);
                        saveConfirmModal.close();
                    },
                },
            });
            saveConfirmModal.open();
        };
        const commonService = new CommonService(window.axiosInstance.axios);
        const confirmSendEmail = useConfirmModal({});
        const sendSuccessModal = useSuccessModal({ content: t('message.email_sent') });
        const sendErrorModal = useFailModal({ content: t('message.email_delivery_failed') });
        const { masterDeviceProfile } = useSiteInfo();
        const processSendEmail = async () => {
            try {
                if (!siteId.value)
                    throw new Error();
                startLoading();
                const params = {
                    device_id: masterDeviceProfile?.device_id || '',
                    pin_code: String(installationInfo.value?.instl_pin_code) || '',
                    email: installationInfo.value?.homeowner_email || '',
                };
                await commonService.sendOwnerEmail(siteId.value, params);
                sendSuccessModal.open();
                stopLoading();
            }
            catch (e) {
                stopLoading();
                sendErrorModal.open();
                console.error(e);
            }
        };
        const onSendEmailToHomeowner = async () => {
            confirmSendEmail.patchOptions({
                attrs: {
                    content: t('message.resend_email', { email: installationInfo.value?.homeowner_email }),
                    onConfirm: () => {
                        processSendEmail();
                        confirmSendEmail.close();
                    },
                },
            });
            confirmSendEmail.open();
        };
        onMounted(async () => {
            try {
                startLoading();
                await loadInstallationInfo();
                await loadSiteImage();
                await loadSiteType();
            }
            catch (e) {
                console.error(e);
            }
            finally {
                stopLoading();
            }
        });
        return {
            goToList,
            onSave,
            installationInfo,
            siteImage,
            onSendEmailToHomeowner,
            siteType,
        };
    },
});
