import { defineComponent } from 'vue';
import { useModelWrapper } from '@hems/util';
import { TAB_SIZE } from '@hems/util/src/constant';
export default defineComponent({
    name: 'Tab',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        tabList: {
            type: Array,
            required: true,
        },
        tabSize: {
            type: String,
            default: TAB_SIZE.REGULAR,
        },
    },
    setup(props, { emit }) {
        const tabName = useModelWrapper(props, emit);
        const switchTab = (name) => {
            tabName.value = name;
        };
        return {
            tabName,
            switchTab,
        };
    },
});
