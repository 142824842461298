
import type { PropType } from 'vue';
import { defineComponent, computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { BasicButton, Calendar, HiddenSearchbox, Selector, LabelInput, Panel } from '@hems/component';
import { CommonService } from '@hems/service';
import { useAppType, useLocale } from '@hems/util';
import { ACTIVITY_TYPE_CODE, DATE_UNIT, DAY_RANGE_31 } from '@hems/util/src/constant';
import { getLocalDateFormat, getDateDifference } from '@hems/util/src/helper/dateHelper';
import { isNull, codeNameToSelectorOption, activityHistoryInitialConditions } from '@hems/util/src/helper/helper';

import type { SelectorOption } from 'hems';

import type { ActivitySearchBoxOptions } from 'hems/activityHistory';

const { VCalendarRange } = Calendar;

export default defineComponent({
  name: 'ActivitySearchBox',
  components: {
    BasicButton,
    VCalendarRange,
    HiddenSearchbox,
    Selector,
    LabelInput,
    Panel,
  },
  props: {
    condition: {
      type: Object as PropType<ActivitySearchBoxOptions>,
      default: () => activityHistoryInitialConditions,
    },
  },
  emits: ['search'],
  async setup(props, { emit }) {
    const { t } = useI18n();
    const commService = new CommonService(window.axiosInstance.axios);

    const searchCondition = ref<ActivitySearchBoxOptions>(props.condition);

    const actTypeOptions = ref<SelectorOption[]>([]);

    const placeholder = computed(
      () =>
        `${t('message.enter_search_word')} (${t('common.site_id')}, ${t('device.serial_no')}, ${t(
          'common.description'
        )})`
    );

    const { locale } = useLocale();

    const { isInstallerApp } = useAppType();

    const validateRangeDate = (value: Calendar.CalendarRangeValue) => {
      const dateCount = getDateDifference(value, DATE_UNIT.DAYS);
      if (isNull(searchCondition.value.searchFor) && dateCount > DAY_RANGE_31) {
        return false;
      }

      return true;
    };

    const onSearch = () => {
      const isValidateRangeDate = searchCondition.value.date && validateRangeDate(searchCondition.value.date);
      if (isValidateRangeDate) {
        searchCondition.value.searchFor = searchCondition.value.searchFor?.trim() ?? '';
        emit('search', { ...searchCondition.value });
      }
    };

    onBeforeMount(async () => {
      const { ACT_TYPE_CD } = await commService.getCodesByGroupCode([
        { grpCd: 'ACT_TYPE_CD', exclude: isInstallerApp ? [ACTIVITY_TYPE_CODE.DELETION] : [] },
      ]);
      actTypeOptions.value = [
        { text: t('common.all'), value: '' },
        ...ACT_TYPE_CD.map((item) => codeNameToSelectorOption(item, t)),
      ];
    });

    return {
      actTypeOptions,
      locale,
      placeholder,
      searchCondition,
      validateRangeDate,
      onSearch,
      getLocalDateFormat,
    };
  },
});
