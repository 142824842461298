// Copyright © 2024 Qcells. All rights reserved.
// This software is proprietary and confidential. Unauthorized use,
// duplication, or distribution of software is strictly prohibited.
class DownloadImageRenderer {
    container;
    el;
    constructor(props) {
        this.container = document.createElement('div');
        this.el = document.createElement('img');
        this.container.appendChild(this.el);
        this.container.style.display = 'flex';
        this.container.style.justifyContent = 'center';
        this.container.style.paddingRight = '102px';
        this.container.style.height = '100%';
        this.render(props);
    }
    getElement() {
        return this.container;
    }
    async render(props) {
        const options = props.columnInfo.renderer.options;
        this.el.src = options
            ? (await import(`@hems/component/resources/images/${options.baseUrl || ''}/${options.imageName || ''}.${options.extension || 'png'}`)).default
            : '';
        this.el.height = options?.height ?? 20;
        this.el.width = options?.width ?? 20;
    }
}
export default DownloadImageRenderer;
