
import type { PropType } from 'vue';
import { computed, defineComponent, watch, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import range from 'lodash-es/range';
import type { ColumnOptions } from 'tui-grid';

import { withLoading, ToastGrid, Panel, ListNavigator, BasicButton } from '@hems/component';
import { getColumns } from '@hems/container/src/dataGrid/eventDataGrid/gridConfig';
import { ErrorCodeService } from '@hems/service';
import { useLanguage, useRole, useAlertModal } from '@hems/util';
import { CONTENT_TEXT_ALIGN_TYPE, SORT_ORDER, BASIC_BUTTON_COLOR_TYPE } from '@hems/util/src/constant';
import type { ErrorHistoryGridTypeValue } from '@hems/util/src/constant/eventHistory';
import { ERROR_HISTORY_DOWNLOAD_LIST_SIZE, ERROR_HISTORY_GRID_TYPE } from '@hems/util/src/constant/eventHistory';
import { getErrorData, getErrorHistoryParams } from '@hems/util/src/helper/errorHistoryHelper';
import {
  isNull,
  formatNumber,
  downloadCSV,
  addSortOrderMark,
  downloadButtonIconInfo,
} from '@hems/util/src/helper/helper';
import { getRouteInfo, isErrorCodeDisplayDetails, isNumberType } from '@hems/util/src/helper/tsguardHelper';

import type { GridEventType, GridEvents } from 'hems';

import type { DataGridOptions } from 'hems/common/dataGrid';
import type { MakeFieldNonNullable } from 'hems/common/utils';
import type { ErrorHistoryInfo, ErrorHistorySearchBoxOptions, ErrorHistoryParams } from 'hems/event';

export default defineComponent({
  name: 'EventDataGrid',
  components: {
    ToastGrid,
    Panel,
    ListNavigator,
    BasicButton,
  },
  props: {
    searchCondition: {
      type: Object as PropType<ErrorHistorySearchBoxOptions>,
      required: true,
    },
    eventGridType: {
      type: String as PropType<ErrorHistoryGridTypeValue>,
      default: ERROR_HISTORY_GRID_TYPE.ALL,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const alertModal = useAlertModal({ content: t('message.no_results') });
    const errorCodeService = new ErrorCodeService(window.axiosInstance.axios);
    const { languageCode } = useLanguage();
    const { roleName, isAdminGroup } = useRole();
    const showGrid = ref(true);

    const condition = ref<MakeFieldNonNullable<ErrorHistoryParams, 'pageNumber' | 'listSize'>>({
      ...props.searchCondition,
      pageNumber: getRouteInfo(route.query.pageNumber, isNumberType) ?? 1,
      listSize: getRouteInfo(route.query.listSize, isNumberType) ?? 10,
      sort: addSortOrderMark('triggerDate', SORT_ORDER.DESCENDING),
      language: languageCode.value,
    });

    const toastGrid = ref<DataGridOptions<ErrorHistoryInfo>>({
      gridOptions: {
        columns: computed((): ColumnOptions[] => getColumns(t, props.eventGridType, roleName.value)),
        data: [],
        sortState: {
          columnName: 'triggerDate',
          ascending: false,
          multiple: false,
        },
        options: {
          bodyHeight: 'fitToParent',
        },
      },
    });

    const totalErrorHistoryCount = ref(0);
    const eventList = computed(() => toastGrid.value.gridOptions.data);

    const loadErrorList = withLoading(async () => {
      try {
        const params = getErrorHistoryParams(condition.value, languageCode.value);
        const errorHistory = await errorCodeService.getErrorHistory(params);
        const { data, totalCount } = getErrorData(errorHistory.data, errorHistory.totalCount);
        toastGrid.value.gridOptions.data = data;
        totalErrorHistoryCount.value = totalCount;
      } catch (error) {
        console.error(error);
        toastGrid.value.gridOptions.data = [];
        condition.value.pageNumber = 1;
      }
    });

    const onDownload = withLoading(async () => {
      const totalPages = Math.ceil(totalErrorHistoryCount.value / ERROR_HISTORY_DOWNLOAD_LIST_SIZE);

      try {
        const params = getErrorHistoryParams(condition.value, languageCode.value);
        const errorHistoryPromiseList = range(1, totalPages + 1).map(
          (page) => () =>
            errorCodeService.getErrorHistory({
              ...params,
              listSize: ERROR_HISTORY_DOWNLOAD_LIST_SIZE,
              pageNumber: page,
            })
        );

        const errorHistoryList = await Promise.all(
          errorHistoryPromiseList.map((errorHistoryPromise) => errorHistoryPromise())
        );
        const errorHistory = errorHistoryList.map((errorHistory) => errorHistory.data).flat();
        const { data } = getErrorData(errorHistory, errorHistory.length);

        downloadCSV(data, toastGrid.value.gridOptions.columns, 'ErrorHistory');
      } catch (e) {
        console.error(e);
        alertModal.patchOptions({ attrs: { content: t('message.error_download') } });
        alertModal.open();
      }
    });

    const onChangeListSize = (listSize: number, pageNumber: number) => {
      condition.value.pageNumber = pageNumber;
      condition.value.listSize = listSize;
      loadErrorList();
    };

    const onChangePage = (pageNumber: number) => {
      condition.value.pageNumber = pageNumber;
      loadErrorList();
    };

    const beforeSortEventHandler = (gridEventProps: GridEventType) => {
      gridEventProps.stop();
      const { ascending, multiple, columnName } = gridEventProps;
      toastGrid.value.gridOptions.sortState = {
        columnName,
        ascending,
        multiple,
      };
      const sortOrder = ascending ? SORT_ORDER.ASCENDING : SORT_ORDER.DESCENDING;

      condition.value.sort = addSortOrderMark(columnName, sortOrder);

      loadErrorList();
    };
    const clickEventHandler = (gridEventProps: GridEventType) => {
      const { rowKey, columnName, instance } = gridEventProps;
      if (isNull(rowKey)) return;
      if (columnName === 'description') {
        const errorDetails = instance.getValue(rowKey, 'display');

        if (isErrorCodeDisplayDetails(errorDetails)) {
          const { description, howToFix } = errorDetails;
          if (!description) return;
          alertModal.patchOptions({ attrs: { content: description } });
          if (howToFix) {
            alertModal.patchOptions({
              attrs: {
                content: `${description}\n\n${t('common.how_to_fix')}\n${howToFix.trim()}`,
                contentAlign: CONTENT_TEXT_ALIGN_TYPE.LEFT,
              },
            });
          }
          alertModal.open();
        }
      }
    };

    const events: GridEvents = {
      click: clickEventHandler,
      beforeSort: beforeSortEventHandler,
    };

    onMounted(() => {
      loadErrorList();
    });

    watch(
      () => props.searchCondition,
      () => {
        condition.value = { ...condition.value, ...props.searchCondition, pageNumber: 1 };
        loadErrorList();
      }
    );

    watch(
      () => languageCode.value,
      () => {
        loadErrorList();
      }
    );

    return {
      showGrid,
      totalErrorHistoryCount,
      eventList,
      toastGrid,
      condition,
      events,
      isAdminGroup,
      BASIC_BUTTON_COLOR_TYPE,
      downloadButtonIconInfo,
      formatNumber,
      onChangeListSize,
      onDownload,
      onChangePage,
    };
  },
});
