import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { Accordion, LabelInput, BasicInput, ToggleInput, ImageUploader, SettingInfoBlock, SettingInfoItem, Tooltip, RadioGroup, SignPadViewer, BasicButton, Selector, } from '@hems/component';
import SettingButtonGroup from '@hems/container/src/forms/device/settings/_shared/SettingButtonGroup.vue';
import GoogleMap from '@hems/container/src/forms/device/settings/installation/GoogleMap.vue';
import { ALARM_CODE, ON_OFF_CODE, SETTING_YES_NO_CODE, SITE_TYPE_KEY } from '@hems/util/src/constant';
export default defineComponent({
    name: 'InstallationContainer',
    components: {
        Accordion,
        LabelInput,
        BasicInput,
        ToggleInput,
        Form,
        ImageUploader,
        SignPadViewer,
        SettingInfoBlock,
        SettingInfoItem,
        Tooltip,
        RadioGroup,
        GoogleMap,
        SettingButtonGroup,
        BasicButton,
        Selector,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        siteImage: {
            type: File,
            required: true,
        },
        siteType: {
            type: [String, null],
            required: true,
        },
    },
    emits: ['goToList', 'save', 'sendEmail'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const isEditable = ref(false);
        const installationInfo = ref(props.data ? { ...props.data } : null);
        const alarmSettingInfo = ref({
            enabled: installationInfo.value?.enabled_noti_alarms?.includes(ALARM_CODE.C128)
                ? ON_OFF_CODE.ON
                : ON_OFF_CODE.OFF,
            alarmCd: ALARM_CODE.C128,
        });
        const siteTypeData = ref(props.siteType);
        const currentImageData = ref(props.siteImage);
        const userImageData = computed({
            get: () => currentImageData.value,
            set: (newImageFile) => {
                currentImageData.value = newImageFile;
            },
        });
        const onOffToggleOptions = computed(() => [
            [ON_OFF_CODE.ON, t('common.on')],
            [ON_OFF_CODE.OFF, t('common.off')],
        ]);
        const yesOrNoToggleOptions = computed(() => [
            {
                text: t('common.yes'),
                value: SETTING_YES_NO_CODE.YES,
            },
            {
                text: t('common.no'),
                value: SETTING_YES_NO_CODE.NO,
            },
        ]);
        const siteTypeOptions = computed(() => [
            {
                text: t('control.residential'),
                value: SITE_TYPE_KEY.RESIDENTIAL,
            },
            {
                text: t('control.commercial'),
                value: SITE_TYPE_KEY.COMMERCIAL,
            },
            {
                text: t('common.test_site'),
                value: SITE_TYPE_KEY.TEST,
            },
            {
                text: t('control.other_purpose'),
                value: SITE_TYPE_KEY.OTHER_PURPOSE,
            },
        ]);
        const onClickEmail = () => {
            emit('sendEmail');
        };
        const getInstallationSaveParam = () => {
            return {
                ...installationInfo.value,
                enabled_noti_alarms: alarmSettingInfo.value.enabled === ON_OFF_CODE.ON ? alarmSettingInfo.value.alarmCd : '',
                user_info_image: userImageData.value,
                siteType: siteTypeData.value,
            };
        };
        const onCancel = (handleReset) => {
            isEditable.value = false;
            if (handleReset)
                handleReset();
        };
        const onSave = () => {
            emit('save', getInstallationSaveParam());
            onCancel();
        };
        const onEdit = () => {
            isEditable.value = true;
        };
        const goToList = () => {
            emit('goToList');
        };
        const schema = yup.object().shape({
            instl_pin_code: yup
                .string()
                .matches(/^(\d{4}|\d{6})$/, { message: { key: 'message.invalid_format' } })
                .required(),
            owner_email: yup.string().email().nullable().required(),
            owner_name: yup.string().trim().min(1).nullable().required(),
            owner_contact: yup
                .string()
                .matches(/^[0-9+\-()]+$/, { message: { key: 'message.invalid_phone_number' } })
                .nullable()
                .required(),
        });
        watch(() => props.siteImage, (newValue) => {
            if (newValue) {
                currentImageData.value = newValue;
            }
        });
        watch(() => props.siteType, () => (siteTypeData.value = props.siteType));
        return {
            schema,
            goToList,
            onEdit,
            onCancel,
            onSave,
            onClickEmail,
            isEditable,
            installationInfo,
            alarmSettingInfo,
            yesOrNoToggleOptions,
            onOffToggleOptions,
            userImageData,
            siteTypeOptions,
            siteTypeData,
        };
    },
});
